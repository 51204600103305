import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  CustomSubNav,
  CustomTable,
  Loader,
  Button,
  PageTitle,
  TextInput,
  useAPI,
  WhitelabelContext,
  AuthContext,
} from 'components/lib';
import { useParams, useNavigate } from 'react-router-dom';
import {
  UilCog,
  UilEnvelope,
  UilFile,
  UilAnalysis,
  UilCheck,
  UilMessage,
  UilThumbsUp,
  UilThumbsDown,
  UilExclamationTriangle,
  UilQuestionCircle,
  UilSync,
  UilListUl
} from '@iconscout/react-unicons';
import { Row, Col } from 'react-grid-system';
import axios from 'axios';
import isDomainFree from './../../assets/data/free-email-domain';
import Swal from 'sweetalert2';
import CustomModal from 'components/custom/customModal';
import './emailDeliverabilityReport.scss'
import { Helmet } from 'react-helmet';

export default function EmailDeliverabilityReport() {
  const [senderEmail, setSenderEmail] = useState(null);
  const [senderData, setSenderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProfessional, setIsProfessional] = useState(false);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [DKIMIdentifier, setDKIMIdentifier] = useState('google');
  let { id } = useParams();
  let navigator = useNavigate();
  const sender = useAPI(`/api/senders/${id}`);
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (sender.data) {
      // console.log('DADA ', sender.data);
      setSenderEmail(sender.data?.smtpImapEmail || sender.data?.alias || sender.data?.microsoft_email || sender.data?.google_email);
      setDKIMIdentifier(sender.data?.dkim_identifier);
    }
  }, [sender.loading]);

  useEffect(() => {
    if (senderEmail) {
      let senderDomain = senderEmail.split('@')[1];
      let professional = isDomainFree(senderDomain);
      if (!professional) {
        setIsProfessional(!isDomainFree(senderDomain));
        getDeliverabilityReport();
      } else setIsProfessional(false);
    }
  }, [senderEmail]);
  const getDeliverabilityReport = async () => {
    try {
      let result = await axios.get(`/api/senders/${id}/report`);
      console.log("result.data ", result.data.data)
      setSenderData(result.data?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    setSaving(true);
    try {
      const identifierRegex = /^[A-Za-z0-9][A-Za-z0-9-]*$/;
      if (!identifierRegex.test(DKIMIdentifier)) {
        setSaving(false);
        return Swal.fire({
          icon: 'error',
          title: 'Error in Updating Email Deliverability!',
          html: `The provided DKIM Identifier does not look valid! Please provide a valid DKIM Identifier.`,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
      }
      let res = await axios.put(`/api/senders/${id}/save`, {
        dkim_identifier: DKIMIdentifier,
      });
      setSaving(false);
      if (res?.data?.success) {
        Swal.fire({
          icon: 'success',
          title: 'Email Deliverability Updated!',
          html: `${res?.data?.msg}`,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error in Updating Email Deliverability!',
          html: `${res?.data?.err}`,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
      }
    } catch (err) {
      setSaving(false);
      Swal.fire({
        icon: 'error',
        title: `Oops! Could Not Update Email Deliverability`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    }
  };

  return (
    <div className='deliverabilityContainer'>
      <Helmet>
        <title>{`Email Deliverability | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <CustomSubNav
        selected={'email senders'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />

      <Fragment>
        <div style={{ display: 'flex' }}>
          <div className='mt-3'>
            <PageTitle 
              title="Domain Setup Report"
              description={`${senderData?.smtpImapEmail || ""}`}
              tooltip="Find problems related to your email address & fix them for better deliverability."
            />
          </div>
          {isProfessional && <button
            onClick={() => setOpen(true)}
            className='csBadge'
            style={{
              marginLeft: 'auto', border: '3px solid #F9C00C', padding: 10
            }}
          >
            <UilCog size={26} color="#F9C00C" />
          </button>}
        </div>

        {open && (
          <CustomModal
            title="Email Domain Setup Settings"
            close={true}
            onClose={() => setOpen(false)}
          >
            {/* <p className="text-modal-description">Configure Email WarmUp settings for improved email deliverability.</p> */}
            <div style={{ padding: 10 }}>
              <label className="relative block text-md mb-1 font-semibold">
                DKIM Identifier
              </label>
              {authContext?.user?.permission !== 'client' && <div className='mb-2'>
                <a className='csCustomeLink cursor-pointer' href='https://help.salesblink.io/en/articles/9080626-domain-technical-setup#h_f2cf27fc9f' target='_blank'>
                  <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
                  Learn to find your DKIM Identifier
                </a>
              </div>}
              <TextInput
                value={DKIMIdentifier}
                onChange={(name, value) => setDKIMIdentifier(value)}
              />
              <div
                style={{
                  display: 'flex',
                  marginTop: 11,
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                {!saving && (
                  <Button
                    variant="primary"
                    action={() => saveSettings()}
                    text="Save"
                  />
                )}
                {saving && (
                  <UilSync
                    style={{
                      marginTop: 15,
                      marginRight: 30,
                      cursor: `not-allowed`,
                    }}
                    className={`rotate`}
                  />
                )}
              </div>
            </div>
          </CustomModal>
        )}

        {isProfessional ? (
          loading ? (
            <div className="csFormLoader">
              <Loader>
                <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  <p>Checking your domain setup for {senderEmail}</p>
                  <p className='mt-2'>This can take a moment.</p>
                </div>
              </Loader>
            </div>
          ) : (
            <div>
              <Row>
                <Col lg={6}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: 40,
                    }}
                  >
                    <div style={{ fontSize: 128, fontWeight: 500, color: senderData?.deliverability ? '#3AC569' : "#E53A40", backgroundColor: '#f9f9f9', border: '2px solid #CCCCCC', borderRadius: '50%', width: 300, height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {senderData?.deliverability ? 
                        <UilCheck 
                          size={180}
                        />
                      : <UilExclamationTriangle 
                        size={180}
                      />}
                    </div>
                  </div>
                  <div style={{ fontSize: 32, fontWeight: 500, paddingTop: 20, display: 'flex', justifyContent: 'center' }}>
                    Domain Setup
                  </div>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <div
                        className='csCardBox'
                        style={{ margin: '12.5px 10px' }}
                      >
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {senderData?.emailError === false ? (
                            <UilThumbsUp
                              size={63}
                              color="#3AC569"
                            />
                          ) : (
                            <UilThumbsDown
                              size={63}
                              style={{ color: '#D14343' }}
                            />
                          )}
                        </div>
                        <div className="text-center mt-3 blockTitle">
                          Website & Email
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className='csCardBox'
                        style={{ margin: '12.5px 10px' }}
                      >
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {senderData?.dnsError === false ? (
                            <UilThumbsUp
                              size={63}
                              color="#3AC569"
                            />
                          ) : (
                            <UilThumbsDown
                              size={63}
                              style={{ color: '#D14343' }}
                            />
                          )}
                        </div>
                        <div className="text-center mt-3 blockTitle">
                          DNS Config
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className='csCardBox'
                        style={{ margin: '12.5px 10px' }}
                      >
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {senderData?.blackList?.length > 0 ? (
                            <UilExclamationTriangle
                              size={63}
                              color="#F9C00C"
                            />
                          ) : (
                            <UilThumbsUp
                              size={63}
                              color="#3AC569"
                            />
                          )}
                        </div>
                        <div className="text-center mt-3 blockTitle">
                          BlackLists
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {(!senderData?.dkim || !senderData?.dmarc || !senderData?.spf || !senderData?.mx || !senderData?.websiteExists || !senderData?.sendingEnabled || !senderData?.receivingEnabled) && <div className='errorTitle'>
                Errors Found
              </div>}
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                {!senderData?.dkim && authContext?.user?.permission !== 'client' && (
                  <div style={{ marginTop: 5 }} className='errorCard'>
                    <b>DKIM (TXT)</b>{' '}
                    <UilQuestionCircle
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://help.salesblink.io/en/articles/9080626-domain-technical-setup#h_f2cf27fc9f',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
                {!senderData?.dmarc && authContext?.user?.permission !== 'client' && (
                  <div className='errorCard'>
                    <b>DMARC (TXT)</b>{' '}
                    <UilQuestionCircle
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://help.salesblink.io/en/articles/9080626-domain-technical-setup#h_94221d735c',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
                {!senderData?.spf && authContext?.user?.permission !== 'client' && (
                  <div className='errorCard'>
                    <b>SPF (TXT)</b>{' '}
                    <UilQuestionCircle
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://help.salesblink.io/en/articles/9080626-domain-technical-setup#h_976559a3c8',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
                {!senderData?.mx && (
                  <div className='errorCard'>
                    <b>MX Records</b>
                  </div>
                )}
                {(!senderData?.websiteExists || senderData?.emailErrorMessage) && (
                  <div className='errorCard'>
                    <b>{senderData?.emailErrorMessage || "Website does not exist. (We ping the domain & look for status codes 200, 301, 302)"}</b>
                  </div>
                )}
                {!senderData?.sendingEnabled && authContext?.user?.permission !== 'client' && (
                  <div className='errorCard'>
                    <b>SMTP Connected</b>{' '}
                    <UilQuestionCircle
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://help.salesblink.io/en/collections/8802287-email-senders',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
                {!senderData?.receivingEnabled && authContext?.user?.permission !== 'client' && (
                  <div className='errorCard'>
                    <b>IMAP Connected</b>{' '}
                    <UilQuestionCircle
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://help.salesblink.io/en/collections/8802287-email-senders',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                )}
              </div>

              {senderData?.blackList?.length > 0 && authContext?.user?.permission !== 'client' && (
                <Row style={{ marginTop: 20 }}>
                  <Col lg={12}>
                    <CustomTable
                      hasFooter={false}
                      pagination={false}
                      filter={''}
                      columns={[
                        {
                          Header: (
                            <div style={{ display: 'flex' }}>
                              BlackList{' '}
                              <UilQuestionCircle
                                style={{ marginLeft: 5, cursor: 'pointer' }}
                                onClick={() => {
                                  window.open(
                                    'https://help.salesblink.io/en/articles/9080711-how-to-remove-your-domain-from-blacklists',
                                    '_blank'
                                  );
                                }}
                              />
                            </div>
                          ),
                          Footer: 'BlackList',
                          disableSortBy: true,
                          accessor: 'name',
                        },
                      ]}
                      data={senderData?.blackList}
                    />
                  </Col>
                </Row>
              )}
            </div>
          )
        ) : loading ? (
          <div className="csFormLoader">
            <Loader>
              <div style={{ fontWeight: 'bold' }}>
                Calculating Domain Setup for {senderEmail}
              </div>
            </Loader>
          </div>
        ) : (
          <div className="font-semibold text-md text-center" style={{ marginTop: 100 }}>
            Domain Setup is only available for Professional Email
            Addresses.
          </div>
        )}
      </Fragment>
    </div>
  );
}
