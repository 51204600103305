import React from 'react'
import styles from './switch.module.scss';

export function CustomSwitch(props) {
    const handleChange = () => {
        props.onChange(!props.value);
    };

    return (
        <label className={styles.switch}>
            <input
                type="checkbox"
                checked={props.value}
                onChange={handleChange}
            />
            <span className={`${styles.slider} ${props.value ? styles.checked : ''}`}></span>
        </label>
    )
}
