function getTimeOfDay(hour) {
    if (hour >= 5 && hour < 12) return 'Morning';
    else if (hour >= 12 && hour < 18) return 'Afternoon';
    else if (hour >= 18 && hour < 21) return 'Evening';
    else return 'Night';
}

function getQuarter(month) {
    if (month >= 1 && month <= 3) return 'Q1';
    else if (month >= 4 && month <= 6) return 'Q2';
    else if (month >= 7 && month <= 9) return 'Q3';
    else return 'Q4';
}

export { getTimeOfDay, getQuarter };