import React, { useState, useEffect } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import './expander.scss';

export function Expander(props) {
  const [expanded, setExpanded] = useState(!!props.expanded);

  useEffect(() => props?.onExpandToggle?.(expanded), [expanded]);

  useEffect(() => {
    setExpanded(!!props.expanded);
  }, [props.expanded]);

  return (
    <div className="csExpander" style={props.style}>
      <div onClick={() => setExpanded(!expanded)} className="csExpanderLabel">
        <div>{props.icon}</div>
        <span>{props.label || 'Expander Label'}</span>
        <div className="arrow">
          {expanded ? <UilAngleUp /> : <UilAngleDown />}
        </div>
      </div>
      {expanded && <div className="csExpanderExpanded">{props.children}</div>}
    </div>
  );
}
