import React from 'react';
import { Label } from 'components/lib';
import Style from './checkbox.tailwind.js';
import {  UilQuestionCircle } from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip';

export function Checkbox(props) {
  return (
    <div id={props?.id} style={{ display: 'flex', ...props?.style }}>
      <input
        type="checkbox"
        name={props.name}
        id={props.option}
        value={props.option}
        // style={{ ...props.style, border: '1px solid #bbb' }}
        // className={Style.input}
        disabled={props.disabled}
        checked={props.checked ? 'checked' : ''}
        onChange={(e) =>
          props.onChange(props.index, props.checked, props.option)
        }
      />

      <Label
        text={props.option}
        style={{ marginLeft: 10, marginTop: 3 }}
        required={props.required}
        for={props.option}
        className={Style.label}
      />

      {props?.tooltip && (
        <UilQuestionCircle
          size={22}
          style={{ cursor: 'pointer', marginLeft: 5 }}
          id={props.option.trim().toLowerCase().replaceAll(' ', '-')}
        />
      )}

      {props?.tooltip && (
        <Tooltip
          anchorSelect={`#${props.option
            .trim()
            .toLowerCase()
            .replaceAll(' ', '-')}`}
          place={props.toolTipPosition || "right"}
          className="z-10"
        >
          {props.tooltip}
        </Tooltip>
      )}
    </div>
  );
}
