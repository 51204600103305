import React from 'react';
import { UilSearch } from '@iconscout/react-unicons'
import './search.scss';

export function CustomSearch(props) {
  return (
    <div className="rounded searchPeople" style={props.style}>
      <UilSearch />
      <input
        type="text"
        placeholder={props.placeholder || 'Search'}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}
