import axios from "axios";
import Toast from "utils/toast";
import { useEffect, useState } from "react";
import { PhoneInput } from "components/lib";
import CustomModal from "components/custom/customModal";
import CustomButton from "components/custom/customButton";
import '../auth.scss';

export default function PhonePage () {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneIsValid, setPhoneIsValid] = useState(false);

    useEffect(() => {
        const isPhoneRequired = sessionStorage.getItem('phone');
        if (!isPhoneRequired) window.location.href = '/dashboard';
    }, []);

    const updateUser = async () => {
        if (!phoneIsValid)
          return Toast.fire({
            icon: 'error',
            title: 'Please provide a valid phone number.',
          });
    
        try {
            await axios.put('/api/account', { phone: phoneNumber });
            sessionStorage.removeItem('phone');
            window.location.href = '/dashboard';
        } catch (err) {
            Toast.fire({
                icon: 'error',
                title: 'Unable to save phone number.',
            });
        }
    };

    return ( 
        <CustomModal height={'100%'} width={'100%'}>
            <div id="phone-page-container">
                <div>
                    <h1
                        id="phone-page-heading"
                        style={{
                            padding: 0,
                            marginBottom: 10,
                            fontSize: 22,
                            fontWeight: 500,
                            color: '#3a3a3a'
                        }}
                    >
                        Complete your SignUp...
                    </h1>
                    <div id="phone-page-input" className='csCard'>
                        <PhoneInput 
                            label="Enter your phone number" 
                            required={true}
                            name="phone_number"
                            value={phoneNumber}
                            onChange={(n, value, valid) => {
                            setPhoneIsValid(valid);
                            setPhoneNumber(value);
                            }}
                        />
                        <CustomButton
                            onClick={updateUser}
                            title="Save"
                            variant="primary"
                            style={{
                            marginLeft: 'auto',
                            float: 'right',
                            marginTop: 10,
                            marginBottom: 5
                            }}
                        />
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}