/***
 *
 *   Email Settings
 *   Configure Email Senders
 *
 **********/

import {
  UilCog,
  UilEdit,
  UilEnvelopeDownload,
  UilFire,
  UilSync,
  UilTrash,
  UilArrowUpRight,
  UilSyncExclamation,
  UilQuestionCircle,
  UilRedo,
  UilArrowLeft,
  UilEnvelopeUpload,
  UilCornerUpLeftAlt,
  UilPlusCircle,
  UilExclamationOctagon,
  UilClock,
  UilRefresh,
  UilSetting,
  UilTimes,
  UilCheck,
  UilExchangeAlt,
  UilArrowGrowth,
  UilCheckCircle,
  UilGlobe,
  UilFileInfoAlt,
  UilExternalLinkAlt,
  UilEnvelope,
  UilExclamationTriangle,
  UilUsersAlt,
  UilMessage,
  UilHistory,
  UilEnvelopeCheck,
  UilClockEight,
  UilPause,
  UilFilter,
  UilFolder,
  UilFolderSlash
} from '@iconscout/react-unicons';
import axios from 'axios';
import Papa from 'papaparse';
import CustomButton from 'components/custom/customButton';
import CustomDialog from 'components/custom/customDialog';
import CustomModal from 'components/custom/customModal';
import isDomainFree from './../../assets/data/free-email-domain';
import {
  Animate,
  AuthContext,
  Button,
  Checkbox,
  CustomDropdown,
  CustomSearch,
  CustomTable,
  EmailInput,
  Expander,
  Loader,
  NumberInput,
  PageTitle,
  PasswordInput,
  Select,
  Switch,
  TextInput,
  UpgradePlan,
  useAPI,
  useNavigate,
  useRouteCheck,
  WhitelabelContext,
} from 'components/lib';
import { DateTime } from 'luxon';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { decodeQuery } from 'utils/query';
import SenderWizard from './senderWizard';
import WarmUpChart from './warmupChart';
import GmailSVG from './../../assets/icons/Gmail.svg';
import OutlookSVG from './../../assets/icons/Outlook.svg';
import ZohoSVG from './../../assets/icons/Zoho.svg';
import './emailSender.scss';
import Upload from 'views/leads/import/upload';
import CustomToggle from 'components/custom/customToggle';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';
import { CustomSelect } from 'components/customselect/customselect';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function AddEditSenderForm(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const deviceWidth = useWindowResize();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const pd = props.data;
    if (pd) {
      setData({
        id: pd.id,
        service: pd.serviceName,
        service_type: pd.senderType,
        name: pd.senderName,
        warmup_urls: pd.warmupURLs,
        email_frequency: pd.emailFrequency,
        smtp_username: pd.smtpUsername,
        smtp_imap_email: pd.smtpImapEmail,
        smtp_password: pd.smtpImapPassword,
        smtp_host: pd.smtpHost,
        smtp_port: pd.smtpPort,
        differentImapEmail: pd.differentImapEmail,
        imap_email: pd.imap_email,
        imap_password: pd.imap_password,
        imap_host: pd.imapHost,
        imap_port: pd.imapPort,
        warmup: pd.warmupEnabled,
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (props.option && props.option === 'gmail') {
      setTimeout(() => {
        handleChange('service', 'gmail');
      }, 100);
    } else if(props.option && props.option === 'zoho') {
      setTimeout(() => {
        handleChange('service', 'zoho');
      }, 100);
    } else if(props.option && props.option === 'smtponly') {
      setTimeout(() => {
        handleChange('service', 'smtponly');
      }, 100);
    } else {
      setTimeout(() => {
        handleChange('service', 'smtpimap');
      }, 100);
    }
  }, [props.option]);

  useEffect(() => {
    if (data.service && data.service === 'gmail') {
      setData((previousData) => ({
        ...previousData,
        smtp_host: 'smtp.gmail.com',
        smtp_port: 465,
        imap_host: 'imap.gmail.com',
        imap_port: 993,
      }));
    } else if (data.service && data.service === 'zoho') {
      setData((previousData) => ({
        ...previousData,
        // smtp_host: 'smtp.zoho.com',
        smtp_port: 465,
        // imap_host: 'imap.zoho.com',
        imap_port: 993,
      }));
    } else {
      setData((previousData) => ({
        ...previousData,
        smtp_host: '',
        smtp_port: 0,
        imap_host: '',
        imap_port: 0,
      }));
    }
  }, [data.service]);

  const handleChange = (name, value) => {
    setData((dt) => {
      let oldData = JSON.parse(JSON.stringify(dt));
      if(name === "name" || name === "smtp_password" || name === "imap_password" || name === "smtp_username") oldData[name] = value;
      else if(typeof value === "string") oldData[name] = value.trim().replaceAll(' ', '').toLowerCase();
      else oldData[name] = value
      return oldData;
    });
  };

  const handleSubmit = async () => {
    if (!data.service || data.service === '')
      return Toast.fire({ icon: 'error', title: `Service required.` });
    if (!data.name || data.name === '')
      return Toast.fire({ icon: 'error', title: `Service name required.` });
    if (!data.smtp_username || data.smtp_username === '')
      return Toast.fire({ icon: 'error', title: `SMTP username required.` });
    if (!data.smtp_imap_email || data.smtp_imap_email === '')
      return Toast.fire({ icon: 'error', title: `IMAP email required.` });
    if (!data.smtp_password || data.smtp_password === '')
      return Toast.fire({ icon: 'error', title: `SMTP password required.` });
    if (!data.smtp_host || data.smtp_host === '')
      return Toast.fire({ icon: 'error', title: `SMTP host required.` });
    if (!data.smtp_port || data.smtp_port === '')
      return Toast.fire({ icon: 'error', title: `SMTP port required.` });
    if (
      data.service !== 'smtponly' &&
      (!data.imap_host || data.imap_host === '')
    )
      return Toast.fire({ icon: 'error', title: `IMAP host required.` });
    if (
      data.service !== 'smtponly' &&
      (!data.imap_port || data.imap_port === '')
    )
      return Toast.fire({ icon: 'error', title: `IMAP port required.` });
    if (data.service === 'smtpimap' && data.differentImapEmail) {
      if (!data.imap_email || data.imap_email == '')
        return Toast.fire({ icon: 'error', title: `IMAP email required.` });
      if (!data.imap_password || data.imap_password == '')
        return Toast.fire({
          icon: 'error',
          title: `IMAP password required.`,
        });
    }
    let smtpData = data
    if (data.service === 'smtpimap' && !data.differentImapEmail) {
      smtpData['imap_email'] = ""
      smtpData['imap_password'] = ""
    }

    try {
      setLoading(true);

      let res;
      let title = 'Sender Created!';

      if (!props.data) {
        res = await axios.post('/api/senders', smtpData);
      } else {
        title = 'Sender Updated!';
        res = await axios.put('/api/senders', smtpData);
      }

      setLoading(false);
      if (res?.data?.success) {
        if(res?.data?.data) window.location.href = `/outreach/email-senders?sender=${res?.data?.data?.id}&step=domain`;
        else window.location.href = `/outreach/email-senders?sender=${data?.id}&step=domain`;
      } else {
        let html = res?.data?.err || 'Something went wrong! Please try again after some time.';
        if (res?.data?.err?.message === "ECONNREFUSED") html = 'Something went wrong! Please try again after some time.';
        Swal.fire({
          icon: 'error',
          title: 'Error in Creating Sender!',
          html: html,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: `${err?.response?.data?.msg || 'Oops! Could Not Create Sender'}`,
        html: `${err?.response?.data?.description || ''}`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
      // console.log('Error in saving sender ', data);
    }
  };

  return (
    <>
      <form>
        {props?.data?.senderType !== 'OAUTH' ? (
          <>
            <h2 className="text-modal-header">SMTP Settings</h2>

            <Select
              label="Service"
              name="service"
              onChange={(name, value) => {
                handleChange(name, value);
                if (value !== 'smtpimap')
                  handleChange('differentImapEmail', false);
              }}
              default={data['service']}
              value={data['service']}
              required={true}
              options={[
                { value: 'smtponly', label: 'SMTP Only' },
                { value: 'smtpimap', label: 'SMTP and IMAP' },
                { value: 'gmail', label: 'Gmail' },
                // { value: 'outlook', label: 'Outlook' },
                { value: 'zoho', label: 'Zoho' },
              ]}
            />
            {authContext?.user?.permission !== 'client' && <div
              style={{ marginTop: '-10px', marginBottom: 10, marginLeft: 5 }}
            >
              <a
                href={`https://help.salesblink.io/en/articles/9080592-what-are-email-senders-in-salesblink`}
                target="_blank"
                style={{ color: '#3366FF', display: 'flex' }}
              >
                <UilQuestionCircle size={18} style={{ marginRight: 3, marginTop: 2 }} />
                Check Step by Step Guide
              </a>
            </div>}
            {loading && (
              <div className="csFormLoader">
                <Loader />
              </div>
            )}

            <div className={`${deviceWidth > 991 ? 'csRow' : 'row-column-responsive'}`}>
              <div className="csCol50">
                <TextInput
                  required={true}
                  label="Your Name"
                  valid={data['name']}
                  name="name"
                  value={data['name']}
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="John Doe"
                />
              </div>
              <div className="csCol50">
                <Tooltip anchorSelect='#username-input' place="top">Usually the same as your Sender Email Address.</Tooltip>  
                <EmailInput
                  required={true}
                  label={<div style={{ display: 'inline-flex' }}>Sender Username (SMTP) <UilQuestionCircle id='username-input' size={18} style={{ marginRight: 3, marginLeft: 3 }} /></div>}
                  name="smtp_username"
                  value={data['smtp_username']}
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="email@company.net"
                />
              </div>
            </div>

            <div className={`${deviceWidth > 991 ? 'csRow' : 'row-column-responsive'}`}>
              <div className="csCol50">
                <EmailInput
                  required={true}
                  label="Sender Email Address (SMTP)"
                  name="smtp_imap_email"
                  value={data['smtp_imap_email']}
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="email@company.net"
                />
              </div>
              <div className="csCol50">
                <Tooltip anchorSelect='#password-input' place="top">App Password needs to be generated from settings, its not the same as your login password.</Tooltip>
                <PasswordInput
                  required={true}
                  label={data['service'] === 'gmail' ? <div style={{ display: 'inline-flex' }}>App Password <UilQuestionCircle id='password-input' size={18} style={{ marginRight: 3, marginLeft: 3 }} /></div> : 'Sender Password (SMTP)'}
                  name="smtp_password"
                  value={data['smtp_password']}
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="Enter Password"
                />
              </div>
            </div>

            <div className={`${deviceWidth > 991 ? 'csRow' : 'row-column-responsive'}`}>
              <div className="csCol50">
                <TextInput
                  required={true}
                  label="SMTP Host (Outgoing Server)"
                  name="smtp_host"
                  value={data['smtp_host']}
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="smtp.domain.com"
                />
              </div>
              <div className="csCol50">
                <NumberInput
                  required={true}
                  label="SMTP Port (Outgoing Server Port)"
                  min={1}
                  value={data['smtp_port']}
                  name="smtp_port"
                  onChange={(name, value) => handleChange(name, value)}
                  placeholder="465 or 25"
                />
              </div>
            </div>

            {data.service === 'smtpimap' && (
              <>
                <div className="flex items-center mt-4 mb-4">
                  <input
                    id="link-checkbox"
                    onChange={(e) =>
                      handleChange('differentImapEmail', e.target.checked)
                    }
                    type="checkbox"
                    checked={data['differentImapEmail']}
                    className="csCheckbox"
                  />
                  <label
                    for="link-checkbox"
                    name="differentImapEmail"
                    className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-600"
                  >
                    Use Different Email Address for Incoming Emails
                  </label>
                </div>
                {data['differentImapEmail'] && (
                  <div className={`${deviceWidth > 991 ? 'csRow' : 'row-column-responsive'}`}>
                    <div className="csCol50">
                      <EmailInput
                        required={true}
                        label="Receiver Email Address (IMAP)"
                        name="imap_email"
                        value={data['imap_email']}
                        onChange={(name, value) => handleChange(name, value)}
                        placeholder="email@company.net"
                      />
                    </div>
                    <div className="csCol50">
                      <PasswordInput
                        required={true}
                        label="Receiver Password / App Password (IMAP)"
                        name="imap_password"
                        value={data['imap_password']}
                        onChange={(name, value) => handleChange(name, value)}
                        placeholder="Enter Password"
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {data.service !== 'smtponly' && (
              <div className={`${deviceWidth > 991 ? 'csRow' : 'row-column-responsive'}`}>
                <div className="csCol50">
                  <TextInput
                    required={true}
                    label="IMAP Host (Incoming Server)"
                    value={data['imap_host']}
                    name="imap_host"
                    onChange={(name, value) => handleChange(name, value)}
                    placeholder="imap.domain.com"
                  />
                </div>
                <div className="csCol50">
                  <NumberInput
                    required={true}
                    label="IMAP Port (Incoming Server Port)"
                    min={1}
                    value={data['imap_port']}
                    name="imap_port"
                    onChange={(name, value) => handleChange(name, value)}
                    placeholder="993"
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          ''
        )}


        <br />

        <div
          style={{
            display: 'flex',
            margin: '11px 16px 0px',
            justifyContent: 'end',
            width: '100%',
            gap: '2rem',
          }}
        >
          <Button variant="primary grey" action={props.close} text="Cancel" />
          <Button variant="primary" action={() => handleSubmit()} text="Next" />
        </div>
      </form>
    </>
  );
}

function BulkSenderUploader(props) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [parsedData, setParsedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!selectedFile) return;
    parseCsvFile()
  }, [selectedFile]);

  function parseCsvFile() {
    const expectedDelimiter = ","
    const reader = new FileReader();

    // Hashed fields to check if exist
    let requiredfield = {
      from_name: false,
      from_email: false,
      user_name: false,
      password: false,
      smtp_host: false,
      smtp_port: false
  };
    // Read the content of the file
    reader.onload = (event) => {
      const content = event.target.result;

      // Check if the expected delimiter is present in the content
      if (content.includes(expectedDelimiter)) {
        // Continue with parsing if the expected delimiter is found
        Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
          delimiter: expectedDelimiter,
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });

            // checking required field
            rowsArray[0].forEach((item)=>{if(requiredfield.hasOwnProperty(item)){ requiredfield[item]=true}});
            let errfieldarray =[];
            for (let key in requiredfield) {
            if (requiredfield[key] == false) {
              errfieldarray.push(key)
            }}
            let errfields = errfieldarray.join(' , ')
            if(errfieldarray?.length>0){
              setSelectedFile(null)
              Toast.fire({ icon: 'error', title: `&lt ${errfields} &gt field(s) required! `});
            }

            // Parsed Data Response in array format
            setParsedData(results.data.filter(sender => {
              if(sender.from_email.trim() === "") return false
              if(sender.from_email.includes("Your email")) return false
              return true
            }));
          },
        });
      } else {
        // Trigger an error if the expected delimiter is not found
        setSelectedFile(null)
        Swal.fire({
          icon: 'info',
          title: 'Please upload CSV file with "," delimiter!',
          html: '',
          showCancelButton: false,
          confirmButtonText: `OK`,
          confirmButtonColor: '#0066FF',
        });
      }
    };

    reader.readAsText(selectedFile);
  }

  async function handleSubmit() {
    if(props.planUsage?.senderCount.limit < (props.planUsage?.senderCount.used + parsedData?.length)) return Swal.fire({
      icon: 'info',
      title: `According to your plan you can add ${props.planUsage?.senderCount.limit - props.planUsage?.senderCount.used} more email senders.`,
      confirmButtonText: `Ok`,
      confirmButtonColor: '#0066ff',
    })
    if(parsedData?.length > 100) return Swal.fire({
      icon: 'error',
      title: 'Cannot add more than 100 senders!',
      html: '',
      showCancelButton: false,
      confirmButtonText: `Ok`,
      confirmButtonColor: '#0066ff',
    });

    try {
      setLoading(true)
      const formData = new FormData();
      formData.append('csvFile', selectedFile);

      let result = await axios({
        method: 'POST',
        url: '/api/senders/bulk',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      Swal.fire({
        icon: 'success',
        title: 'Email Senders will be uploaded in a while.',
        html: '',
        showCancelButton: false,
        confirmButtonText: `OK`,
        confirmButtonColor: '#0066FF',
      });
      props.onSuccess()
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }
  if (loading) return (<><CustomDialog title="Add Bulk Email Sender" scroll={true}><div
    style={{ textAlign: 'center',  padding: '100px',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      margin: '4rem auto 2rem' }}
  >
    <Loader />
  </div> </CustomDialog></>);
  return <CustomDialog title="Add Bulk Email Sender" scroll={true}>
    <div>
    {selectedFile ? (
      <div
        className="import-file-details"
        style={{ textAlign: 'center' }}
      >
        <h2>{selectedFile?.name}</h2>
        <p>{parsedData?.length} rows found</p>
        <button
          onClick={() => setSelectedFile(null)}
          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Remove
        </button>
      </div>
    ) : (
      <Upload 
        onSelected={(acceptedFiles) => {
          if (
            acceptedFiles[0].type !== 'text/csv' &&
            acceptedFiles[0].type !== 'application/vnd.ms-excel'
          )
            return Swal.fire({
              icon: 'error',
              title: 'Please select a .csv file!',
              html: '',
              showCancelButton: false,
              confirmButtonText: `Ok`,
              confirmButtonColor: '#0066ff',
            });
          setSelectedFile(acceptedFiles[0]);
        }}
        fullWidth={true}
        type="sender"
      />
    )}
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        width: '100%',
        gap: '2rem',
        marginRight: 30
      }}
    >
      <Button variant="primary grey" action={props.onClose} text="Cancel" />
      <Button variant="primary" action={() => handleSubmit()} text="Save" />
    </div>
  </CustomDialog>
}

function ErrorView({ body, email, original, urls, setSelectedSender, setEditSenderDialogVisible, showSenderIcon, showSenderName, reconnectSenders }) {
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState(null);
  const [fixAttempted, setFixAttempted] = useState(false);
  const [reconnectAll, setReconnectAll] = useState(false);

  const reconnectSender = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`/api/senders/${original.id}/reconnect`, {});
      if (response.data.success) window.location.reload();
      setFixAttempted(true)
    } catch (error) {
      setLoading(false);
      Toast.fire({ icon: 'error', title: "Please try to re-connect again." });
      setFixAttempted(true)
    }
  }

  return (
    <>
      {sender && <CustomDialog title={`Error with ${sender.email}`} scroll={true}>
        <div style={{ marginTop: -20 }}>
          <div className='font-semibold'>🤮 What went wrong?</div>
          <div className='mt-2'>Your email service provider responded with the following error:</div>
          <div className='mt-2 bg-gray-100 p-3' style={{ borderRadius: 10 }}>{body ? <span>{JSON.stringify(body.axiosError || body.error || body)}</span> : "Please try to re-connect once"}</div>
        </div>

        <div className='mt-4'>
          <div className='font-semibold'>🤨 How do I fix this?</div>
          <div className='mt-2'>Email sending issues are usually caused by a number of factors.</div>
          <div className='mt-2'>Unless the error message states that it's a temporary issue, we recommend you contact your email service provider and ask them to investigate the issue.</div>
        </div>

        {!fixAttempted && <div className='flex mt-4'>
          <input type='checkbox' id="reconnectAllSenders" checked={reconnectAll} onChange={() => setReconnectAll(prev => !prev)} />
          <label htmlFor="reconnectAllSenders" className='font-medium' style={{ marginLeft: 5, marginTop: 1 }}>Reconnect All Senders</label>
        </div>}

        <div style={{ marginBottom: 50 }}>
          <Button
            variant="primary"
            disabled={loading}
            action={() => {
              if(reconnectAll) reconnectSenders()
              else {
                if(fixAttempted) {
                  if(original.senderType === "OAUTH") {
                    if (original.microsoft_email) window.location.href = urls?.data?.microsoftEmailUrl;
                    if (original.google_email) window.location.href = urls?.data?.googleEmailUrl;
                  } else {
                    setSelectedSender(original);
                    setEditSenderDialogVisible(true);
                    setSender(null)
                  }
                }
                else reconnectSender()
              }
            }}
            text="Reconnect Email Senders"
          />
        </div>
      </CustomDialog>}

      <div className="flex items-center">
        <span className="csBadge info" style={{ padding: 4, marginRight: 3 }} id={`sender-type-${original?.id}`}>
          {original?.microsoft_email
            ? showSenderIcon('office365')
            : showSenderIcon(original.smtpHost)}
        </span>
        {(original.smtpHost === 'smtp.titan.email' || original.smtpHost === 'smtp.protonmail.ch') && <div style={{ marginInline: 3 }}>
          <span className="csBadge warning" style={{ height: 28, color: '#e8b105', size: 12 }} id={`sender-host-${original?.id}`}>
            Caution
          </span>
          <Tooltip anchorSelect={`#sender-host-${original?.id}`} place="top">
            {`${original.smtpHost === 'smtp.titan.email' ? 'Titan' : 'Proton'} Email is not fully supported & is not ideal for sending cold emails.`}
          </Tooltip>
        </div>}
        <div id={`sender-fix-${original?.id}`} className='flex items-center'>{email} 
          {loading ? <div style={{ padding: 5, marginLeft: 8 }} className='sync'><UilSync size={16} /></div> : 
            <div style={{ display: original?.completed ? 'block' : 'none', marginLeft: 8 }} className="text-center">
              <span
                className="csBadge danger"
                style={{
                  paddingBlock: 2,
                  paddingInline: 4,
                  cursor: 'pointer',
                  color: "#E53A40"
                }}
                onClick={() => {
                  setSender(original)
                  // reconnectSender()
                }}
              >
                Fix It
              </span>
              <Tooltip anchorSelect={`#sender-type-${original?.id}`} place="top">
                {original?.microsoft_email ? "Outlook" :""}
                {original?.google_email ? "Gmail (Google Workspace)" :""}
                {showSenderName(original?.serviceName)}
              </Tooltip>
            </div>
          }
        </div>
      </div>

      <Tooltip anchorSelect={`#sender-fix-${original?.id}`} clickable>
        {!original.completed ? "Connecting..." : <span>Your email service provider is responding with an Error. <a style={{ color: 'white', cursor: 'pointer' }} type='button' onClick={() => setSender(original)}>Learn More</a></span>}
      </Tooltip>
    </>
  );
}

const validateFakeInputFields = (value, name) => {
  if (value === '' || parseInt(value) <= 0) return { success: false, message: `${name} cannot be less than 1.` };
  if (parseInt(value) > 100) return { success: false, message: `${name} cannot be more than 100.` };
  return { success: true, message: 'Valid value' };
}

const WarmUpSettingsModal = ({ 
  setShowModal, 
  emailFreq, 
  rampUpEnabled, 
  rampUpFreq, 
  maxDailyFreq, 
  selectedTemplate, 
  selectedWarmupUrls, 
  templates,
  warmuplinks, 
  fetchTemplates, 
  fetchLinks, 
  loadingLinks,
  selectedSender,
  setSelectAll,
  setSelectedSender,
  getStats,
  open_rate,
  spam_protection,
  read_emulation,
  setUpdatedValues
}) => {
  const [emailFrequency, setEmailFrequency] = useState(emailFreq);
  const [autoRampUpEnabled, setAutoRampUpEnabled] = useState(rampUpEnabled);
  const [rampUpFrequency, setRampUpFrequency] = useState(rampUpFreq);
  const [maxDailyFrequency, setMaxDailyFrequency] = useState(maxDailyFreq);
  const [template, setTemplate] = useState(selectedTemplate);
  const [warmupUrls, setWarmupUrls] = useState(selectedWarmupUrls);
  const [saving, setSaving] = useState(false);
  const [openRate, setOpenRate] = useState(open_rate);
  const [spamProtection, setSpamProtection] = useState(spam_protection);
  const [readEmulation, setReadEmulation] = useState(read_emulation);

  const saveSettings = async () => {
    if(emailFrequency === "" || parseInt(emailFrequency) <= 0) return Toast.fire({ icon: 'error', title: `Minimum warmup email frequency cannot be less than 1.` });
    if(parseInt(emailFrequency) >= 250) return Toast.fire({ icon: 'error', title: `Maximum warmup email frequency cannot be more than 250.` });
    const fakeFeatures = [
      { name: 'Open Rate', value: openRate }, 
      { name: 'Spam Protection', value: spamProtection }, 
      { name: 'Read Emulation', value: readEmulation }
    ];
    for (const feature of fakeFeatures) {
      const validatedResult = validateFakeInputFields(feature.value, feature.name);
      if (!validatedResult.success) return Toast.fire({ icon: 'error', title: validatedResult.message });
    };
    if(autoRampUpEnabled) {
      if(maxDailyFrequency === '' || rampUpFrequency === '') return Toast.fire({ icon: 'error', title: `Frequencies cannot be null.` });
      if(parseInt(emailFrequency) >= parseInt(maxDailyFrequency)) return Toast.fire({ icon: 'error', title: `Max Daily Frequency should be more than Daily Email Frequency.` });
      if(parseInt(maxDailyFrequency) > 250) return Toast.fire({ icon: 'error', title: `Max Daily Frequency cannot be more than 250.` });
      if(parseInt(rampUpFrequency) >= parseInt(maxDailyFrequency)) return Toast.fire({ icon: 'error', title: `Ramp up Frequency cannot be more than Max Daily Frequency.` });
      if(parseInt(rampUpFrequency) >= 250) return Toast.fire({ icon: 'error', title: `Ramp up Frequency cannot be more than 250.` });
    }
    setSaving(true);
    try {
      const ids = typeof selectedSender !== 'string' ? selectedSender.join(',') : selectedSender;
      const data = {
        updateWarmup: true,
        warmupEnabled: true,
        warmupURLs: warmupUrls,
        warmupTemplates: template,
        emailFrequency,
        autoRampUpEnabled,
        rampUpFrequency,
        maxDailyFrequency,
        openRate,
        spamProtection,
        readEmulation
      };
      const res = axios.put(`/api/senders/${ids}/save`, data);
      setShowModal(false);
      let result = await res;
      if (res?.data?.success || result?.data?.success) {
        Swal.fire({
          icon: 'success',
          title: 'WarmUp Settings Updated',
          html: `Daily frequency update takes 24 hours to take effect.`,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        }).then(() => {
          if (typeof selectedSender !== 'string') {
            setSelectAll([]);
            setSelectedSender([]);
          } else {
            setUpdatedValues(
              emailFrequency,
              autoRampUpEnabled,
              rampUpFrequency,
              maxDailyFrequency,
              openRate,
              spamProtection,
              readEmulation,
              template,
              warmupUrls,
            );
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error in Updating Sender WarmUp!',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
      }
    } catch (error) {
      console.log('Could not update the WarmUp Settings', error);
      Swal.fire({
        icon: 'error',
        title: `Oops! Could Not Update Sender WarmUp`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    } finally {
      setSaving(false);
    }
  }

  return (
    <CustomModal
      title="WarmUp Settings"
      close={true}
      onClose={() => setShowModal(false)}
    >
      <p className="text-modal-description">
        Configure Email WarmUp settings for improved email deliverability.
      </p>
      <div style={{ padding: 10 }}>
        <NumberInput
          required={true}
          label={<div style={{ display: 'inline-flex' }}>
            Daily Email Frequency
            <UilQuestionCircle
              size={18}
              id={"daily-email-frequency"}
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </div>}
          value={emailFrequency}
          max={250}
          min={1}
          name="email_frequency"
          onChange={(name, value) => {
            if (value === '') setEmailFrequency(value)
            else if (value <= 0) setEmailFrequency(1)
            else if (value >= 250) setEmailFrequency(250)
            else setEmailFrequency(value)
          }}
          placeholder="Daily Email Frequency"
        />
        <Tooltip anchorSelect={`#daily-email-frequency`} place={"right"}>
          Max no. of WarmUp emails to be sent from your account per day. <br />
          Changes can take upto 1 week to keep your account safe.
        </Tooltip>

        <div className='flex mb-4'>
          <input type='checkbox' id="autoRampUp" checked={autoRampUpEnabled} onChange={() => setAutoRampUpEnabled(prev => !prev)} />
          <label htmlFor="autoRampUp" className='font-medium' style={{ marginLeft: 5, marginTop: 1 }}>Enable Auto Ramp Up</label>
          <UilQuestionCircle
            size={18}
            id={"auto-ramp-up"}
            style={{ marginLeft: 5, marginTop: 4, cursor: 'pointer' }}
          />
          <Tooltip anchorSelect={`#auto-ramp-up`} place={"right"}>
            Automatically increase daily email frequency.
          </Tooltip>
        </div>

        {autoRampUpEnabled &&
          <div className='mb-4'>
            <NumberInput
              required={true}
              label={<div style={{ display: 'inline-flex' }}>
                Increase Daily Ramp Up Everyday By
                <UilQuestionCircle
                  size={18}
                  id={"rampup-frequency"}
                  style={{ marginLeft: 5, marginTop: 1, cursor: 'pointer' }}
                />
              </div>}
              value={rampUpFrequency}
              min={1}
              max={250}
              name="rampup_frequency"
              onChange={(name, value) => {
                if (value === '') setRampUpFrequency(value)
                else if (value <= 0) setRampUpFrequency(1)
                else if (value >= 250) setRampUpFrequency(250)
                else setRampUpFrequency(value)
              }}
              placeholder="Number of extra emails to send per day."
            />
            <Tooltip anchorSelect={`#rampup-frequency`} place={"right"}>
              By how much should frequency increase every day.
            </Tooltip>

            <NumberInput
              required={true}
              label={<div style={{ display: 'inline-flex' }}>
                Max Daily Frequency
                <UilQuestionCircle
                  size={18}
                  id={"daily-rampup-frequency"}
                  style={{ marginLeft: 5, marginTop: 2, cursor: 'pointer' }}
                />
              </div>}
              value={maxDailyFrequency}
              min={1}
              max={250}
              name="daily_rampup_frequency"
              onChange={(name, value) => {
                if (value === '') setMaxDailyFrequency(value)
                else if (value <= 0) setMaxDailyFrequency(1)
                else if (value >= 250) setMaxDailyFrequency(250)
                else setMaxDailyFrequency(value)
              }}
              placeholder="Max daily warmup frequency."
            />
            <Tooltip anchorSelect={`#daily-rampup-frequency`} place={"right"}>
              Max number of warmup emails to send per day after ramp up.
            </Tooltip>
          </div>
        }

        <NumberInput
          label={<div style={{ display: 'inline-flex' }}>
            Open Rate
            <UilQuestionCircle
              size={18}
              id={"open-rate"}
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </div>}
          value={openRate}
          max={100}
          min={1}
          name="open_rate"
          onChange={(name, value) => {
            if (value === '') setOpenRate(value)
            else if (value <= 0) setOpenRate(1)
            else if (value > 100) setOpenRate(100)
            else setOpenRate(value)
          }}
          placeholder="70"
          showPercent={true}
        />
        <Tooltip anchorSelect={`#open-rate`} place={"right"}>
          Percentage of your warm up emails to open
        </Tooltip>

        <NumberInput
          label={<div style={{ display: 'inline-flex' }}>
            Spam Protection Rate
            <UilQuestionCircle
              size={18}
              id={"spam-protection"}
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </div>}
          value={spamProtection}
          max={100}
          min={1}
          name="spam_protection"
          onChange={(name, value) => {
            if (value === '') setSpamProtection(value)
            else if (value <= 0) setSpamProtection(1)
            else if (value > 100) setSpamProtection(100)
            else setSpamProtection(value)
          }}
          placeholder="80"
          showPercent={true}
        />
        <Tooltip anchorSelect={`#spam-protection`} place={"right"}>
          Percentage of your warm up emails to save from spam folder
        </Tooltip>

        <NumberInput
          label={<div style={{ display: 'inline-flex' }}>
            Read Emulation Rate
            <UilQuestionCircle
              size={18}
              id={"read-emulation"}
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </div>}
          value={readEmulation}
          max={100}
          min={1}
          name="read_emulation"
          onChange={(name, value) => {
            if (value === '') setReadEmulation(value)
            else if (value <= 0) setReadEmulation(1)
            else if (value > 100) setReadEmulation(100)
            else setReadEmulation(value)
          }}
          placeholder="80"
          showPercent={true}
        />
        <Tooltip anchorSelect={`#read-emulation`} place={"right"}>
          Percentage of your emails to Spend time and scroll through <br /> your warmup email to emulate human-like reading
        </Tooltip>

        <div>
          <label className="relative mb-2 font-semibold flex">
            WarmUp Templates
            <UilQuestionCircle
              size={18}
              id={"warmup-templates"}
              style={{ marginLeft: 5, marginTop: 2, cursor: 'pointer' }}
            />
          </label>
          <Tooltip anchorSelect={`#warmup-templates`} place={"right"}>
            WarmUp the templates that you will use in cold emails for better email deliverability.
          </Tooltip>
          <AsyncSelect
            styles={{
              control: () => ({
                padding: 5,
                width: '100%',
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #D7D7D7',
                backgroundColor: "#f9f9f9"
              }),
            }}
            placeholder="Select Email Templates to WarmUp"
            value={template}
            defaultOptions={templates}
            isClearable
            isMulti={true}
            loadOptions={fetchTemplates} // Function to fetch template options asynchronously
            onChange={(values) => setTemplate(values)}
            noOptionsMessage={() => 'No Results, Type to Search'}
          />

          <a
            href={`${window.location.origin}/outreach/templates`}
            target="_blank"
            style={{ color: '#3366FF', marginLeft: 10 }}
          >
            Add Templates for WarmUp
          </a>
          <label className="relative mb-2 font-semibold mt-3 flex">
            WarmUp Links
            <UilQuestionCircle
              size={18}
              id={"warmup-links"}
              style={{ marginLeft: 5, marginTop: 2, cursor: 'pointer' }}
            />
          </label>
          <Tooltip anchorSelect={`#warmup-links`} place={"right"}>
            WarmUp your website links that you will use in cold emails so that your emails with the link land in inbox.
          </Tooltip>
          <Row>
            <Col xs={10} md={11}>
              <ReactSelect
                styles={{
                  control: () => ({
                    padding: 5,
                    width: '100%',
                    borderRadius: 5,
                    display: 'flex',
                    border: '1px solid #D7D7D7',
                    backgroundColor: "#f9f9f9"
                  }),
                }}
                placeholder="Select Links to WarmUp"
                isMulti={true}
                defaultValue={warmupUrls}
                options={warmuplinks?.map((wlink) => ({
                  label: wlink.label,
                  value: wlink.id,
                }))}
                onChange={(values) => setWarmupUrls(values)}
              />
              <a
                href={`${window.location.origin}/outreach/settings`}
                target="_blank"
                style={{ color: '#3366FF', marginLeft: 10 }}
              >
                Add Links for WarmUp
              </a>
            </Col>
            <Col xs={2} md={1}>
              <UilSync
                style={{
                  marginTop: 15,
                  cursor: `${loadingLinks ? 'not-allowed' : 'pointer'}`,
                }}
                id="refresh-templates"
                className={`${loadingLinks ? 'rotate' : ''}`}
                onClick={() => {
                  if (!loadingLinks) fetchLinks();
                }}
              />
              <Tooltip anchorSelect="#refresh-templates" place="top">
                Refresh
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: 24,
            justifyContent: 'end',
            width: '100%',
          }}
        >
          {!saving && (
            <Button
              variant="primary"
              action={saveSettings}
              text="Save"
            ></Button>
          )}
          {saving && (
            <UilSync
              style={{
                marginTop: 15,
                marginRight: 30,
                cursor: `not-allowed`,
              }}
              className={`rotate`}
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
};

function CreateFolder(props) {
  const [folderName, setFolderName] = useState('');

  async function createFolder() {
    try {
      if (folderName?.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Enter a valid folder name',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });
        return;
      }

      await axios.post(`/api/list/folders`, {
        name: folderName,
        type: 'email-sender'
      });
      if (props.onCreate) props.onCreate();
    } catch (err) {
      const message = err?.response?.data?.message ?? 'Error in creating new folder';
      Swal.fire({
        icon: 'error',
        title: message,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  }

  return (
    <div>
      <h1 className="text-modal-header">Create New Folder</h1>
      <p className="text-modal-description">
        Organize your Email Sender in folders.
      </p>
      <div style={{ paddingLeft: 14, paddingRight: 15 }}>
        <TextInput
          required={true}
          label="Folder Name"
          value={folderName}
          onChange={(nme, value) => {
            setFolderName(value);
          }}
          placeholder="Enter a Folder Name"
        />
      </div>
      <Row>
        <div
          style={{
            display: 'flex',
            margin: '16px 32px',
            justifyContent: 'end',
            width: '100%',
            gap: '2rem',
          }}
        >
          <Button
            id='folderButtons'
            variant="primary grey"
            action={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
            text="Cancel"
          />
          <Button
            id='folderButtons'
            variant="primary"
            action={() => createFolder()}
            text="Create Folder"
          />
        </div>
      </Row>
    </div>
  );
}

function MoveToFolder(props) {
  const [folderID, setFolderID] = useState('');
  const [folderName, setFolderName] = useState('');

  async function moveToFolder() {
    console.log('FOLDAA ', folderID);
    try {
      if (folderID?.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Select a folder',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });
        return;
      }

      await axios.put(`/api/senders/${props.sequenceID.join(',')}/updateFolder`, {
        folder: folderID,
      });
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in creating new folder',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  }

  return (
    <div>
      <div>
        <h1 className='text-2xl text-modal-header'>Move Email Sender(s) to Folder.</h1>
        <p className='text-modal-description'>Moving {props.sequenceID?.length} Email Sender(s) to Folder</p>
      </div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <ReactSelect
          placeholder="Select Folder"
          defaultValue={folderID}
          options={props?.folders?.map((userObj) => ({
            label: userObj?.name,
            value: userObj?.id,
          }))}
          onChange={(selected) => {
            setFolderID(selected.value);
            setFolderName(selected?.label)
          }}
        />

        <Row>
          <div
            style={{
              display: 'flex',
              margin: '0 16px',
              marginTop: 11,
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              id='folderButtons'
              style={{ marginLeft: 'auto', marginRight: 5 }}
              variant="primary grey"
              text="Cancel"
              action={() => {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
            <Button
              id='folderButtons'
              variant="primary"
              style={{ marginLeft: 15 }}
              text={`Move ${folderName ? "to " + folderName : ""}`}
              action={() => moveToFolder()}
            />
          </div>
        </Row>
      </div>
    </div>
  );
}

export function EmailSenders(props) {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [senderAddDialogVisible, setAddSenderDialogVisible] = useState(false);
  const [senderAddDialogType, setAddSenderDialogType] = useState('');
  const [senderEditDialogVisible, setEditSenderDialogVisible] = useState(false);
  const [selectedSender, setSelectedSender] = useState(null);
  const [showSetupWizard, setShowSetupWizard] = useState(false);
  const [showBlukUploader, setShowBlukUploader] = useState(false);
  const [step, setStep] = useState('sender');
  const [isloading, setIsloading] = useState(true);
  const [planUsage, setPlanUsage] = useState(null);
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [warmuplinks, setWarmUpLinks] = useState([]);
  const [viewWarmup, setViewWarmup] = useState(null);
  const [viewWarmupSender, setViewWarmupSender] = useState(null);
  const [senders, setSenders] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState([]);
  const [senderCount, setSenderCount] = useState(0);
  const [statsFilter, setStatsFilter] = useState('');
  const [showDetails,setShowDetails] = useState(false);
  const [senderDetails,setSenderDetails] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sendersBeingDeleted, setSendersBeingDeleted] = useState(null);
  const [entries, setEntries] = useState([]);
  const [reconnectLoading,setReconnectLoading] = useState(false);
  const [showWarmupConsent, setShowWarmupConsent] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [senderSelectedForWarmup, setSenderSelectedForWarmup] = useState({});
  const usersApi = useAPI('/api/account/owner-list');
  const [filterVisible, setFilterVisible] = useState(false);
  const [folders, setFolders] = useState([])
  const [searchFolder, setSearchFolder] = useState("")
  const [pageTitle, setPageTitle] = useState("")
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [moveToFolder, setMoveToFolder] = useState(false);
  const [showDeleteFolderConfirmationModal, setShowDeleteFolderConfirmationModal] = useState(false);
  const [selectedFolderToDelete, setSelectedFolderToDelete] = useState(null);
  const deviceWidth = useWindowResize();
  
  // warmup modal
  const [showModal, setShowModal] = useState(false);

  const [ownedBy, setOwnedBy] = useState(authContext?.user?.id);
  const [users, setUsers] = useState([]);
  const { whitelabelDetails } = useContext(WhitelabelContext);

  const fetchFolders = async () => {
    try {
      let url = '/api/lists/folders?type=email-sender';
      let result = await axios.get(url);
      setFolders(result?.data?.data || []);
    } catch (err) {
      console.log('Error in getting data ', err);
    }
  }

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (usersApi?.data?.users) {
      let users =  usersApi.data.users?.map((userObj) => ({
        label: userObj?.name + `${userObj?.id === authContext.user.id ? ' (Me)' : ''}`,
        value: userObj?.id,
      }))
      users.unshift({
        label: "Owned by Anyone",
        value: '',
      })
      setUsers(users);
    } 
  }, [usersApi]);

  useEffect(()=>{
    if(ownedBy && ownedBy?.length>0){
      fetchSenders()
    }
  },[ownedBy])

  const deletedSenderSet = useMemo(() => {
    return new Set(sendersBeingDeleted?.split(','));
  }, [sendersBeingDeleted]);

  useEffect(() => {
    if (sendersBeingDeleted) {
      const entriesList = sendersBeingDeleted.split(',').map(senderId => ({
        beingDeletedString: senderId,
        replacingString: 'n/a',
      }));
      setEntries(entriesList);
    }
  }, [sendersBeingDeleted]);

  const handleReplacementChange = (event, index) => {
    const updatedEntries = [...entries];
    updatedEntries[index].replacingString = event.value;
    setEntries(updatedEntries);
  };

  const fetchAvsend = async (inputValue) => {
    try {
      const response = await axios.get(`/api/senders?limit=10&skip=0&search=${inputValue}&ninids=${sendersBeingDeleted}&ownedby=${ownedBy}`);
      if(!response.data.data?.length) {
        return [];
      }
      let senderList = response.data.data.map((sender) => ({
        key: sender.id,
        label: `${
          sender.microsoft_email ||
          sender.google_email ||
          sender.smtpImapEmail
        }`,
        value: sender.id,
      }))
      return senderList;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const auth = useAPI('/api/auth');
  const urls = useAPI('/api/oauthurls');

  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );

  useEffect(() => {
    getActivePlans();
    const searchParams = decodeQuery(window.location.search);
    if (searchParams.exists) {
      Swal.fire({
        icon: 'error',
        title: 'Sender Already Exists.',
        showCancelButton: false,
      }).then(
        (result) =>
          (window.location.href = `${window.location.protocol}//${window.location.host}/outreach/email-senders`)
      );
    }
    fetchTemplates('');
    fetchLinks();
  }, []);

  function reloadWindow() {
    let searchParams = new URLSearchParams();
    searchParams.append('limit', perPage);
    searchParams.append('skip', page);
    searchParams.append('search', search)
    if (statsFilter !== "") searchParams.append('filter', statsFilter)
    if (searchFolder?.length > 0) searchParams.append('folder', searchFolder);
    fetchSenders(searchParams.toString());
  }

  useEffect(() => {
    reloadWindow();
  }, [perPage, page, search, statsFilter, ownedBy, searchFolder]);

  useEffect(() => {
    if (senders?.length > 0) {
      const searchParams = decodeQuery(window.location.search);
      let stepValue = searchParams.step;
      if (stepValue) {
        Swal.fire({
          icon: 'success',
          title: 'Email Sender Connected',
          showCancelButton: false,
          confirmButtonText: `Next`,
          confirmButtonColor: '#0066ff',
        });
        setSelectedSender(
          senders.find((s) => s.id === searchParams.sender)
        );
        setShowSetupWizard(true);
        setStep(stepValue);
      }
    }
  }, [senders]);

  useEffect(()=>{
    if(showDetails){
      fetchDetails()
    }
  },[showDetails,selectedSender])

  async function fetchSenders(searchQuery) {
    setIsloading(true)
    try {
      let url = '/api/senders';
      if (searchQuery?.length > 0) {
        url += '?' + searchQuery;
        if(ownedBy) url += "&ownedby=" + ownedBy
      }else{
        if(ownedBy) url += "?ownedby=" + ownedBy
      }
      let result = await axios.get(url);
      setSenders(result?.data?.data.map((s) => {
        s['email'] = s.alias || s.microsoft_email || s.google_email || s.smtpImapEmail;
        return s;
      }));
      setSenderCount(result.data.count);
      setTotalPages(Math.ceil(result.data.count / perPage));
    } catch (err) {
      console.log('Error in getting sequences ', err.response);
    }
    setIsloading(false)
  }

  async function getActivePlans() {
    try {
      let res = await axios.get(`/api/account/plan`);
      setPlanUsage(res.data.data);
    } catch (err) {}
  }

  async function fetchTemplates(inputValue) {
    try {
      let result = await axios.get(
        `/api/template?type=Email&limit=10&search=${inputValue}`
      );
      const data = result?.data?.data;

      // Transform the fetched data into options array
      const options = data.map((item) => ({
        key: item._id,
        label: item.name,
        value: item._id,
      }));
      setTemplates(options);

      return options;
    } catch (err) {
      return [];
    }
  }

  async function fetchLinks() {
    try {
      setLoadingLinks(true);
      let result = await axios.get('/api/warmuplinks');
      setWarmUpLinks(result.data?.data);
      setLoadingLinks(false);
    } catch (err) {
      setLoadingLinks(false);
    }
  }

  const saveSender = async (id, data, message) => {
    try {
      let res = await axios.put(`/api/senders/${id}/save`, data);
      Toast.fire({ icon: 'success', title: message });
    } catch (err) {}
  };

  const showSenderIcon = (host) => {
    if (host?.includes('gmail'))
      return <img src={GmailSVG} alt="Gmail" width={15} height={15} />;
    else if (host?.includes('office365'))
      return <img src={OutlookSVG} alt="Outlook" width={15} height={15} />;
    else if (host?.includes('zoho'))
      return <img src={ZohoSVG} alt="Zoho Mail" width={15} height={15} />;
    else return <img src={GmailSVG} alt="Gmail" width={15} height={15} />;
  };

  const showSenderName = (serviceName) => {
    if (serviceName === 'gmail') return "Gmail"
    else if (serviceName === 'zoho') return "Zoho Mail"
    else if (serviceName === 'smtponly') return "SMTP"
    else if (serviceName === 'smtpimap') return "SMTP/IMAP"
    return ""
  };

  const reconnectSenders = async (multi) => {
    try {
      setReconnectLoading(true)
      let url = '/api/senders/bulk-reconnect'
      if(multi) url += `?ids=${multi}`
      let result = await axios({
        method: 'POST',
        url
      });
      
      Swal.fire({
        icon: 'success',
        title: 'Reconnecting Email Senders',
        html: '',
        text: "It might take a few minutes depending on the number of email senders.",
        showCancelButton: false,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066FF',
      });

      setTimeout(() => {
        setReconnectLoading(false)
        window.location.reload();
      }, 30000)
    } catch (err) {
      setReconnectLoading(false)
      Toast.fire({ icon: 'error', title: "Unable to re-connect senders" });
    }
  }

  const fetchDetails = async ()=>{
    try {
      // setLoadingLinks(true)
      const { data } = await axios.get(`/api/senders/${selectedSender.id}/details`);
      setSenderDetails({ ...senderDetails, ...data.data })
    } catch (error) {
      Toast.fire({ icon: 'error', title: "Unable to fetch sender details" });
    } finally {
      setLoadingLinks(false)
    }
  }

  const enableWarmup = async (original=senderSelectedForWarmup) => {
    try {
      const data = {
        updateWarmup: true,
        warmupEnabled: true,
      }
      const res = await axios.put(`/api/senders/${original.id}/save`, data);
      if (res?.data?.success) {
        Swal.fire({
          icon: 'success',
          title: 'WarmUp Settings Updated',
          html: `Daily frequency update takes 24 hours to take effect.`,
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error in Updating Sender WarmUp!',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
      }
    } catch (error) {
      console.log('Could not update the WarmUp Settings', error);
      Swal.fire({
        icon: 'error',
        title: `Oops! Could Not Update Sender WarmUp`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    }
  }

  const warmupConsentEnable = (original) => {
    const localStorageWarmupEnabledIdentifier = JSON.parse(localStorage.getItem('wOn'));
    if (localStorageWarmupEnabledIdentifier === null && original.warmup_last_sent === 1) {
      setShowWarmupConsent(true);
    } else {
      enableWarmup(original);
    }
  }

  const columns = [
    {
      Header: (
        <>
          {senders?.length > 0 && (
            <CustomDropdown
              type="select"
              checked={selectAll?.length === senders?.length}
              disabled={selectAll?.length === 0}
              indeterminate={
                selectAll?.length > 0 && selectAll?.length < senders?.length
              }
              selectAction={() => {
                if (selectAll?.length === senders?.length) setSelectAll([]);
                else if (
                  selectAll?.length > 0 &&
                  selectAll?.length < senders?.length
                )
                  setSelectAll([]);
                else {
                  let selectArr = [];
                  let someSenders = '';
                  for (let i = 0; i < senders?.length; i++) {
                    selectArr.push(senders[i]?.id);
                    if(!someSenders) someSenders = senders[i]?.id; 
                    else someSenders += ',' + senders[i]?.id;
                  }
                  setSendersBeingDeleted(someSenders)
                  setSelectAll(selectArr);
                }
              }}
              items={[
                {
                  key: 'settings',
                  title: 'Sender Settings',
                  icon: <UilSetting size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    setStep("domain")
                    setSelectedSender(selectAll);
                    setEditSenderDialogVisible(true);
                  },
                },
                {
                  key: 'warmup',
                  title: 'WarmUp Settings',
                  icon: <UilFire size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    setSelectedSender(selectAll);
                    setShowModal(true);
                  }
                },
                {
                  title: searchFolder ? "Remove from Folder" : 'Move to Folder',
                  icon: searchFolder ? <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if(searchFolder) removeFromFolder(selectAll)
                    else setMoveToFolder(true);
                  },
                },
                {
                  key: 'reconnect',
                  title: 'Reconnect',
                  icon: <UilRedo size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    reconnectSenders(selectAll.join(','))
                  },
                },
                {
                  title: 'Delete',
                  icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    setShowDeleteModal(true);
                    setSendersBeingDeleted(selectAll.join(','));
                  },
                }
              ]}
              align="left"
            />
          )}
        </>
      ),
      Footer: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { cells } = row;
        let id = cells[0].value;
        let isSelected = false;

        if (selectAll.find((item) => item === id)) isSelected = true;
        return (
          <input
            type="checkbox"
            className="csCheckbox"
            style={{ marginLeft: 10 }}
            defaultChecked={isSelected}
            onChange={(e) => {
              if (selectAll.find((item) => item === id))
                setSelectAll(selectAll.filter((item) => item !== id));
              else setSelectAll([...selectAll, id]);
              // console.log(selectAll);
            }}
          />
        );
      }, // formatting date
    },
    {
      Header: 'Name',
      Footer: 'Name',
      accessor: 'senderName',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        return <div className='flex flex-col gap-1'>
          <p>
            {value}
          </p>
          {row.original?.folder && <p className='flex gap-2 items-center' style={{ color: '#9ca4ae' }}><UilFolder size={20} />{row.original.folderName}</p>}
        </div>
      }
    },
    {
      Header: 'Email Address',
      Footer: 'Email Address',
      accessor: 'email',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;

        let email = original.microsoft_email || original.google_email || original.smtpImapEmail;
        let senderDomain = email?.split('@')[1];
        let freeDomain = isDomainFree(senderDomain);

        if((original.sendingEnabled && original.serviceName === "smtponly") || (original.sendingEnabled && original.receivingEnabled)) return <div className='flex items-center'>
          <span className="csBadge info" style={{ padding: 4, width: 28, height: 28 }} id={`sender-type-${original?.id}`}>
            {original?.microsoft_email
              ? showSenderIcon('office365')
              : showSenderIcon(original.smtpHost)}
          </span>
          {(typeof original.deliverability === "boolean" || freeDomain) && <span
            id={`deliverablity-reports-${original?.id}`}
            className={`csBadge ${(original.deliverability && !freeDomain) ? "success" : "warning"} text-center`}
            style={{ marginLeft: 3, padding: 3, cursor: 'pointer', width: 28, height: 28, color: (original.deliverability && !freeDomain) ? "#3AC569" : "#e8b105", fontSize: 14 }}
            onClick={() => {
              if(!freeDomain) navigate(`/outreach/email-senders/${original.id}/deliverability`)
            }}
          >
            {(original.deliverability && !freeDomain) ? <UilCheck size={17} /> : <UilExclamationTriangle size={17} />}
          </span>}
          {(original.smtpHost === 'smtp.titan.email' || original.smtpHost === 'smtp.protonmail.ch') && <div style={{ marginLeft: 3 }}>
            <span className="csBadge warning" style={{ height: 28, color: '#e8b105', size: 12 }} id={`sender-host-${original?.id}`}>
              Caution
            </span>
            <Tooltip anchorSelect={`#sender-host-${original?.id}`} place="top">
              {`${original.smtpHost === 'smtp.titan.email' ? 'Titan' : 'Proton'} Email is not fully supported & is not ideal for sending cold emails.`}
            </Tooltip>
          </div>}
          <span style={{ marginLeft: 3 }}>{value}</span>
          <Tooltip anchorSelect={`#deliverablity-reports-${original?.id}`} place="top">
            {freeDomain ? "Free emails have poor deliverability" : (typeof original.deliverability === "boolean" && original.deliverability) ? "Domain Setup Is Correct" : "Click To View Issues"}
          </Tooltip>
          <Tooltip anchorSelect={`#sender-type-${original?.id}`} place="top">
            {original?.microsoft_email ? "Outlook" :""}
            {original?.google_email ? "Gmail (Google Workspace)" :""}
            {showSenderName(original?.serviceName)}
          </Tooltip>
        </div>

        return <ErrorView 
          email={value}
          body={original.error || ""}
          original={original}
          urls={urls}
          setSelectedSender={setSelectedSender}
          setEditSenderDialogVisible={setEditSenderDialogVisible}
          showSenderIcon={showSenderIcon}
          showSenderName={showSenderName}
          reconnectSenders={reconnectSenders}
        />
      }
    },
    // {
    //   Header: 'Service',
    //   Footer: 'Service',
    //   accessor: 'serviceName',
    //   disableSortBy: true,
    // },
    {
      Header: <span className="csCenter">Enabled</span>,
      accessor: 'sendingEnabled',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        if(!original.completed) return (
          <div className="text-center">
            <span className="csBadge warning" id="processing-email-sender" style={{ padding: 0 }}>
              <UilClock style={{ color: '#FBD355' }} />
            </span>
            <Tooltip anchorSelect="#processing-email-sender" place="top">
              Connecting...
            </Tooltip>
          </div>
        )

        if (original.sendingEnabled && original.receivingEnabled) return (
          <div className="text-center">
            <span className="csBadge success" id="sending-recieving-reconnect" style={{ padding: 0 }}>
              <UilExchangeAlt style={{ color: '#3AC569', transform: 'rotate(-45deg)' }} />
            </span>
            <Tooltip anchorSelect="#sending-recieving-reconnect" place="top">
              Both sending & receiving is enabled.
            </Tooltip>
          </div>
        );
          
        if(original.serviceName === "smtponly" && value) return (
          <div className="text-center">
            <span className="csBadge success" id="sending-enabled" style={{ padding: 0 }}>
              <UilArrowUpRight style={{ color: '#3AC569' }} />
            </span>
            <Tooltip anchorSelect="#sending-enabled" place="top">
              Only sending is enabled for SMTP senders.
            </Tooltip>
          </div>
        );

        return (
          <div className="text-center">
            <span
              className="csBadge danger"
              style={{
                padding: 5,
                cursor: original.senderType === 'OAUTH' ? 'pointer' : 'default',
              }}
              id="sending-reconnect"
              onClick={() => {
                if (original.senderType === 'OAUTH') {
                  if (original.microsoft_email)
                    window.location.href = urls?.data?.microsoftEmailUrl;
                  if (original.google_email)
                    window.location.href = urls?.data?.googleEmailUrl;
                }
              }}
            >
              <UilSyncExclamation color="#E53A40" size={16} />
            </span>
            <Tooltip anchorSelect="#sending-reconnect" place="top">
              Error with email sender, try reconnecting.
            </Tooltip>
          </div>
        );
      },
    },
    {
      Header: <span className="csCenter">Warmup</span>,
      accessor: 'warmupEnabled',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;

        if(!original.completed) return (
          <div className="text-center">
            <span className="csBadge warning" id={`processing-email-sender-${original?.id}`} style={{ padding: 0 }}>
              <UilClock style={{ color: '#FBD355' }} />
            </span>
            <Tooltip anchorSelect={`#processing-email-sender-${original?.id}`} place="top">
              Waiting to Connect
            </Tooltip>
          </div>
        )

        return <div className="text-center">
          {(value && original.sendingEnabled && original.receivingEnabled) ? 
            <span
              className="csBadge success"
              style={{ padding: 3, cursor: 'pointer' }}
              id={`check-warmup-reports-${original?.id}`}
              onClick={() => {
                setViewWarmup(original.id);
                setViewWarmupSender(
                  original.alias ||
                  original.microsoft_email ||
                  original.google_email ||
                  original.smtpImapEmail ||
                  original.smtpUsername
                );
              }}
            >
              <UilCheck color="#3AC569" size={20} />
            </span>
          :<span
            className="csBadge danger"
            style={{ padding: 3, cursor: original.serviceName === "smtponly" ? 'default' : 'pointer' }}
            id={`${(original.serviceName === "smtponly" && original.sendingEnabled && !original.receivingEnabled) ? "smtponly-warmup" : "no-warmup"}-${original?.id}`}
            onClick={() => {
              if (original.serviceName !== "smtponly") {
                if(original.block) {
                  const currentTime = new Date().getTime();
                  const fifteenDaysInMs = 15 * 24 * 60 * 60 * 1000;

                  if (currentTime >= Number(original.block) + fifteenDaysInMs) {
                    warmupConsentEnable(original);
                    setSenderSelectedForWarmup(original);
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'WarmUp Blocked',
                      html: '<h5>It was found to be negatively impacting other email addresses in the WarmUp pool.<br /><br /> Emails being sent to this email address were found to be bouncing off by our AI.<br /><br /> (For more details - contact your ESP.)<br /><br /> You can still use this email address for outreach.</h5>',
                      showCancelButton: false,
                      confirmButtonText: `Ok`,
                      confirmButtonColor: '#0066ff',
                    })
                  }
                } else {
                  warmupConsentEnable(original);
                  setSenderSelectedForWarmup(original);
                }
              }
            }}
          >
            <UilTimes color="#E53A40" size={20} />
          </span>}
          <Tooltip anchorSelect={`#check-warmup-reports-${original?.id}`} place="top">
            Click to view report.
          </Tooltip>
          <Tooltip anchorSelect={`#no-warmup-${original?.id}`} place="top">
            Enable WarmUp
          </Tooltip>
          <Tooltip anchorSelect={`#smtponly-warmup-${original?.id}`} place="top">
            Not available for SMTP Only emails.
          </Tooltip>
        </div>
      },
    },
    {
      Header: <span className="csCenter">Inbox</span>,
      accessor: 'inboxEnabled',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.serviceName === "smtponly") return null
        let email = original.email
        return <div className="flex items-center">
          <div className="flex m-auto" style={{ width: 60 }}>
            <Switch
              default={value}
              onChange={(name, val) =>{ saveSender(original.id, { inboxEnabled: val, updateInbox: true }, `Replies to ${email} will ${val?"":"not"} be visible in Inbox.`);  const newSenders = senders.map((item) => item === original ? { ...item, inboxEnabled: val } : item ); setSenders(newSenders)}}
            />
          </div>
        </div>
      },
    },
    {
      Header: <span className="csCenter">Actions</span>,
      accessor: 'action',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { cells, original } = row;

        let disabled = false;
        let email =
          original.microsoft_email ||
          original.google_email ||
          original.smtpImapEmail;
        let senderDomain = email?.split('@')[1];
        let freeDomain = isDomainFree(senderDomain);
        if (email && freeDomain) disabled = true;

        let actions = []

        actions.push({
          key: 'details',
          title: 'Details',
          icon: <UilFileInfoAlt size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            setLoadingLinks(true)
            setSenderDetails({emailAddress: original?.alias || original?.microsoft_email || original?.google_email || original?.smtpImapEmail || "",
              emailType: original?.serviceName,
              dailyLimit:original?.dailyEmailSendingLimit,
            createdAt:original?.createdAt,
            folderName: original?.folderName || ''
            })
            setSelectedSender(original);
            setShowDetails(true)
          },
        })

        if(!disabled && original.sendingEnabled) actions.push({
          key: 'deliverability-reports',
          title: 'Check Domain Setup',
          icon: <UilArrowGrowth size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            navigate(`/outreach/email-senders/${original.id}/deliverability`)
          },
        })

        if(original.warmupEnabled && original.sendingEnabled) actions.push({
          key: 'warmup-reports',
          title: 'Warmup Reports',
          icon: <UilFire size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            setViewWarmup(original.id);
            setViewWarmupSender(
              original.alias ||
              original.microsoft_email ||
              original.google_email ||
              original.smtpImapEmail ||
              original.smtpUsername
            );
          },
        })

        let folderOptions = {
          key: 'folder',
          title: 'Move to Folder',
          icon: <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            setMoveToFolder(cells[0].value);
          },
        }

        if(original.folder) folderOptions = {
          key: 'folder',
          title: 'Remove from Folder',
          icon: <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            removeFromFolder([original.id]);
          },
        }

        actions.push(folderOptions);

        actions = [...actions,
        {
          key: 'settings',
          title: 'Settings',
          icon: <UilSetting size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            setStep("domain")
            setSelectedSender(original);
            setEditSenderDialogVisible(true);
          },
        },
        {
          key: 'delete',
          title: 'Delete',
          icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
          action: () => {
            setShowDeleteModal(true);
            setSendersBeingDeleted(original.id);
          },
        }]

        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              className="csBadge warningOutline ml-2 mr-2"
              style={{ padding: 4 }}
              data-tooltip-content="Edit"
              data-tooltip-id={`edit-${original.id}`}
            >
              <UilEdit
                color="#FBD355"
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedSender(original);
                  setEditSenderDialogVisible(true);
                }}
              />
            </span>
            <Tooltip id={`edit-${original.id}`} />
            <CustomDropdown
              right={true}
              items={actions}
              style={{ padding: 4 }}
              align="left"
            />
          </div>
        );
      },
    },
  ];

  /**
   * `updates circular thingy when user or plan updates`
   * 
   * this is commented out because we're currently offering unlimited senders, which means technically the circular thing shouldn't fill
   * uncomment the code below incase/when plans are updated and we need to show the users how many email senders are connected
   */
  // useEffect(() => {
  //   const circularThingy = document.getElementById('gradient-thingy-wrapper');
  //   const degrees =
  //     (planUsage?.senderCount?.used / planUsage?.userCount?.limit) * 180;

  //   if (circularThingy && !isNaN(degrees))
  //     circularThingy.style.backgroundImage = `conic-gradient(from -90deg, #3AC569 ${degrees}deg, #f2f2f2 ${degrees}deg)`;
  // }, [planUsage, senders]);

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  const handleReplaceAndDelete = async () => {
    try {
      await axios.put('/api/senders/replace-delete', entries);
      setEntries([]);
      setSendersBeingDeleted('');
      setShowDeleteModal(false);
      Swal.fire({
        icon: 'success',
        title: 'Sender Deleted!',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      }).then(() => window.location.reload());
    } catch (error) {
      console.error('Error processing entries:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error in deleting sender',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      })
    }
  }

  const removeFromFolder = async (id) => {
    if (!id || id?.length === 0) return;
    const ids = id.join(",");
    await axios.put(`/api/senders/${ids}/updateFolder`, {
      folder: "",
    });
    let searchParams = new URLSearchParams();
    searchParams.append('limit', perPage);
    searchParams.append('skip', page);
    searchParams.append('search', search);
    if(statsFilter !== "") searchParams.append('filter', statsFilter);
    if (searchFolder?.length > 0) searchParams.append('folder', searchFolder);
    fetchSenders(searchParams.toString());
  };

  const deleteFolder = async (folderID) => {
    try {
      const response = await axios.delete(`/api/lists/folders/${folderID}`);
      if (searchFolder === folderID) {
        setPageTitle('');
        setSearchFolder(''); 
      }
      fetchFolders();
      reloadWindow();
      const sequencesUsingThisFolder = response?.data?.sequencesUsingThisFolder;
      if (sequencesUsingThisFolder && sequencesUsingThisFolder.length > 0) {
        const sequenceList = sequencesUsingThisFolder
          .map(sequence => `
            <p style="background-color: #e9e9e9; border-radius: 4px; padding-block: 6px; padding-inline: 16px; width: max-content; font-size: 14px;">
              ${sequence}
            </p>
          `)
          .join("");
      
        return Swal.fire({
          icon: 'info',
          title: 'Folder deleted successfully',
          html: `
            <h5>These sequences were using this folder:</h5>
            <div style="flex-wrap: wrap; margin-block: 16px;" class='flex gap-2 justify-center'>${sequenceList}</div>
            <p>Pause them, if you do not want them to use the email senders that were present in that folder.</p>
          `,
          showCancelButton: false,
          confirmButtonColor: '#0066FF',
        });
      }
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`Email Senders | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      {showModal && <WarmUpSettingsModal 
        setShowModal={setShowModal}
        emailFreq={5}
        rampUpEnabled={false}
        rampUpFreq={3}
        maxDailyFreq={100}
        selectedTemplate={[]}
        selectedWarmupUrls={[]}
        templates={templates}
        warmuplinks={warmuplinks}
        fetchTemplates={fetchTemplates}
        fetchLinks={fetchLinks}
        loadingLinks={loadingLinks}
        selectedSender={selectedSender}
        setSelectAll={setSelectAll}
        setSelectedSender={setSelectedSender}
        open_rate={60}
        spam_protection={80}
        read_emulation={80}
      />}
      { showDeleteModal && 
        <CustomModal close={true} width={500} onClose={() => setShowDeleteModal(false)}>
          <div style={{ padding: 10 }} id="deleteSenderModal">
            <h1
              className="text-2xl text-modal-header"
              style={{ marginTop: '-15px', padding: 0 }}
            >
              Delete Email Senders
            </h1>
            <p className="text-modal-description" style={{ padding: 0 }}>
              Remove email senders and replace them in existing sequences.
            </p>
            <hr className="mt-3 mb-3" />
            <div className="deleting-content flex flex-col">
            <CustomTable
              columns={[
                { Header: 'Email Sender (being deleted)', accessor: (row) => {
                  const senderEmail = senders.find(sender => sender.id === row.beingDeletedString)?.email;
                  return senderEmail || 'Email Not Found';
                }},
                {
                  Header: 'Replace Email Sender in Sequences with',
                  accessor: 'replacingString',
                  Cell: ({ value, row }) => {
                    const handleChange = (event) => {
                      handleReplacementChange(event, row.index);
                    };
                    return (
                      <AsyncSelect
                        styles={{
                          control: () => ({
                            padding: 5,
                            width: '100%',
                            borderRadius: 5,
                            display: 'flex',
                            border: '1px solid #D7D7D7',
                            backgroundColor: '#f9f9f9',
                          }),
                          menu: () => ({
                            borderRadius: 5,
                            border: '1px solid #D7D7D7',
                            backgroundColor: '#f9f9f9',
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999, marginTop: '0.25rem' }),
                        }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        onChange={handleChange}
                        loadOptions={fetchAvsend}
                        placeholder="Type to Search Senders"
                        noOptionsMessage={() => 'Search for an Email Sender'}
                        value={(function () {
                          const value = senders.find((sender) => sender?.id === row.original.replacingString);
                          if (value) {
                            const email = value.email;
                            return {label: email};
                          };
                          return {label: 'Don\'t Replace'};
                        })()}
                      />
                    );
                  },
                },
              ]}
              data={entries}
              pagination={false}
              noBottomMargin={true}
            />
          </div>
            <div
              style={{
                display: 'flex',
                marginTop: '1rem',
                gap: '2rem',
                justifyContent: 'end',
                width: '100%',
              }}
            >
            <Button
              className='custom-buttons'
              variant="primary grey"
              action={() => setShowDeleteModal(false)}
              text="Cancel"
            />
            <Button
              className='custom-buttons'
              variant="primary"
              action={handleReplaceAndDelete}
              text={`Save & Delete`}
            />
          </div>
          </div>
        </CustomModal>
      }
      {showDeleteFolderConfirmationModal && (
        <CustomDialog width={200}>
          <h2 className="text-2xl text-modal-header">
            Deleting Folder {selectedFolderToDelete.name}.
          </h2>
          <p style={{ paddingLeft: 15 }}>
            Assets within the folder will not be deleted.
          </p>
          <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Col md={6} className='addTopMargin'>
              <Button
                style={{ width: '100%' }}
                text="Cancel"
                variant="secondary grey"
                action={() => {
                  setSelectedFolderToDelete(null);
                  setShowDeleteFolderConfirmationModal(false);
                }}
              />
            </Col>
            <Col md={6}>
              <Button
                style={{ width: '100%', marginTop: deviceWidth > 768 ? 0 : 16 }}
                color="red"
                variant="primary red"
                text="Delete Folder"
                action={() => {
                  deleteFolder(selectedFolderToDelete.id)
                  setSelectedFolderToDelete(null);
                  setShowDeleteFolderConfirmationModal(false);
                }}
              />
            </Col>
          </Row>
        </CustomDialog>
      )}
      {showWarmupConsent && <CustomModal close={true} width={500} onClose={() => setShowWarmupConsent(false)}>
        <div style={{ padding: 10 }} className='flex flex-col items-center' id="deleteSenderModal">
          <h1
            className="text-2xl text-modal-header mb-4"
            style={{ marginTop: '-15px', padding: 0 }}
          >
            🔥 Warming Up
          </h1>
          <div className="text-modal-description text-center" style={{ padding: 0 }}>
            <p className='mb-2'>WarmUp helps you improve email deliverability.</p>
            <p className='mb-2'>Once Enabled, your email account will start receiving random AI generated emails between other users in {whitelabelDetails?.agencyName}’s WarmUp Pool.</p>
            <p className='mb-4'>⚠️ Please Note, we may remove your email address from WarmUp pool if we notice anything unusual that might hurt quality of the pool for other users.</p>
            {authContext?.user?.permission !== 'client' && <a className='mb-4' style={{ color: '#03A6FF' }} target='_blank' href='https://help.salesblink.io/en/articles/9080622-email-warmup'>Learn more about WarmUp here</a>}
          </div>
          <Checkbox style={{marginBottom: '1rem'}} option='Yes, I understand' checked={consentGiven} onChange={() => setConsentGiven(prev => !prev)} />
          <Button
            variant="primary"
            action={() => {
              localStorage.setItem('wOn', true);
              setShowWarmupConsent(false);
              enableWarmup();
            }}
            text={`Enable WarmUp`}
            disabled={!consentGiven}
          />
        </div>
      </CustomModal>}
      {!viewWarmup && (
        <Animate>
          {(showSetupWizard || senderEditDialogVisible) && (
            <SenderWizard
              step={step}
              onAction={(type, details) => {
                if (type === 'SMTP') {
                  setShowSetupWizard(false);
                  setAddSenderDialogVisible(true);
                  if (details) setAddSenderDialogType(details);
                }
              }}
              data={selectedSender?.length > 0 ? null : selectedSender}
              isMulti={selectedSender?.length > 0 ? selectedSender : false}
              setStep={setStep}
              close={() => {
                setShowSetupWizard(false);
                setSelectedSender(null);
                setEditSenderDialogVisible(false);
                setStep('sender');
                setShowBlukUploader(false)
              }}
              warmupAllowed={
                !planUsage?.warmUpCount ||
                (planUsage?.warmUpCount?.limit > 0 &&
                  planUsage?.warmUpCount?.limit > planUsage?.warmUpCount?.used)
              }
              uploadBulk={() => setShowBlukUploader(true)}
            />
          )}

          {showBlukUploader && <BulkSenderUploader 
            onSuccess={() => {
              setShowBlukUploader(false)
            }}
            onClose={() => {
              setShowBlukUploader(false)
              setStep('sender');
              setShowSetupWizard(true);
            }}
            planUsage={planUsage}
          />}

          <div id='email-sender-page-title' className="email-sender-header">
            <PageTitle
              title="Email Senders"
              description="Manage email addresses, working hours & improve email deliverability with email warmup."
              tooltip="Use these email senders to send emails with follow-ups on autopilot."
            />
            <div className="group">
              <CustomButton
                onClick={() =>  reconnectSenders()}
                iconOnly={true}
                variant="secondary"
                icon={<UilRedo style={{ color: reconnectLoading && '#FBD355' }}/>}
                id="refresh-email-senders"
                style={{
                  marginTop: 0,
                  minWidth: 'max-content',
                  outlineColor:reconnectLoading && '#FBD355',
                }}
                disabled={reconnectLoading}
              />
              <Tooltip anchorSelect={`#refresh-email-senders`}>
                {reconnectLoading ? 'Connecting' : 'Bulk (Re)Connect senders'}
              </Tooltip>
              <CustomButton
                onClick={() => {
                  setStep('sender');
                  setShowSetupWizard(true);
                  // if (planUsage) {
                    // if (
                    //   planUsage.senderCount?.limit > 0 &&
                    //   planUsage.senderCount?.limit >=
                    //     planUsage.senderCount?.used
                    // ) {
                      // setStep('sender');
                      // setShowSetupWizard(true);
                    // } else {
                    //   Swal.fire({
                    //     icon: 'info',
                    //     title: 'Upgrade to Access',
                    //     html: 'Your current plan does not allow you to do this.',
                    //     showCancelButton: true,
                    //     confirmButtonText: `Upgrade`,
                    //     confirmButtonColor: '#0066ff',
                    //   }).then(async (result) => {
                    //     if (result.isConfirmed === false) return;
                    //     navigate('/account/billing');
                    //   });
                    // }
                  // }
                }}
                title="Add Sender"
                variant="primary"
                icon={<UilPlusCircle />}
                className="layout-reverse"
                style={{
                  marginTop: 0,
                  minWidth: 'max-content',
                }}
              />
              {planUsage?.senderCount?.limit >= 100 && (
                  <div id='testing'>
                    <Tooltip anchorSelect={`#testing`} place='top'>
                      {planUsage?.senderCount?.used} emails connected.
                    </Tooltip>
                    <div id="gradient-thingy-wrapper" style={{backgroundImage: `conic-gradient(from -90deg, #3AC569 135deg, #f2f2f2 135deg)`}}>
                      <div className="plan-usage">
                        {`${planUsage?.senderCount?.used ?? '-'}/`}
                        <span style={{ fontWeight: 900 }}>&infin;</span>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>

          <Row>
            {moveToFolder && (
              <CustomDialog>
                <MoveToFolder
                  sequenceID={selectAll?.length > 0 ? selectAll : [moveToFolder]}
                  folders={folders}
                  onClose={() => setMoveToFolder(false)}
                  onSuccess={() => {
                    setMoveToFolder(false);
                    reloadWindow();
                  }}
                />
              </CustomDialog>
            )}
            <Col id='email-sender-filter' xs={12} lg={3}>
              <div
                style={{
                  height: '80vh',
                  borderRight: '1px solid #D7D7D7',
                  paddingRight: 30,
                  borderRadius: 0,
                }}
                className="templatesContaier"
              >
                <CustomSearch
                  value={search}
                  onChange={(e) => {setPage(0); setSearch(e);}}
                  placeholder={'Search Email Senders'}
                />
                <CustomSelect
                  dontCollapseOnDelete={true}
                  icon={<UilFolder />}
                  customClearAction={() => {
                    setPageTitle("")
                    setSearchFolder('');
                  }}
                  new={() => {
                    setShowCreateFolder(true);
                  }}
                  onDelete={(folderID) => {
                    const folder = folders?.find(folder => folder.id === folderID);
                    setSelectedFolderToDelete(folder);
                    setShowDeleteFolderConfirmationModal(true);
                  }}
                  onChange={(selectedFolder) => {
                    if (selectedFolder?.value === 'All Folders') {
                      setPageTitle("")
                      setSearchFolder('');
                      return 
                    }
                    setSearchFolder(selectedFolder.value);
                    setPageTitle(`Email Senders in Folder ${selectedFolder.label}`)
                  }}
                  default={{ label: 'Folders', value: 'All Folders' }}
                  options={folders?.map((folderObj) => ({
                    label: folderObj.name,
                    value: folderObj.id,
                  }))}
                  style={{ width: '100%', marginLeft: 'auto', marginTop: 15 }}
                />
                {showCreateFolder && (
                  <CustomDialog>
                    <CreateFolder
                      onClose={() => setShowCreateFolder(false)}
                      onCreate={() => {
                        fetchFolders();
                        setShowCreateFolder(false);
                      }}
                    />
                  </CustomDialog>
                )}
                {/* owned by */}
                {(authContext.user.permission === 'owner' || authContext.user.permission === 'admin' || authContext.user.permission === 'client') && users?.length>0 &&
                (
                  <Expander
                    style={{ width: '100%', padding: 2 }}
                    label="Owned By"
                    icon={<UilUsersAlt />}
                  >
                    {
                      <ReactSelect
                        placeholder="Select User"
                        defaultValue={'All'}
                        value={users?.find((userObj) => userObj.value === ownedBy) || null}
                        options={users}
                        onChange={(selected) => {
                          setPage(0);
                          setOwnedBy(selected?.value);
                        }}
                      />
                    }
                  </Expander>
                )}
                <CustomToggle
                  title="Has Error"
                  selected={statsFilter === "has-error"}
                  icon={<UilExclamationOctagon color={statsFilter === "has-error"?"#FFF":"#E53A40"} />}
                  onChange={(e) => {
                    if(statsFilter === "has-error") setStatsFilter('')
                    else {setPage(0); setStatsFilter('has-error')}
                  }}
                />
                <CustomToggle
                  title="Connecting"
                  selected={statsFilter === "connecting"}
                  icon={<UilRefresh color={statsFilter === "connecting" ? "#FFF":"#FBD355"} />}
                  onChange={(e) => {
                    if(statsFilter === "connecting") setStatsFilter('')
                    else {setPage(0); setStatsFilter('connecting')}
                  }}
                />
                <CustomToggle
                  title="Connected"
                  selected={statsFilter === "connected"}
                  icon={<UilCheckCircle color={statsFilter === "connected" ? "#FFF" :"#3AC569"} />}
                  onChange={(e) => {
                    if(statsFilter === "connected") setStatsFilter('')
                    else {setPage(0); setStatsFilter('connected')}
                  }}
                />
                <CustomToggle
                  title="Warmup Active"
                  selected={statsFilter === "warmup-active"}
                  icon={<UilFire color={statsFilter === "warmup-active" ? "#FFF":"#F0AA61"} />}
                  onChange={(e) => {
                    if(statsFilter === "warmup-active") setStatsFilter('')
                    else {setPage(0); setStatsFilter('warmup-active')}
                  }}
                />
                <CustomToggle
                  title="WarmUp Paused"
                  selected={statsFilter === "warmup-paused"}
                  icon={<UilFire color={statsFilter === "warmup-paused"?"#FFF":"#a0a0a0"} />}
                  onChange={(e) => {
                    if(statsFilter === "warmup-paused") setStatsFilter('')
                    else {setPage(0); setStatsFilter('warmup-paused')}
                  }}
                />
                <CustomToggle
                  title="No Custom Tracking Domain"
                  selected={statsFilter === "no-custom-domain"}
                  icon={<UilGlobe />}
                  onChange={(e) => {
                    if(statsFilter === "no-custom-domain") setStatsFilter('')
                    else {setPage(0); setStatsFilter('no-custom-domain')}
                  }}
                />
              </div>
            </Col>
            <Col id='email-sender-lists' xs={12} lg={9}>
              <div
                id='mobile-email-sender-filter'
                style={{
                  borderRadius: 0,
                  backgroundColor: '#f9f9f9',
                  marginTop: -2,
                  paddingBlock: 4,
                  paddingLeft: 2,
                  marginBottom: 8
                }}
              >
                <CustomButton
                  onClick={() => setFilterVisible(prev => !prev)}
                  title={filterVisible ? 'Hide Filters' : 'View Filters'}
                  variant="secondary"
                  icon={filterVisible ? <UilTimes /> : <UilFilter />}
                />
                <div style={{ display: filterVisible ? 'block' : 'none', marginTop: 8 }}>
                  <CustomSearch
                    value={search}
                    onChange={(e) => {setPage(0); setSearch(e);}}
                    placeholder={'Search Email Senders'}
                  />
                  <CustomSelect
                    dontCollapseOnDelete={true}
                    icon={<UilFolder />}
                    customClearAction={() => {
                      setPageTitle("")
                      setSearchFolder('');
                    }}
                    new={() => {
                      setShowCreateFolder(true);
                    }}
                    onDelete={(folderID) => {
                      const folder = folders?.find(folder => folder.id === folderID);
                      setSelectedFolderToDelete(folder);
                      setShowDeleteFolderConfirmationModal(true);
                    }}
                    onChange={(selectedFolder) => {
                      if (selectedFolder?.value === 'All Folders') {
                        setPageTitle("")
                        setSearchFolder('');
                        return 
                      }
                      setSearchFolder(selectedFolder.value);
                      setPageTitle(`Email Senders in Folder ${selectedFolder.label}`)
                    }}
                    default={{ label: 'Folders', value: 'All Folders' }}
                    options={folders?.map((folderObj) => ({
                      label: folderObj.name,
                      value: folderObj.id,
                    }))}
                    style={{ width: '100%', marginLeft: 'auto', marginTop: 15 }}
                  />
                  {showCreateFolder && (
                    <CustomDialog>
                      <CreateFolder
                        onClose={() => setShowCreateFolder(false)}
                        onCreate={() => {
                          fetchFolders();
                          setShowCreateFolder(false);
                        }}
                      />
                    </CustomDialog>
                  )}
                  {moveToFolder && (
                    <CustomDialog>
                      <MoveToFolder
                        sequenceID={selectAll?.length > 0 ? selectAll : [moveToFolder]}
                        folders={folders}
                        onClose={() => setMoveToFolder(false)}
                        onSuccess={() => {
                          setMoveToFolder(false);
                          reloadWindow();
                        }}
                      />
                    </CustomDialog>
                  )}
                  {/* owned by */}
                  {(authContext.user.permission === 'owner' || authContext.user.permission === 'admin' || authContext.user.permission === 'client') && users?.length>0 &&
                  (
                    <Expander
                      style={{ width: '100%', marginTop: 15, padding: 2 }}
                      label="Owned By"
                      icon={<UilUsersAlt />}
                    >
                      {
                        <ReactSelect
                          placeholder="Select User"
                          defaultValue={'All'}
                          value={users?.find((userObj) => userObj.value === ownedBy) || null}
                          options={users}
                          onChange={(selected) => {
                            setPage(0);
                            setOwnedBy(selected?.value);
                          }}
                        />
                      }
                    </Expander>
                  )}
                  <CustomToggle
                    title="Has Error"
                    selected={statsFilter === "has-error"}
                    icon={<UilExclamationOctagon color={statsFilter === "has-error"?"#FFF":"#E53A40"} />}
                    onChange={(e) => {
                      if(statsFilter === "has-error") setStatsFilter('')
                      else {setPage(0); setStatsFilter('has-error')}
                    }}
                  />
                  <CustomToggle
                    title="Connecting"
                    selected={statsFilter === "connecting"}
                    icon={<UilRefresh color={statsFilter === "connecting" ? "#FFF":"#FBD355"} />}
                    onChange={(e) => {
                      if(statsFilter === "connecting") setStatsFilter('')
                      else {setPage(0); setStatsFilter('connecting')}
                    }}
                  />
                  <CustomToggle
                    title="Connected"
                    selected={statsFilter === "connected"}
                    icon={<UilCheckCircle color={statsFilter === "connected" ? "#FFF" :"#3AC569"} />}
                    onChange={(e) => {
                      if(statsFilter === "connected") setStatsFilter('')
                      else {setPage(0); setStatsFilter('connected')}
                    }}
                  />
                  <CustomToggle
                    title="Warmup Active"
                    selected={statsFilter === "warmup-active"}
                    icon={<UilFire color={statsFilter === "warmup-active" ? "#FFF":"#F0AA61"} />}
                    onChange={(e) => {
                      if(statsFilter === "warmup-active") setStatsFilter('')
                      else {setPage(0); setStatsFilter('warmup-active')}
                    }}
                  />
                  <CustomToggle
                    title="WarmUp Paused"
                    selected={statsFilter === "warmup-paused"}
                    icon={<UilFire color={statsFilter === "warmup-paused"?"#FFF":"#a0a0a0"} />}
                    onChange={(e) => {
                      if(statsFilter === "warmup-paused") setStatsFilter('')
                      else {setPage(0); setStatsFilter('warmup-paused')}
                    }}
                  />
                  <CustomToggle
                    title="No Custom Tracking Domain"
                    selected={statsFilter === "no-custom-domain"}
                    icon={<UilGlobe />}
                    onChange={(e) => {
                      if(statsFilter === "no-custom-domain") setStatsFilter('')
                      else {setPage(0); setStatsFilter('no-custom-domain')}
                    }}
                  />
                </div>
              </div>
            {!isloading ? (
               senders?.length === 0 ? pageTitle ? <div className='flex gap-8 items-center mb-4'>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: '3px solid #A0A0A0',
                    height: 40,
                    width: 50,
                    borderRadius: 5,
                  }}
                  className="mr-5 text-white"
                  role="button"
                  id="show-all-sequences"
                  onClick={() => {
                    setPageTitle("")
                    setSearchFolder('');
                  }}
                >
                  <UilArrowLeft size={25} color="#A0A0A0" />
                </button>
                <PageTitle
                  title={pageTitle}
                  description="Manage your email senders in this folder."
                />
              </div> : 
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 'auto',
                      textAlign: 'center',
                      marginTop: 50,
                      marginBottom: 50,
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto', marginBottom: 10 }} viewBox="0 0 100 100">
                      <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
                    </svg>
                    No email senders found.
                    <div style={{ margin: 'auto', marginTop: '0 !important' }}>
                      <CustomButton
                        onClick={() => {
                          setStep('sender');
                          // fetchSenders('');
                          setShowSetupWizard(true);
                        }}
                        title="Connect Email Sender"
                        variant="primary"
                      />
                    </div>
                  </div>
                </div>
              :
                <>
                  {pageTitle && <div className='flex gap-8 items-center mb-4'>
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: '3px solid #A0A0A0',
                        height: 40,
                        width: 50,
                        borderRadius: 5,
                      }}
                      className="mr-5 text-white"
                      role="button"
                      id="show-all-sequences"
                      onClick={() => {
                        setPageTitle("")
                        setSearchFolder('');
                      }}
                    >
                      <UilArrowLeft size={25} color="#A0A0A0" />
                    </button>
                    <PageTitle
                      title={pageTitle}
                      description="Manage your email senders in this folder."
                    />
                  </div>}
                  <CustomTable
                    hasFooter={false}
                    columns={columns}
                    data={senders}
                    count={senderCount}
                    style={{ width: '100%' }}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </>
              )
              : <div
              className="csFormLoader"
              style={{ background: `rgba(255, 255, 255, 0.5)`}}
              >
                <Loader />
              </div> }
            </Col>
          </Row>
          {senderAddDialogVisible && (
            <CustomDialog title="Add Email Sender" scroll={true}>
              <AddEditSenderForm
                close={() => {
                  setAddSenderDialogVisible(false);
                  setAddSenderDialogType('');
                }}
                enableWarmup={
                  !planUsage?.warmUpCount ||
                  (planUsage?.warmUpCount?.limit > 0 &&
                    planUsage?.warmUpCount?.limit >
                      planUsage?.warmUpCount?.used)
                }
                option={senderAddDialogType}
              />
            </CustomDialog>
          )}
        </Animate>
      )}

      {viewWarmup && (
        <WarmUpView
          sender={viewWarmup}
          userName={viewWarmupSender}
          fetchTemplates={fetchTemplates}
          fetchLinks={fetchLinks}
          warmuplinks={warmuplinks}
          loadingLinks={loadingLinks}
          templates={templates}
          setSenders={setSenders}
          close={() => {
            setViewWarmup(null);
            setViewWarmupSender(null);
          }}
        />
      )}
      {
        showDetails && (
          <CustomModal
            close={true}
            onClose={()=>{
              setSelectedSender(null)
              setShowDetails(false)}}
            title={`Details for ${selectedSender?.alias || selectedSender?.microsoft_email || selectedSender?.google_email || selectedSender?.smtpImapEmail || ""}`}
            style={{padding:24}}
            width={'700px'}
          >
            <SenderDetails data={senderDetails} loading={loadingLinks}/>
          </CustomModal>
        )
      }
    </Fragment>
  );
}

function WarmUpView({
  sender,
  userName,
  close,
  templates,
  warmuplinks,
  fetchTemplates,
  fetchLinks,
  loadingLinks,
  setSenders
}) {
  const [sent, setSent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [received, setReceived] = useState(0);
  const [replies, setReplies] = useState(0);
  const [spam, setSpam] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [template, setTemplate] = useState([]);
  const [emailFrequency, setEmailFrequency] = useState(5);
  const [warmupUrls, setWarmupUrls] = useState([]);
  const [logs, setLogs]=useState(null)
  const [remdays, setRemdays] = useState(15);
  const [autoRampUpEnabled, setAutoRampUpEnabled] = useState(false);
  const [rampUpFrequency, setRampUpFrequency] = useState(3);
  const [maxDailyFrequency, setMaxDailyFrequency] = useState(100);
  const [domainAge, setDomainAge] = useState(1);
  const [openRate, setOpenRate] = useState(60);
  const [spamProtection, setSpamProtection] = useState(80);
  const [readEmulation, setReadEmulation] = useState(80);

  useEffect(() => {
    if (sender) {
      getList();
      getStats();
    }
  }, [sender]);

  const getList = async () => {
    try {
      let res = await axios.get(`/api/warmuplog/${sender}/list?full=true`);
      setDomainAge(res.data.domainAge)
      setLogs(res.data.data);
      if(res.data?.data?.length === 0) setLoading(false)
    } catch (err) {}
  };

  useEffect(() => {
    if (logs) getRemainingdays()
  }, [logs]);

  function getRemainingdays() {
    if (!logs || logs?.length === 0) {
      setRemdays(21); // Default warm-up days for older domains
      setLoading(false);
      return;
    }

    let warmUpDays = domainAge < 15 ? 30 : 21;

    if(domainAge < 15) {
      setRemdays(30)
      Swal.fire({
        icon: 'info',
        title: 'WarmUp is taking a while since domain is new.',
        showCancelButton: false,
        confirmButtonColor: '#0066FF'
      })
      setLoading(false);
      return
    }

    let prevDate = DateTime.fromMillis(logs[0].timestamp).startOf('day');
    const todayDate = DateTime.now().startOf('day');
    const firstDifference = todayDate.diff(prevDate, 'days').days;
    let pureDaysCounter = 0;

    if (firstDifference > 1) {
      setLoading(false);
      return;
    }

    if (prevDate.equals(todayDate)) prevDate = prevDate.minus({ days: 1 });

    // Calculate Spam to Inbox ratio in the last 7 days
    const last7DayLogs = logs.filter(log => DateTime.fromMillis(log.timestamp).diff(todayDate, 'days').days >= -7);
    const spamCountInLast7Days = last7DayLogs.filter(log => log.spam_to_inbox).length;
    const spamRatioInLast7Days = (spamCountInLast7Days / last7DayLogs.length) * 100;

    // Add days based on Spam to Inbox ratio
    const additionalDays = Math.floor(spamRatioInLast7Days / 4);

    // Adjust the warm-up period based on spam ratio
    warmUpDays += additionalDays;

    for (let i = 0; i < logs?.length; i++) {
      if (pureDaysCounter === warmUpDays) break;
      
      const date = DateTime.fromMillis(logs[i].timestamp).startOf('day');

      if (date.equals(todayDate)) continue;
      
      if (prevDate) {
        const differenceInDays = Math.round(prevDate.diff(date, 'days').days);
        if (differenceInDays === 0) prevDate = date;
        else if (differenceInDays === 1) {
          pureDaysCounter++;
          prevDate = date;
        } else if (differenceInDays > 1) {
          pureDaysCounter++;
          break;
        }
      }
    }

    setRemdays(warmUpDays - pureDaysCounter);
    setLoading(false);
    return;
  }

  const getStats = async () => {
    try {
      let res = await axios.get(`/api/warmuplog/${sender}/stats`);
      setSent(res.data.sent);
      setReceived(res.data.recieved);
      setReplies(res.data.replied);
      setSpam(res.data.spam);
      if (res.data.sender) {
        if (res.data.sender.warmupTemplates)
          setTemplate(res.data.sender.warmupTemplates);
        if (res.data.sender.warmupURLs)
          setWarmupUrls(res.data.sender.warmupURLs);
        if (res.data.sender.emailFrequency)
          setEmailFrequency(res.data.sender.emailFrequency);
        if (res.data.sender.autoRampUpEnabled) {
          setAutoRampUpEnabled(res.data.sender.autoRampUpEnabled)
          setRampUpFrequency(res.data.sender.rampUpFrequency)
          setMaxDailyFrequency(res.data.sender.maxDailyFrequency) 
        } else setAutoRampUpEnabled(false);
        if (res.data.sender.open_rate) setOpenRate(res.data.sender.open_rate)
        if (res.data.sender.spam_protection) setSpamProtection(res.data.sender.spam_protection)
        if (res.data.sender.read_emulation) setReadEmulation(res.data.sender.read_emulation)
      }
    } catch (err) {}
  };

  const deactivateWarmUp = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure',
      html: `<h5>Your Email Deliverability might be negatively impacted.</h5>`,
      showCancelButton: true,
      confirmButtonText: `Disable WarmUp`,
      confirmButtonColor: '#E53A40',
      confirmButtonHtml: <Button text="Disable WarmUp" variant="primary red" />,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await axios.put(`/api/senders/${sender}/save`, {
            warmupEnabled: false,
            updateWarmup: true
          });
          if (res?.data?.success) {
            close();
            setSenders((oldSenders) => {
              let newSenders = oldSenders.map((item) => {
                if (item.email === userName)
                  return { ...item, warmupEnabled: false };
                return item;
              });
              return newSenders;
            });
          }
        } catch (err) {
          Swal.fire({
            icon: 'error',
            title: `Oops! Could Not Deactivate Sender WarmUp`,
            showCancelButton: false,
            confirmButtonText: `Ok`,
            confirmButtonColor: '#0066ff',
          });
        }
      }
    });
  };

  const setUpdatedValues = (
    emailFreq, 
    rampUpEnabled, 
    rampUpFreq, 
    maxDailyFreq,  
    open_rate,
    spam_protection,
    read_emulation,
    templates,
    warmuplinks, 
  ) => {
    setEmailFrequency(emailFreq);
    setAutoRampUpEnabled(rampUpEnabled);
    setRampUpFrequency(rampUpFreq);
    setMaxDailyFrequency(maxDailyFreq);
    setOpenRate(open_rate);
    setSpamProtection(spam_protection);
    setReadEmulation(read_emulation);
    setTemplate(templates);
    setWarmupUrls(warmuplinks);
  }

  if(loading) return <Loader/>

  return (
    <Animate>
      {showModal && <WarmUpSettingsModal 
        setShowModal={setShowModal}
        emailFreq={emailFrequency}
        rampUpEnabled={autoRampUpEnabled}
        rampUpFreq={rampUpFrequency}
        maxDailyFreq={maxDailyFrequency}
        selectedTemplate={template}
        selectedWarmupUrls={warmupUrls}
        templates={templates}
        warmuplinks={warmuplinks}
        fetchTemplates={fetchTemplates}
        fetchLinks={fetchLinks}
        loadingLinks={loadingLinks}
        selectedSender={sender}
        getStats={getStats}
        open_rate={openRate}
        spam_protection={spamProtection}
        read_emulation={readEmulation}
        setUpdatedValues={setUpdatedValues}
      />}

      <div id='warmup-sender-header' style={{ display: 'flex' }}>
        <button
          onClick={() => close()}
          className="csBadge"
          id="back-to-sender"
          style={{ border: '3px solid #A0A0A0', padding: 10 }}
        >
          <UilArrowLeft size={26} color="#A0A0A0" />
        </button>
        <Tooltip anchorSelect="#back-to-sender" place="top">
          Back to Senders
        </Tooltip>
        <div id='warmup-sender-title'>
          <PageTitle
            title={'Email Warmup Report'}
            description={'for ' + userName}
          />
        </div>
        {remdays && remdays > 0 ?
          <div id='rem-days' className=" h-full dayCardtrue  items-stretch px-2">
            <div className="csBadge flex items-center justify-center" >
              <UilEnvelopeUpload size={30} color={`${remdays > 0 ? '#FBD355':'#3AC569'}`} />
            </div>
            <div className=" flex items-center justify-center py-2 mr-2">
              {loading ? 
                <div className="statTitle">...Loading Warmup Status</div>
                :
                <div className="statTitle">Will be ready for outreach in {remdays} days</div>
              }
            </div>
          </div>:
          <div className=" h-full dayCardfalse  items-stretch px-2">
            <div className="csBadge flex items-center justify-center" >
              <UilEnvelopeUpload size={30} color={`${remdays > 0 ? '#FBD355':'#3AC569'}`} />
            </div>
            <div className=" flex items-center justify-center py-2 mr-2">
              <div className="statTitle">Ready for Outreach
              </div>
            </div>
          </div>
        }
        <div id='warmup-settings-container'>
          <button
            onClick={() => setShowModal(true)}
            className="csBadge"
            id="warmup-settings"
            style={{
              border: '3px solid #F9C00C',
              padding: 10,
            }}
          >
            <UilCog size={26} color="#F9C00C" />
          </button>
          <Tooltip anchorSelect="#warmup-settings" place="top">
            WarmUp Settings
          </Tooltip>
          <button
            onClick={() => deactivateWarmUp()}
            className="csBadge"
            style={{
              marginLeft: 10,
              border: '3px solid #E53A40',
              color: '#E53A40',
              padding: 10,
            }}
          >
            Disable WarmUp
          </button>
        </div>
      </div>

      <Row style={{ marginTop: 35 }}>
        <Col xs={12} lg={8}>
          <h1 style={{ fontSize: 20, fontWeight: 500, marginBottom: 10 }}>
            Last 7 Days at a Glance
          </h1>
          <Row className="mt-4">
            <Col>
              <div className="statCard">
                <div className="csBadge success" style={{ padding: 15 }}>
                  <UilEnvelopeUpload size={35} color="#3AC569" />
                </div>
                <div className="ml-4">
                  <div className="statCount" id="email-sent">{sent}</div>
                  <div className="statTitle">Emails Sent</div>
                </div>
              </div>
              <Tooltip anchorSelect={`#email-sent`} style={{ zIndex: 99999 }}>
                Number of emails sent from your email sender for email WarmUp this week.
              </Tooltip>
            </Col>
            <Col>
              <div className="statCard">
                <div className="csBadge primary" style={{ padding: 15 }}>
                  <UilEnvelopeDownload size={35} color="#03A6FF" />
                </div>
                <div className="ml-4">
                  <div className="statCount" id="email-recieved">{received}</div>
                  <div className="statTitle">Emails Received</div>
                </div>
              </div>
              <Tooltip anchorSelect={`#email-recieved`} style={{ zIndex: 99999 }}>
                Number of emails received from other email addresses in WarmUp pool. 
              </Tooltip>
            </Col>
            <Col>
              <div className="statCard">
                <div className="csBadge warning" style={{ padding: 15 }}>
                  <UilCornerUpLeftAlt size={35} color="#F9C00C" />
                </div>
                <div className="ml-3">
                  <div className="statCount" id="replies-recieved">{replies}</div>
                  <div className="statTitle">Replies Received</div>
                </div>
              </div>
              <Tooltip anchorSelect={`#replies-recieved`} style={{ zIndex: 99999 }}>
                Number of replies received to the emails that are sent from your email sender.
              </Tooltip>
            </Col>
            <Col>
              <div className="statCard">
                <div className="csBadge danger" style={{ padding: 15 }}>
                  <UilExclamationOctagon size={35} color="#E53A40" />
                </div>
                <div className="ml-4">
                  <div className="statCount" id="spam-to-inbox">{spam}</div>
                  <div className="statTitle">Spam to Inbox</div>
                </div>
              </div>
              <Tooltip anchorSelect={`#spam-to-inbox`} style={{ zIndex: 99999 }}>
                Number of emails that landed in Spam & were moved to Inbox.
              </Tooltip>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col>
              <WarmUpChart sender={sender} />
            </Col>
          </Row>
        </Col>
        <Col id='recent-warmup-logs' xs={12} lg={4}>
          <div className='flex gap-2 items-center'>
            <h1 style={{ fontSize: 20, fontWeight: 500, marginBottom: 10 }}>
              Recent WarmUp Logs
            </h1>
            <UilQuestionCircle id='warmup-logs-text' style={{ marginBottom: 8 }} />
          </div>
          <Tooltip anchorSelect='#warmup-logs-text' place='top'>WarmUp logs cleared every 30 days.</Tooltip>
          <WarmUpLogs sender={sender} userName={userName} />
        </Col>
      </Row>
    </Animate>
  );
}

function WarmUpLogs({ sender,userName }) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (sender) getList();
  }, [sender]);

  const getList = async () => {
    try {
      let res = await axios.get(`/api/warmuplog/${sender}/list?limit=17`);
      const filteredData = res.data.data.filter(log => {
        const { from, to, is_reply, spam_to_inbox, sender: logSender } = log;
        return (
          (from === userName && spam_to_inbox) ||
          (logSender !== sender && is_reply) ||
          (logSender !== sender && !(is_reply || spam_to_inbox)) ||
          (logSender === sender && !(is_reply || spam_to_inbox))
        );
      });
      setLogs(filteredData);
      setLoading(false);
    } catch (err) {}
  };

  const columns = [
    {
      Header: 'Email Address',
      Footer: 'Email Address',
      accessor: 'from',
      Cell: ({ row, value }) => {
        const { original } = row;
        if (original.from === userName) return original?.to;
        return value;
      },
    },
    {
      Header: 'Actions',
      Footer: 'Actions',
      accessor: 'message',
      Cell: ({ row, value }) => {
        const { cells } = row;
        let warmupData = cells[1].row.original;

        if (warmupData.from !== warmupData.to && warmupData.spam_to_inbox)
          return <span className="csBadge danger csCenter">Spam to Inbox</span>;
        else if (warmupData.sender !== sender && warmupData.is_reply)
          return (
            <span className="csBadge warning csCenter">Reply Received</span>
          );
        else if (warmupData.sender !== sender )
          return (
            <span className="csBadge primary csCenter">Email Received</span>
          ); 
        else if (warmupData.sender === sender && !(warmupData.is_reply || warmupData.spam_to_inbox ) )
          return <span className="csBadge success csCenter">Email Sent</span>;
      },
    },
    {
      Header: 'When',
      Footer: 'When',
      accessor: 'timestamp',
      Cell: ({ row, value }) => {
        const date = DateTime.fromMillis(value);

        const day = date.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';
        return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
      },
    },
  ];

  if (loading) 
    return (
    <div
      className="csFormLoader"
      style={{ background: `rgba(255, 255, 255, 0.5)`}}
    >
      <Loader />
    </div>
    );

  if (logs?.length === 0)
    return (
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
            <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
          </svg> 
          <span>No logs found</span>
        </div>
      </div>
    );

  return (
    <CustomTable
      hasFooter={false}
      columns={columns}
      data={logs}
      onClick={(row) => {}}
      pagination={false}
    />
  );
}


function SenderDetails({ data, loading }){
  const activeSequences = data?.activeSequences?.map((sequence, index)=>{
    return (
      <>
        <a href={`/outreach/sequences/${sequence.id}/stats`} target='_blank' className="csCustomeLink inline-flex">{sequence.name}
          <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
          {/* <span className="csLinkEmpty inline-block"></span> */}
        </a>
        {index !== data?.activeSequences?.length - 1 && <span> , </span>}
      </>
    )
  })

  const pausedSequences = data?.pausedSequences?.map((sequence,index)=>{
    return (
      <>
        <a href={`/outreach/sequences/${sequence.id}/stats`} target='_blank' className="csCustomeLink inline-flex">{sequence.name}
          <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
          {/* <span className="csLinkEmpty"></span> */}
        </a>
        {index !== data?.pausedSequences?.length-1 && <span> , </span>}
      </>
    )
  })

  const showFormatedDate = (value) => {
    if(!value) return ;
    const date = DateTime.fromISO(value);

    const day = date.day;
    const suffix =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th';

    return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
  }

  const formatServiceType=(serviceName)=>{
    if(serviceName === "smtpimap")return "SMTP/IMAP"
    else if(serviceName === "smtponly") return "SMTP Only"
    return serviceName
  }

  return (
    <>
      {loading && (
        <div className="csFormLoader">
          <Loader />
        </div>
      )}
      {!loading && (<>
        <div id='hide-in-mobile'>
          <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}} >
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #03A6FF',backgroundColor:'#DCEBF9',color:'#03A6FF'}}>
                <UilEnvelope width={30} height={30}/>
              </span>
              <p className='text-[18px] font-medium'>Sender Email Address:</p></div>
            <div className="values" style={{width:'60%'}}><p>
              {data.emailAddress} <span className='capitalize'>({formatServiceType(data.emailType)})</span></p></div>
          </div>
          
          {activeSequences?.length > 0 && <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}}>
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #8929E0',backgroundColor:'#E3CCF8',color:'#8929E0'}}>
                <UilMessage width={30} height={30} />
              </span>
              <p className='text-[18px] font-medium'>Active Sequences:</p></div>
            <div className="values" style={{width:'60%'}}>{activeSequences}</div>
          </div>}
          
          {pausedSequences?.length > 0 && <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}}>
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #E8B105',backgroundColor:'#F3E8C4',color:'#E8B105'}}>
                <UilPause width={30} height={30} />
              </span>
              <p className='text-[18px] font-medium'>Paused Sequences:</p></div>
            <div className="values" style={{width:'60%'}}><p>{pausedSequences}</p></div>
          </div>}

          <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}}>
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #16B4A1',backgroundColor:'#C7EFEA',color:'#16B4A1'}}>
                <UilHistory width={30} height={30} />
              </span>
              <p className='text-[18px] font-medium'>Emails sent Today:</p></div>
            <div className="values" style={{width:'60%'}}><p>{data.emailCount}</p></div>
          </div>

          <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}}>
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #ED317F',backgroundColor:'#FBD6E5',color:'#ED317F'}}>
                <UilEnvelopeCheck width={30} height={30} />
              </span>
              <p className='text-[18px] font-medium'>Max daily sending limit:</p></div>
            <div className="values" style={{width:'60%'}}><p>{data.dailyLimit}</p></div>
          </div>

          <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}}>
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #FBD355',backgroundColor:'#F3E8C4',color:'#F9C00C'}}>
                <UilClockEight width={30} height={30} />
              </span>
              <p className='text-[18px] font-medium'>Added On:</p></div>
            <div className="values" style={{width:'60%'}}><p>{showFormatedDate(data.createdAt)}</p></div>
          </div>

          {data?.folderName && <div className='flex items-center' style={{margin:"20px 0"}}>
            <div className="details flex items-center " style={{width:'40%',gap:'15px'}} >
              <span className="" style={{padding:5,borderRadius:5,border:'1px solid #03A6FF',backgroundColor:'#DCEBF9',color:'#03A6FF'}}>
                <UilFolder width={30} height={30}/>
              </span>
              <p className='text-[18px] font-medium'>Folder:</p></div>
            <div className="values" style={{width:'60%'}}>{data.folderName}</div>
          </div>}
        </div>
        <div id='show-in-mobile' className='flex flex-col gap-4'>
          <div className='flex items-center gap-4'>
            <div style={{ padding:5, borderRadius:5, border:'1px solid #03A6FF', backgroundColor:'#DCEBF9', color:'#03A6FF' }}><UilEnvelope width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Sender Email Address:</strong> <div>{data.emailAddress} <span className='capitalize'>({formatServiceType(data.emailType)})</span></div></div>
          </div>
          {activeSequences?.length > 0 &&  <div className='flex items-center gap-4'>
            <div style={{ padding:5, borderRadius:5, border:'1px solid #8929E0', backgroundColor:'#E3CCF8', color:'#8929E0' }}><UilMessage width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Active Sequences:</strong> <div>{activeSequences}</div></div>
          </div>}
          {pausedSequences?.length > 0 && <div className='flex items-center gap-4'>
            <div style={{ padding:5, borderRadius:5, border:'1px solid #E8B105', backgroundColor:'#F3E8C4', color:'#E8B105' }}><UilPause width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Paused Sequences:</strong> <div>{pausedSequences}</div></div>
          </div>}
          <div className='flex items-center gap-4'>
            <div style={{ padding:5, borderRadius:5, border:'1px solid #16B4A1', backgroundColor:'#C7EFEA', color:'#16B4A1'}}><UilHistory width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Emails sent Today:</strong> <div>{data.emailCount}</div></div>
          </div>
          <div className='flex items-center gap-4'>
            <div style={{padding:5, borderRadius:5, border:'1px solid #ED317F', backgroundColor:'#FBD6E5', color:'#ED317F'}}><UilEnvelopeCheck width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Max daily sending limit:</strong> <div>{data.dailyLimit}</div></div>
          </div>
          <div className='flex items-center gap-4'>
            <div style={{padding:5, borderRadius:5, border:'1px solid #FBD355', backgroundColor:'#F3E8C4', color:'#F9C00C'}}><UilClockEight width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Added On:</strong> <div>{showFormatedDate(data.createdAt)}</div></div>
          </div>
          {data?.folderName && <div className='flex items-center gap-4'>
            <div style={{padding:5, borderRadius:5, border:'1px solid #03A6FF', backgroundColor:'#DCEBF9', color:'#03A6FF'}}><UilFolder width={30} height={30} /></div>
            <div className='flex flex-col'><strong>Folder:</strong> <div>{data.folderName}</div></div>
          </div>}
        </div>
      </>)}
    </>
  );
}