import { useCallback } from 'react';
import ReactFlow, { Controls, MiniMap, applyNodeChanges } from 'reactflow';
import ButtonEdge from './ButtonEdge.js';
import { useFlowchartData } from './FlowchartContext';
import nodeTypes from './nodeTypes';

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const Flowchart = (props) => {
  const flowchartData = useFlowchartData();
  const onConnect = (params) => {};

  const onNodesChange = useCallback((elementsToRemove) => {
    flowchartData.setNodes((els) => applyNodeChanges(elementsToRemove, els));
    flowchartData.alignSources();
  }, []);
  const onEdgesChange = useCallback(
    (elementsToRemove) =>
      flowchartData.setEdges((els) => applyNodeChanges(elementsToRemove, els)),
    []
  );

  return (
    <ReactFlow
      nodes={flowchartData.nodes}
      edges={flowchartData.edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      zoomOnScroll={false}
      panOnScroll={true}
      panOnDrag={true}
      fitview={true}
      attributionPosition="bottom-left"
    >
      <MiniMap />
      <Controls />
    </ReactFlow>
  );
};

export default Flowchart;
