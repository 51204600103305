/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React from 'react';
import SignUp from './signup';
import "../auth.scss";

export function Signup(props) {
  return <SignUp />;
}
