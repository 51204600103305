import Axios from 'axios';
import { AddTaskTemplate, CustomSubNav } from 'components/lib';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilListUl
} from '@iconscout/react-unicons';
import './tasktemplates.scss';

export default function TaskTemplates() {
  const [data, setData] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  const { taskID, templateID } = useParams();
  const navigator = useNavigate();

  const getTaskType = async () => {
    try {
      const res = await Axios({
        url: '/api/task-type/' + taskID,
        method: 'get',
      });
      setData(res.data.data);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  const getTaskTemplate = async () => {
    try {
      const res = await Axios({
        url: '/api/template/' + templateID,
        method: 'get',
      });
      setTemplateData(res.data.data);
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  useEffect(() => {
    if (taskID) getTaskType();
    if (templateID) getTaskTemplate();
  }, []);

  return (
    <>
      <CustomSubNav
        selected={'tasks'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />
      <div id='task-templates'>
        <AddTaskTemplate
          data={data}
          templateData={templateData}
          onComplete={() => navigator(`/outreach/templates`)}
          onClose={() => navigator(`/task-types`)}
        />
      </div>
    </>
  );
}
