import React from 'react';
import CustomButton from 'components/custom/customButton';
import { useNavigate } from 'components/lib';
import emptyFolderImg from '../../assets/balloon.png';
import './index.scss';

function UpgradePlan({ title }) {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            marginTop: 50,
            marginBottom: 20,
          }}
        >
          <img
            alt="empty folder"
            style={{ margin: 'auto', height: 200 }}
            src={emptyFolderImg}
          />
          <span style={{ margin: 'auto', marginTop: 20 }}>
            {title ||
              'You do not have access to this feature, upgrade plan now!'}
          </span>
        </div>
      </div>
      <div>
        <CustomButton
          className="csMarginAuto"
          title="Update Plan"
          variant="primary"
          onClick={(e) => navigate('/account/billing')}
        />
      </div>
    </>
  );
}

export { UpgradePlan };
