const tracking_app_urls = [
	'a.sblnk.com',
    'b.sblnk.com',
    'c.sblnk.com',
    'd.sblnk.com',
    'e.sblnk.com',
    'f.sblnk.com',
    'g.sblnk.com',
    'h.sblnk.com',
    'i.sblnk.com',
    'j.sblnk.com',
    'k.sblnk.com',
    'l.sblnk.com',
    'm.sblnk.com',
    'n.sblnk.com',
    'o.sblnk.com',
    'p.sblnk.com',
    'q.sblnk.com',
    'r.sblnk.com',
    's.sblnk.com',
    't.sblnk.com',
    'u.sblnk.com',
    'v.sblnk.com',
    'w.sblnk.com',
    'x.sblnk.com',
    'y.sblnk.com',
    'z.sblnk.com',
    '1.sblnk.com',
    '2.sblnk.com',
    '3.sblnk.com',
    '4.sblnk.com',
    '5.sblnk.com',
    '6.sblnk.com',
    '7.sblnk.com',
    '8.sblnk.com',
    '9.sblnk.com',
    '10.sblnk.com',
    '11.sblnk.com',
    '12.sblnk.com',
    '13.sblnk.com',
    '14.sblnk.com',
    '15.sblnk.com',
    '16.sblnk.com',
    '17.sblnk.com',
    '18.sblnk.com',
    '19.sblnk.com',
    '20.sblnk.com',
    '21.sblnk.com',
    '22.sblnk.com',
    '23.sblnk.com',
    '24.sblnk.com',
    '25.sblnk.com',
    '26.sblnk.com',
    '27.sblnk.com',
    '28.sblnk.com',
    '29.sblnk.com',
    '30.sblnk.com',
    '31.sblnk.com',
    '32.sblnk.com',
    '33.sblnk.com',
    '34.sblnk.com',
    '35.sblnk.com',
    '36.sblnk.com',
    '37.sblnk.com',
    '38.sblnk.com',
    '39.sblnk.com',
    '40.sblnk.com',
    '41.sblnk.com',
    '42.sblnk.com',
    '43.sblnk.com',
    '44.sblnk.com',
    '45.sblnk.com',
    '46.sblnk.com',
    '47.sblnk.com',
    '48.sblnk.com',
    '49.sblnk.com',
    '50.sblnk.com'
];

export default tracking_app_urls;