import React, { useState, useEffect } from 'react';
import './steps.scss';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

export default function Steps({ steps, current }) {
  const [status, setStatus] = useState(null);
  const deviceWidth = useWindowResize();

  useEffect(() => {
    let _status = {
      total: steps.length,
    };

    steps.map(({ step }, index) => {
      if (step === current) {
        _status.current = index;
      }
    });
    return setStatus(_status);
  }, [current]);

  function getActiveWidth() {
    return status?.current * (deviceWidth > 991 ? 550 : 225) / (steps.length - 1);
  }

  return (
    <div className="csSteps">
      <div
        className="csStepsProgress"
        style={{ width: `${getActiveWidth()}px` }}
      ></div>
      {status &&
        steps.map(({ step, image, accent }, index) => {
          return (
            <div key={index} className="csStep">
              <div className="csStepActiveBar"></div>
              <div
                className="csStepDot"
                style={
                  index <= status.current
                    ? {
                        '--accent-color': accent.color,
                        '--accent-background': accent.background,
                        '--accent-outline': accent.outline,
                      }
                    : {}
                }
              >
                {image}
              </div>
              <div className="csStepName">{step}</div>
            </div>
          );
        })}
    </div>
  );
}
