/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form, Link } from 'components/lib';

export function SignupUser(props) {
  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf('?id=') + 4, url.indexOf('&email'));
  const email = url.substring(url.indexOf('&email') + 7);

  return (
    <Animate type="pop">
      <Row title="Create Your Account">
        <Card restrictWidth center>
          <Form
            data={{
              name: {
                label: 'Full Name',
                value: '',
                type: 'text',
                placeholder: 'Enter your name',
                required: true,
                errorMessage: 'Please enter your first name',
                maxLength: 150,
              },
              email: {
                label: 'Email',
                value: email,
                type: 'email',
                placeholder: 'Enter email',
                required: true,
                maxLength: 150,
              },
              // phone: {
              //   label: 'Phone Number',
              //   type: 'phone',
              //   placeholder: 'Enter phone number',
              //   required: true,
              //   maxLength: 20,
              // },
              password: {
                label: 'Password',
                type: 'password',
                placeholder: 'Enter a password',
                required: true,
                complexPassword: true,
                maxLength: 50,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              invite_id: {
                type: 'hidden',
                value: id,
              },
            }}
            url="/api/user"
            method="POST"
            redirect="/dashboard"
            buttonText="Create Account"
            callback={context.signin}
          />

          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
