import React, { useContext, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { PageTitle, WhitelabelContext } from 'components/lib';
import { UilAngleLeft, UilAngleRight,UilPlusCircle  } from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import moment from 'moment-timezone';
import './calendar.scss'

function Calendar(props) {
  const calendarRef = useRef(null);

  useEffect(() => {
    // Apply alternating background colors to day grid cells
    const dayCells = document.querySelectorAll('.fc-daygrid-day');
    dayCells.forEach((cell, index) => {
      if (index % 2 !== 1) {
        cell.style.backgroundColor = '#f2f2f2';
      }
    });
  }, []);

  let events = [];
  if(props?.events?.length > 0 && props?.timeZone) {
    events = props.events.map((event) => ({
      ...event,
      start: moment.tz(event.start, props.timeZone).format('YYYY-MM-DDTHH:mm:ss'),
      scheduled_time: moment.tz(event.scheduled_time, props.timeZone).format('YYYY-MM-DDTHH:mm:ss')
    }));
  }

  const { whitelabelDetails } = useContext(WhitelabelContext);

  return (
    <>
      <div className="csFullCalendar">
        <PageTitle
          title={props.title || 'My Calendar'}
          description={
            props.description ||
            `Calendar with meetings booked via ${whitelabelDetails?.agencyName} Meeting Scheduler.`
          }
          headerStyle={{ paddingTop: 15 }}
        />
        <div className="csFullCalendarActionBtns">
          <div className="csFullCalendarSelectedMonth">
            {calendarRef?.current
              ?.getApi()
              .getDate()
              .toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
              })}
          </div>
          <div className="csNextPrev">
            <UilAngleLeft
              onClick={() => calendarRef?.current?.getApi().prev()}
            />
            <UilAngleRight
              onClick={() => calendarRef?.current?.getApi().next()}
            />
          </div>
          <CustomButton
            onClick={() => calendarRef?.current?.getApi().today()}
            title="Today"
            variant="secondary"
          />
        </div>
      </div>
      {props.extraHtml}
      <FullCalendar
        ref={calendarRef}
        plugins={[momentTimezonePlugin, dayGridPlugin, interactionPlugin]}
        timeZone={props?.timeZone || 'local'}
        initialView="dayGridMonth"
        dayHeaderFormat={{ weekday: 'long' }}
        datesSet={(dt) =>
          props.onChange({ start: dt.start.getTime(), end: dt.end.getTime() })
        }
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        events={props?.sequence ? props?.timeZone ? events : props?.events : props?.events}
        dayMaxEvents={5}
        eventContent={props.eventContent}
        eventMouseEnter={(event) => {
          // Handle event mouse enter logic
        }}
        moreLinkContent={() => {
        return (
          <div
            className="flex items-center"
          >
            <span className="mr-1">
              <UilPlusCircle />
            </span>
            View More
          </div>
        );}}
        moreLinkClick={(info)=>{

          let url = '/outreach/reports';
          const params = [];

          params.push(`date=${info.date}`)
          if (props.sequence) {
            params.push(`sequence=${props.sequence}`);
          }

          if (props.sender) {
            params.push(`sender=${props.sender}`);
          }

          if (params.length > 0) {
            url += `?${params.join('&')}`;
          }
          window.open(url, '_blank');

        }}
      />
    </>
  );
}

export default Calendar;
