import React from 'react';
import { Label, Error, ClassHelper } from 'components/lib';
import Style from './select.tailwind.js';

export function Select(props) {
  let options = props.options;
  const error = props.errorMessage || props.placeholder || 'Please select an option';

  // set the default
  if (!props.default && options?.length && !props.valueField) {
    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: props.placeholder || 'Please select an option' });
  }

  function change(e) {
    let value = e ? e.target?.value : 'unselected';
    let label = e.target.options[e.target.selectedIndex].innerText;
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid, label);
    props.callback && props.callback(value);
  }

  const wrapperStyle = ClassHelper(Style, {
    className: props.className + ' select-wrapper',
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,
  });

  const selectStyle = ClassHelper(Style, {
    select: true,
    error: props.valid === false,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      <div className="flex items-center">
      <Label text={props.label} required={props.required} for={props.name} />
      {props.icon && props.icon}
      </div>

      <div className={wrapperStyle}>
        <select
          className={selectStyle}
          style={{ ...props.selectStyle, border: '1px solid #D7D7D7', borderRadius: 5, backgroundColor: '#F9F9F9' }}
          defaultValue={props.default}
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => change(e)}
          id={props.name}
        >
          {options?.map((option) => {
            if (props.valueField) {
              return (
                <option
                  key={option[props.valueField]}
                  value={option[props.valueField]}
                >
                  {props.labelField ? option[props.labelField] : option.label}
                </option>
              );
            } else {
              return (
                <option key={option.value} value={option.value}>
                  {props.labelField ? option[props.labelField] : option.label}
                </option>
              );
            }
          })}
        </select>

        {props.valid === false && (
          <Error message={error} className={Style.message} />
        )}
      </div>
    </div>
  );
}
