/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'components/lib';
import defaultLogo from '../../../assets/logo-small.png';
import defaultFavicon from '../../../assets/salesblink-icon-only-logo.png';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Favicon } from '../Favicon';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

export function ForgotPassword(props) {
  const [isLocalOrSalesBlink, setIsLocalOrSalesBlink] = useState(true);
  const [whiteLabelData, setWhiteLabelData] = useState({});
  const [customFaviconPresent, setCustomFaviconPresent] = useState(false);
  const widgetRef = useRef(null);
  const deviceWidth = useWindowResize();

  // to embed frill widget
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = window.location.origin.split('//')[1];
        const response = await axios.get('/api/whitelabel', { params: { query } });
        const data = response.data.data;
        setWhiteLabelData({
          agencyName: data?.agencyName || 'SalesBlink',
          headerLogo: data?.headerLogo || defaultLogo,
          favicon: data?.favicon || defaultFavicon,
        });
        if (data?.headerLogo) setCustomFaviconPresent(true);
      } catch (error) {
        console.log('error while fetching whitelabel data', error);
      }
    }
    if(!window.location.href.includes('salesblink') && !window.location.href.includes('localhost')) {
      setIsLocalOrSalesBlink(false);
      fetchData();
    } else if (deviceWidth > 991) {
      let widget;

      const config = {
        key: '0a05682c-4c0b-44b3-a1bb-61fe4a9d246c', // <-- Add Widget key here
        container: widgetRef.current,
        callbacks: {
          onReady: (frillWidget) => {
            widget = frillWidget;
          },
        },
      };

      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(config);

      if ('Frill' in window) {
        widget = window.Frill.widget(config);
      }

      return () => {
        widget?.destroy();
        if (window.Frill_Config) {
          window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
        }
      }
    }
  }, []);


  return (
    <div className="auth-signin">
      {customFaviconPresent && <Favicon url={whiteLabelData?.favicon} />}
      {whiteLabelData?.agencyName && <Helmet><title>Forgot Password | {whiteLabelData?.agencyName}</title></Helmet>}
      <div className="main">
        <div className="header">
          <div className="logo">
            {!isLocalOrSalesBlink && whiteLabelData?.headerLogo && <img src={whiteLabelData?.headerLogo} alt="Salesblink Logo" width={198} height={44} />}
            {isLocalOrSalesBlink && <img src={defaultLogo} alt="Salesblink Logo" width={198} height={44} />}
          </div>
          <p>Reset Your Password</p>
          <span style={{ textAlign: 'center' }}>
            Enter your {whiteLabelData?.agencyName || 'salesblink'} email address and we'll send you instructions
            to reset your password.
          </span>
        </div>
        <div className="signin-form">
          <div className="form">
            <Form
              data={{
                email: {
                  label: 'Email',
                  type: 'email',
                  required: true,
                  placeholder: 'Registered Email',
                },
              }}
              url="/api/auth/password/reset/request"
              method="POST"
              buttonText="Reset Password"
            />
          </div>
        </div>
      </div>
      {isLocalOrSalesBlink && deviceWidth > 991 && <div className="details">
        <div className="background">
          <div className="hover-thingy left"></div>
          <div className="hover-thingy top-right"></div>
          <div className="hover-thingy right"></div>
          <div className="hover-thingy bottom"></div>
          <div className="hover-thingy bottom-left"></div>
        </div>
        <div className="content" ref={widgetRef}>
          {/* frill widget here */}
        </div>
      </div>}
    </div>
  );
}
