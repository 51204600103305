import { Checkbox, WhitelabelContext } from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select/creatable';
import {UilQuestionCircle, UilCheck, UilTimes} from "@iconscout/react-unicons";
import { Tooltip } from 'react-tooltip';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

function MapTable(props) {
  const [columns, setColumns] = useState(props.mappedValues);

  useEffect(() => {
    if (props?.columns?.length > 0) {
      let arr = [];
      props.columns.forEach((column, index) => {
        arr.push(props.mappedValues[index] ? props.mappedValues[index] : '');
      });
      setColumns(arr);
      props.setMappedValues(arr);
    }
  }, [props.columns]);

  const updateColumn = (idx, val) => {
    setColumns((oldColumns) =>
      oldColumns.map((value, index) => {
        if (index === idx) return val.value;
        return value;
      })
    );
    props.setMappedValues((oldColumns) =>
      oldColumns.map((value, index) => {
        if (index === idx) return val.value;
        return value;
      })
    );
  };

  useEffect(() => {
    props.onChange(columns);
  }, [columns]);

  return (
    <table className="csMapTable">
      <thead>
        <tr>
          {props?.headers?.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props?.columns.map((column, index) => (
          <tr key={index}>
            <td>{column}</td>
            <td
              style={{
                maxWidth: 300,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {props.sample[index]}
            </td>
            <td>
              {column.toLowerCase().includes('email') || (columns[index] !== 'skip' && columns[index] !== '') ? (
                <UilCheck color="green" size={25} style={{"--color": "#3AC569", "--border-color": "#89DCA5", "--background-color": "#D3EFDC"}} />
              ) : (
                <UilTimes color="red" size={25} style={{"--color": "#E53A40", "--border-color": "#EF898C", "--background-color": "#F5D3D4"}} />
              )}
            </td>
            <td>
              <Select
                styles={{
                  control: () => ({
                    padding: 0,
                    width: '100%',
                    height: "30px",
                    borderRadius: 5,
                    display: 'flex',
                    border: `1px solid ${
                      props.mapError !== '' && props.mapError === columns[index]
                        ? '#d14343'
                        : '#D7D7D7'
                    }`,
                    fontSize: 14
                  }),
                  option: (provided) => ({
                    ...provided,
                    textAlign: 'center'
                  })
                }}
                menuPortalTarget={document.body}
                defaultValue={columns[index]}
                placeholder="Start Typing"
                onChange={function (val) {
                  updateColumn(index, val);
                  if (props.mapError === columns[index]) props.setMapError('');
                }}
                options={props.fieldNames}
                value={(function () {
                  if (!columns[index] && column.toLowerCase().includes('email')) {
                    columns[index] = props.fieldNames[3].value;
                  }
                  // if there's some value then find it's corresponding label and value in fieldNames
                  if (columns[index]) {
                    // if it's a custom value then just return it as label and value
                    const label =
                      props.fieldNames.find((field) => field.value === columns[index])
                        ?.label ?? columns[index];
                    const value = columns[index];

                    return { label, value };
                  }
                  // returning null shows the placeholder in the select
                  return null;
                })()}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default function Map(props) {
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const deviceWidth = useWindowResize();

  return (
    <div style={{color: "#3A3A3A"}}>
      <h2 style={{ textAlign: 'center', fontSize: 22, marginTop: 40, fontWeight: 500, marginBottom: 10 }}>
        {' '}
        {`Map Columns to ${whitelabelDetails?.agencyName} Variables`}
      </h2>
      <p style={{ width: deviceWidth > 991 ? 1000 : '', textAlign: 'center', margin: 'auto' }}>
        Map the variables for your leads to personalize cold emails.
        <br /> 
        Start Typing to create a new variable.
      </p>
      <div className="csRow">
        <div style={{ width: '900px', margin: 'auto', overflowX: 'scroll' }}>
          <MapTable
            fieldNames={props.fieldNames}
            onChange={(cols) => props.onChange(cols)}
            columns={props.columns}
            sample={props.sample || {}}
            headers={['Column from CSV', 'Data Preview', 'Mapped', `${whitelabelDetails?.agencyName} Variable`]}
            mapError={props.mapError}
            setMapError={props.setMapError}
            mappedValues={props.mappedValues}
            setMappedValues={props.setMappedValues}
          />{' '}
          {/*"Manage Existing"*/}
        </div>
      </div>
      <div className="csRow">
        <div style={{ width: '900px', margin: 'auto' }}>
          <div style={{ display: "flex", gap: "0.25rem", alignItems: "baseline" }}>
            <Checkbox
              name="list_duplicates"
              onChange={(i, checked, option) => {
                if(props.removeDuplicated) {
                  props.setRemoveDuplicated(false)
                  props.setRemoveDuplicatedFromThisList(false)
                  props.setRemoveDuplicatedFromOtherList(false)
                  props.setRemoveDuplicatedFromTeamMemberList(false)
                } else props.setRemoveDuplicated(true)
              }}
              checked={props.removeDuplicated}
              option="Remove Duplicate Leads"
            />
            <UilQuestionCircle data-tooltip-show="true" data-tooltip-content="Options to remove duplicate contacts before they are uploaded"/>
            <Tooltip place='right' anchorSelect='[data-tooltip-show="true"]'/>
          </div>
          {props.removeDuplicated && (
            <div>
              <Checkbox
                name="list_duplicates"
                onChange={(i, checked, option) =>
                  props.setRemoveDuplicatedFromThisList(
                    !props.removeDuplicatedFromThisList
                  )
                }
                checked={props.removeDuplicatedFromThisList}
                option="If duplicate leads with same email exist in this list"
              />
              <Checkbox
                name="list_duplicates"
                onChange={(i, checked, option) =>
                  props.setRemoveDuplicatedFromOtherList(
                    !props.removeDuplicatedFromOtherList
                  )
                }
                checked={props.removeDuplicatedFromOtherList}
                option="If duplicate leads with same email exist in my other lists"
              />
              <Checkbox
                name="list_duplicates"
                onChange={(i, checked, option) =>
                  props.setRemoveDuplicatedFromTeamMemberList(
                    !props.removeDuplicatedFromTeamMemberList
                  )
                }
                checked={props.removeDuplicatedFromTeamMemberList}
                option="If duplicate leads with same email exist in my team member's lists"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
