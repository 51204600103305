/***
 *
 *   SOCIAL SIGN IN
 *   After social authentication has been completed on the server
 *   the user is redirected back to this view - which obtains
 *   the token and completes the signin flow as normal
 *
 **********/

import React, { useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Animate,
  AuthContext,
  ViewContext,
  Card,
  Row,
  Message,
  useNavigate,
} from 'components/lib';

export function SocialSignin(props) {
  // wrap in useRef to prevent triggering useEffect multiple times
  const authContext = useRef(useContext(AuthContext));
  const viewContext = useRef(useContext(ViewContext));
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [provider, token] = ['provider', 'token'].map((item) =>
    searchParams.get(item)
  );

  useEffect(() => {
    if (!token) return navigate('/signin', { replace: true });

    (async function fetchToken() {
      try {
        const res = await axios({
          method: 'post',
          url: '/api/auth',
          data: { token: token },
        });
        res.data['2fa_required']
          ? navigate(`/signin/otp?token=${res.data.token}`)
          : authContext.current.signin(res);
      } catch (err) {
        viewContext.current.handleError(err);
      }
    })();
  }, [token, viewContext, authContext, navigate]);

  return (
    <Animate type="pop">
      <Row
        title={`Signing in with ${
          provider?.charAt(0).toUpperCase() + provider?.slice(1)
        }`}
      >
        <Card loading={token}>
          {!token && (
            <div style={{ width: '32em', margin: '0 auto' }}>
              <Message
                type="error"
                title={`Something wen't wrong`}
                text="Invalid URL, please try in again"
                buttonText="Sign In"
                buttonLink="/signin"
              />
            </div>
          )}
        </Card>
      </Row>
    </Animate>
  );
}
