import Axios from 'axios';
import BundledEditor from 'components/custom/buldledEditor';
import CustomModal from 'components/custom/customModal';
import { TextInput, Button } from 'components/lib';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';

export default function SignatureEditor(props) {
  const editorRef = useRef(null);
  const [signatureName, setSignatureName] = useState('');
  const [signature, setSignature] = useState('');

  useEffect(() => {
    if (!props.selected) return;
    setSignatureName(props.selected.name);
    setSignature(props.selected.content);
  }, [props.selected]);

  const handleSave = async () => {
    try {
      if (signatureName.trim() === '' || signature.trim() === '')
        return Swal.fire({
          icon: 'error',
          title: ``,
          html: `<h5>Error, Name and Content is Required!</h5>`,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });

      let body = {
        name: signatureName,
        content: signature,
      };

      if (props.selected)
        await Axios.put(`/api/signatures/${props.selected.id}`, body);
      else await Axios.post('/api/signatures', body);

      props.onClose();
    } catch (err) {
      props.onClose();
      return Swal.fire({
        icon: 'error',
        title: ``,
        html: `<h5>Error in Creating Signature!</h5>`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  };

  return (
    <>
      <CustomModal
        title={props.selected ? 'Edit Signature' : 'Create Signature'}
        onClose={props.onClose}
        width={500}
      >
        <div style={{ padding: 15 }}>
          <p
            className="text-modal-description"
            style={{ marginTop: '-15px', padding: 0 }}
          >
            Create an email signature then edit your email sender to attach the signature to it.
            <br /><br />
            Add your signature in email templates with the variable {`{{email_signature_of_sender}}`}
          </p>
          <TextInput
            onChange={(name, value) => setSignatureName(value)}
            value={signatureName}
            label="Name"
            required={true}
          />
          <span className="font-semibold relative block text-sm mb-2 after:text-red-500 after:ml-1 after:content-['*']" style={{ fontSize: 16, fontWeight: 500 }}>
            Email Signature
          </span>
          <BundledEditor
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={signature}
            onEditorChange={(editedContent) => {
              setSignature(editedContent);
            }}
            init={{
              height: 250,
              menubar: false,
              elementpath: false,
              plugins: [
                'advlist',
                'anchor',
                'autolink',
                'image',
                'link',
                'lists',
                'searchreplace',
                'table',
                'wordcount',
                'code',
                'emoticons'
              ],
              toolbar:
                'blocks | ' +
                'bold italic forecolor fontsize code link image emoticons | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist | ' +
                'removeformat | insertImage | unsubButton',
              contextmenu: 'copy paste cut | link ',
              fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',  
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
          <div style={{ width: '100%', marginTop: 20, display: "flex", justifyContent: "flex-end", gap: "2rem" }}>
            <Button
              variant="primary grey"
              action={() => props.onClose()}
              text="Cancel"
            />
            <Button
              variant="primary"
              action={() => handleSave()}
              text={props.selected ? 'Update' : 'Create'}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
}
