/***
 *
 *   BILLING / PLAN
 *   Update the billing plan
 *
 **********/

import { UilSync, UilArrowLeft, UilUsersAlt, UilExclamationTriangle, UilEnvelopes, UilFire, UilAt, UilEnvelopeCheck, UilCheck } from '@iconscout/react-unicons';
import { useStripe } from '@stripe/react-stripe-js';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import CustomModal from 'components/custom/customModal';
import {
  AuthContext,
  Card,
  CustomTable,
  Loader,
  PaymentForm,
  CustomSwitch,
  usePlans,
  WhitelabelContext,
  SubSubNav,
} from 'components/lib';
import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Tooltip } from "react-tooltip"
import BlinkGPT from '../../../assets/BlinkGPT-filled.png';
import './plan.scss';
import { DateTime } from 'luxon';

const planDev = {
    scale: {
        month: "price_1NlO9xGdPjcvdGep9YF3r6AD",
        year: "price_1NlO9xGdPjcvdGepEIlXuvy3",
    },
    growth: {
        month: "price_1NlOBWGdPjcvdGepZTcoisuX",
        year: "price_1NlOBWGdPjcvdGepiG9E8ygr",
    },
    business: {
        month: "price_1NlOCSGdPjcvdGep9ttru8rN",
        year: "price_1NlOCTGdPjcvdGepbVHNdFQg",
    },
    agency_scale: {
        month: "price_1PgjF9GdPjcvdGepx9QOieRr",
        year: "price_1PgjF9GdPjcvdGepAoEr0Y4B",
    },
    agency_growth: {
        month: "price_1PgjFVGdPjcvdGeptaUFevCe",
        year: "price_1PgjFwGdPjcvdGepfojH6Ta2",
    },
    agency_business: {
        month: "price_1PgjHSGdPjcvdGeps4pAOsah",
        year: "price_1PgjHSGdPjcvdGepSuG2AaXK",
    }
};

const planProd = {
    scale: {
        month: "price_1NmacEGdPjcvdGepmmuHKhFx",
        year: "price_1NmacEGdPjcvdGep2Es38a71",
    },
    growth: {
        month: "price_1NmacNGdPjcvdGepsKomGWo4",
        year: "price_1NmacNGdPjcvdGepXIPMMVgF",
    },
    business: {
        month: "price_1NmacTGdPjcvdGep92G9GQKO",
        year: "price_1NmacTGdPjcvdGepI1laNWPb",
    },
    agency_scale: {
        month: "price_1Ph1psGdPjcvdGepRNZruWNA",
        year: "price_1Ph1psGdPjcvdGepnbYjAxPc",
    },
    agency_growth: {
        month: "price_1Ph1pwGdPjcvdGepxUk00D9W",
        year: "price_1Ph1pwGdPjcvdGepYS6hYU2e",
    },
    agency_business: {
        month: "price_1Ph1pzGdPjcvdGepT5TslN8s",
        year: "price_1Ph1pzGdPjcvdGep8Cer6g7m",
    }
};

let planObj = process.env.NODE_ENV === 'production' ? planProd : planDev;

const plansArr = [
    {
        title: 'Scale',
        price: 29,
        price_yearly: 25,
        monthly_plan_id: planObj.scale.month,
        yearly_plan_id: planObj.scale.year,
        features: [
            <span>Send upto <span className='highlight'>7000</span> emails</span>,
            <span>Unlimited <span className='highlight'>Uploaded Contacts</span></span>, 
            <span>Unlimited <span className='highlight'>Email Accounts</span></span>, 
            <span>Unlimited <span className='highlight tooltip' data-tooltip-content='Improve email deliverability with a Pool of 10,000+ real inboxes'>Email WarmUp</span></span>, 
            <span className='highlight tooltip' data-tooltip-content='Generate Emails with BlinkGPT AI'>BlinkGPT AI Email Templates</span>,
            <span>Unified <span className='highlight'>Inbox</span></span>,
            <span>Email <span className='highlight'>Address Rotation</span></span>,
            <span>Automated <span className='highlight'>Follow-Ups</span></span>,
        ]
    },
    {
        title: 'Growth',
        price: 99,
        price_yearly: 79,
        monthly_plan_id: planObj.growth.month,
        yearly_plan_id: planObj.growth.year,
        features: [
            <span>Everything in Scale and</span>,
            <span>Send upto <span className='highlight'>150,000</span> emails</span>,
            <span className='highlight'>BlinkGPT AI Sequences</span>,
            <span className='highlight'>Unlimited Team Members</span>,
            <span>Meeting Scheduler</span>,
            <span>CRM Integrations</span>,
        ]
    },
    {
        title: 'Business',
        price: 199,
        price_yearly: 179,
        monthly_plan_id: planObj.business.month,
        yearly_plan_id: planObj.business.year,
        features: [
            <span>Everything in Growth</span>,
            <span>Send upto <span className='highlight'>500,000</span> emails</span>,
            <span>BlinkGPT AI Sequences</span>,
            <span>BlinkGPT AI Personalized Sequence</span>,
            <span>Outreach Tasks</span>,
            <span>Team Reports</span>,
        ]
    },
];

const agencyPlansArr = [
    {
        title: 'Agency Scale',
        price: 99,
        price_yearly: 83,
        monthly_plan_id: planObj.agency_scale?.month,
        yearly_plan_id: planObj.agency_scale?.year,
        features: [
            // <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Upto <span className='highlight'>3</span> Client</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>150,000</span> emails</span>,
        ]
    },
    {
        title: 'Agency Growth',
        price: 199,
        price_yearly: 166,
        monthly_plan_id: planObj.agency_growth?.month,
        yearly_plan_id: planObj.agency_growth?.year,
        features: [
            // <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Upto <span className='highlight'>10</span> Client</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>500,000</span> emails</span>,
        ]
    },
    {
        title: 'Agency Business',
        price: 299,
        price_yearly: 250,
        monthly_plan_id: planObj.agency_business?.month,
        yearly_plan_id: planObj.agency_business?.year,
        features: [
            // <span>Unlimited <span className='highlight'>Email Sending Accounts</span></span>,
            <span>Upto <span className='highlight'>25</span> Client</span>, 
            <span className='highlight'>Whitelabel</span>,
            <span>Send upto <span className='highlight'>1,000,000</span> emails</span>,
        ]
    },
];

const formatNumber = (value) => {
    if(value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value
}

const PricingPlanCard = (props) => {
    const navigator = useNavigate();

    const activePlan = () => {
        if(props.isYearly) {
            if(props.activePlan === props.yearly_plan_id) return true
            return false
        } 
        if(props.activePlan === props.monthly_plan_id) return true
        return false
    }

    return (
        <div className="pricing-plan-card">
            <h2 className="title">{props.title}</h2>

            <div className='price'>
                {/* show monthly price */}
                {!props.isYearly && <><span style={{ fontSize: "1.75rem" }}>${props.price}</span> / month</>}

                {/* show yearly price with monthly price crossed out */}
                {props.isYearly && <><span style={{fontSize: "1.75rem"}}>${props.price_yearly}</span> {props.price_yearly < 500 && <span style={{textDecoration: "line-through"}}>${props.price}</span>} {props.price_yearly < 500 ? `/ month` : "/ yr"}</>}

                {props.price_yearly < 500 && <p style={{color: "#757575", fontSize: "0.8rem", visibility: props.isYearly ? "visible" : "hidden"}}>Billed Annually as ${props.price_yearly * 12}</p>}
            </div>
            <ul className="features">
                {props.features.map((feature, index) => (
                    <li key={index}><span><UilCheck className="check"/></span>{feature}</li>
                ))}
            </ul>
            <div className='mb-3 flex justify-center' style={{ marginTop: -20 }}>
                <a href='https://salesblink.io/pricing#features' className='csCustomLink' style={{ fontSize: 16 }} target='_blank'>View all Features</a>
            </div>
            {props.title === props.selected?.name && ((props.isYearly && props.selected?.subscription === props.yearly_plan_id) || (!props.isYearly && props.selected?.subscription === props.monthly_plan_id)) ? 
                <CustomButton
                    variant="primary"
                    disabled={props.disableBuy}
                    onClick={() => console.log(props.selected)}
                    title={<UilSync
                        style={{ margin: 'auto' }}
                        size="20"
                        className={`rotate`}
                    />}
                    style={{ width: 150, margin: 'auto' }}
                />
            : <CustomButton
                variant={`${activePlan() ? 'secondary' : 'primary'}`}
                disabled={props.disableBuy}
                onClick={() => {
                    // if(!activePlan()) setShowConfirmModal(true)
                    if(!activePlan()) {
                        navigator(`/account/billing/${props.isYearly ? props.yearly_plan_id : props.monthly_plan_id}/confirm`)
                        sessionStorage.setItem('activePlan', props.activePlan)
                        
                        // Swal.fire({
                        //     icon: 'info',
                        //     title: `You are subscribing to ${props.title} Plan at ${props.isYearly ? `$${props.price_yearly * 12} / year` : `$${props.price} / month`}.`,
                        //     showCancelButton: true,
                        //     cancelButtonText: `Cancel`,
                        //     confirmButtonText: `Subscribe Plan`,
                        //     confirmButtonColor: '#0066FF',
                        // }).then((result) => {
                        //     if (result.isConfirmed) {
                        //         props.onBuy(props.isYearly ? props.yearly_plan_id : props.monthly_plan_id, props.title)
                        //     }
                        // });
                    }
                }}
                title={activePlan() ? 'Active Now' : props.buttonLabel}
                style={{ width: 150, margin: 'auto' }}
            />}
        </div>
    );
};

function CouponCard({ children }) {
    return (
        <div
            className="bg-blue-100 border-blue-900 p-3 mb-2"
            style={{ borderRadius: 9 }}
        >
            {children}
        </div>
    );
}

export function BillingPlan(props) {
    const plans = usePlans();
    const couponCodeRef = useRef(null);
    const context = useContext(AuthContext);
    const coupons = context.user.coupons || [];
    const [type, setType] = useState(new URLSearchParams(window.location.search).get("type") === "upgrade" ? "upgrade" : '');
    const [selected, setSelected] = useState(null);
    const [activePlans, setActivePlans] = useState([]);
    const [planUsage, setPlanUsage] = useState(null);
    const [plan, setPlan] = useState(null);
    const [selectedTab, setSelectedTab] = useState('For Own Business');

    const [loading, setLoading] = useState(false);
    const [disableBuy, setDisableBuy] = useState(false);
    const [viewAddCard, setViewAddCard] = useState(false);
    const [expired, setExpired] = useState(false);
    const [isYearly, setIsYearly] = useState(false);
    const [previousPlanInterval, setPreviousPlanInterval] = useState(undefined);
    const [activePlan, setActivePlan] = useState('');
    const [couponValue, setCouponValue] = useState("");
    const [checkCoupon, setCheckCoupon] = useState(null);
    const [checkCouponMessage, setCheckCouponMessage] = useState("");
    const timeoutRef = useRef(null);
    const { whitelabelDetails } = useContext(WhitelabelContext);
    const navigator = useNavigate();
    const stripe = useStripe();

    useEffect(() => {
        getCurrentPlan();
        getActivePlans();
    }, []);

    async function getActivePlans() {
        try {
            let res = await Axios({
                method: 'GET',
                url: `/api/account/plan`,
            });
            if (res.data.plans.length > 0) {
                setActivePlans(
                    res.data.plans.map((p) => {
                        return {
                            name: p.name,
                            interval: p.interval,
                            amount: p.amount,
                            quantity: p.quantity,
                        };
                    })
                );
                setExpired(res.data.expired);
            }
            setPlanUsage(res.data.data);
            if (res.data.plans.length > 0) {
                setActivePlan(res.data.plans[0].id)
                if (res.data.plans[0].interval === 'year') {
                    setIsYearly(true);
                    setPreviousPlanInterval(true);
                }
                if (res.data.plans[0].interval === 'month') {
                    setIsYearly(false);
                    setPreviousPlanInterval(false);
                }
            }
        } catch (err) {}
    }

    const getCurrentPlan = async () => {
        try {
            let result = await Axios({
                method: 'GET',
                url: `/api/active-plan-usage`,
            });

            if(result.data.data) setPlan(result.data.data);
        } catch (err) {}
    };

    async function submit(e) {
        e.preventDefault();
        try {
            let val = couponCodeRef.current.value;
            let res = await Axios({
                method: 'POST',
                url: `/api/account/add-custom-coupon`,
                data: {
                    accountID: context.user.account_id,
                    coupon: val,
                },
            });

            if (res.data.couponValid === false)
                return Swal.fire({
                    icon: 'error',
                    title: `Sorry!`,
                    html: res.data.err,
                    showCancelButton: true,
                    confirmButtonText: `Ok`,
                    confirmButtonColor: '#0066FF',
                }).then((result) => {});
            
            Swal.fire({
                icon: 'success',
                title: ``,
                html: `<h5>Coupon Applied Successfully!</h5>`,
                showCancelButton: true,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
            }).then((result) => {
                window.location.reload();
            });

            context.user.coupons.push(val);
            localStorage.setItem('user', JSON.stringify(context.user));
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: `Error!`,
                html: `<h5>Something Went Wrong.</h5>`,
                showCancelButton: true,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
            }).then((result) => {});
        }
    }

    if (coupons.length > 0) {
        return (
            <Card
                loading={plans.loading || props.subscription.loading}
                restrictWidth
                className={props.className}
            >
                <h2 style={{ fontSize: 18, marginBottom: 10 }}>Benefits</h2>
                {coupons.length > 0 && <CouponCard>5 Email Senders</CouponCard>}
                {coupons.length > 0 && (
                    <CouponCard>
                        {coupons.length * 5000} Prospecting Credit Per Month
                    </CouponCard>
                )}
                <h2 style={{ fontSize: 18, marginBottom: 10 }}>Codes Used</h2>
                {coupons.map((coupon) => (
                    <CouponCard>{coupon}</CouponCard>
                ))}
                <form onSubmit={(e) => submit(e)}>
                    <input
                        ref={couponCodeRef}
                        placeholder="coupon1,coupon2"
                        className="relative block w-full p-3 rounded bg-white border bordeer-solid border-slate-100 
                focus:bg-slate-50 appearance-none disabled:opacity-50"
                        type="text"
                        name="code"
                    />
                    <button
                        style={{ marginTop: 10 }}
                        className="cursor-pointer font-sans w-[49%] inline-block mr-[2%] last:mr-0 !w-full bg-emerald-500 hover:bg-emerald-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500 text-white"
                    >
                        Apply
                    </button>
                </form>
            </Card>
        );
    }

    const buyNow = async (subscription, name, coupons) => {
        if(disableBuy) return
        if(!subscription || !name) return Swal.fire({
            icon: 'error',
            title: `Oops!`,
            html: `<h4>Please refresh page and try again.</h4>`,
            confirmButtonText: `Okay`,
            confirmButtonColor: '#0066FF',
        });

        setSelected({ name, subscription })
        setDisableBuy(true);

        try {
            if(previousPlanInterval !== undefined) {
                if(previousPlanInterval !== isYearly) {
                    let cancelledSuccess = await cancelSubscriptionForUpdatePlanInterval();
                    if(!cancelledSuccess) {
                        Swal.fire({
                            icon: 'error',
                            title: `Oops!`,
                            html: `<h4>Please try again later or contact customer support.</h4>`,
                            confirmButtonText: `Okay`,
                            confirmButtonColor: '#0066FF',
                        });
                        setSelected(null)
                        setDisableBuy(false);
                        return
                    }
                }
            }

            let res = await Axios.patch('/api/account/plan', {
                subscriptions: [{
                    price: subscription,
                    quantity: 1
                }],
                coupons
            });
            if (res.data.requires_payment_action) {
                const stripeRes = await stripe.handleCardPayment(
                    res.data.client_secret
                );
                if (stripeRes.error) {
                    setLoading(false);
                    setSelected(null)
                    context.handleError(stripeRes.error.message);
                    return false;
                } else {
                    // re-send the form
                    res = await Axios.patch('/api/account/plan', {
                        subscriptions: [{
                            price: subscription,
                            quantity: 1
                        }],
                        stripe: res.data,
                        coupons
                    });
                }
            }
            // Swal.fire({
            //     icon: 'success',
            //     title: `Success!`,
            //     html: `<h4>Subscription Updated</h4>`,
            //     confirmButtonText: `Okay`,
            //     confirmButtonColor: '#0066FF',
            // });
            setDisableBuy(false);
            setSelected(null)
            // window.location.href = "/account/billing";
            sessionStorage.setItem('subscribed_plan', JSON.stringify({
                name,
                plan: res.data.data.plans.length > 0 ? res.data.data.plans[0] : {},
                subscriptionID: res.data.data.subscriptionID,
                coupons
            }))
            navigator('/subscribed')
        } catch (error) {
            console.log("TEST ERROR ", error)
            setLoading(false);
            setDisableBuy(false);
            if (error.response?.status === 409) return setViewAddCard(true);
            setSelected(null)
            let errMsg = 'Error updating plan. Try after sometime.';
            if (error.response?.data?.message) errMsg = error.response?.data?.message;
            Swal.fire({
                icon: 'error',
                title: `Oops!`,
                html: `<h4>${errMsg}</h4>`,
                confirmButtonText: `Ok`,
                confirmButtonColor: '#0066FF',
            });
        }
    };

    const cancelSubscriptionForUpdatePlanInterval = async () => {
        try {
            await Axios.delete('/api/account/plan');
            return true
        } catch (error) {
            console.log("ERR ", error);
            return false;
        }
    }

    const addDate = () => {
        let endDate = DateTime.now().toFormat('d MMM yyyy');
        if(props.subscription?.data?.object?.current_period_end) endDate = props.subscription?.data?.object?.current_period_end;
        return endDate
    }

    const cancelSubscription = async () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            html: `<h5>Your subscription will be cancelled on ${addDate()} & you will not be charged again.</h5>`,
            showCancelButton: true,
            confirmButtonText: `Cancel Subscription`,
            confirmButtonColor: '#D14343',
            cancelButtonText: "Don’t Cancel"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await Axios.delete('/api/account/plan');
                    window.location.reload();
                } catch (error) {
                    console.log('ERROR in Cancel Plan! ', error);
                }
            }
        });
    };

    const columns = [
        {
            Header: 'Plan Name',
            Footer: 'Plan Name',
            accessor: 'name',
            disableSortBy: true,
            Cell: ({ value }) => {
                return <div className='flex'>
                    <span style={{ textTransform: 'capitalize' }}>
                        {value}
                    </span>
                    <span style={{ marginLeft: 3 }}>
                        (<a href="https://salesblink.io/pricing#features" target='_blank'>view all features</a>)
                    </span>
                </div>
            },
        },
        {
            Header: 'Billed',
            Footer: 'Billed',
            accessor: 'interval',
            disableSortBy: true,
            Cell: ({ value }) => {
                return <span style={{ textTransform: 'capitalize' }}>{value}ly</span>
            },
        },
        {
            Header: <span className='csCenter'>Amount</span>,
            accessor: 'amount',
            disableSortBy: true,
            Cell: ({ value }) => {
                return (
                    <div className='csCenter'>
                        <span className='csBadge success' style={{ textTransform: 'capitalize' }}>
                            ${value}
                        </span>
                    </div>
                );
            },
        }
    ];

    return (
        <>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        zIndex: 20,
                        bottom: 0,
                        top: 0,
                    }}
                >
                    <Loader />
                </div>
            )}

            {viewAddCard && (
                <CustomModal close={true} title='One Last Step…' onClose={() => {
                    setViewAddCard(false)
                    setSelected(null);
                    setCheckCoupon(null);
                    setCheckCouponMessage("");
                    setCouponValue("");
                    clearTimeout(timeoutRef.current);
                }}>
                    <div style={{ padding: 15 }}>
                        <PaymentForm
                            data={{
                                token: {
                                    label: 'Credit Card Details',
                                    type: 'creditcard',
                                    required: true,
                                },
                                coupon: {
                                    label: 'Do you have a coupon code?',
                                    type: 'text',
                                    placeholder: 'Enter your coupon code',
                                    errorMessage: checkCouponMessage,
                                    valid: !!!checkCouponMessage,
                                    value: couponValue,
                                },
                            }}
                            onChange={async function ({input, value, valid}) {
                                if(input === "coupon") {
                                    setCouponValue(value);

                                    if(value) {   
                                        clearTimeout(timeoutRef.current);
                                        
                                        timeoutRef.current = setTimeout(async () => {
                                            try {
                                                const response = await Axios.get(`/api/account/check-coupon?coupon=${value}`)
                                                setCheckCoupon(response.data.coupons);
                                                setCheckCouponMessage("");
                                            } catch(error) {
                                                setCheckCouponMessage(error.response.data.message);
                                                setCheckCoupon(null);
                                            }
                                        }, 1500);
                                    }
                                }
                            }}
                            updateOnChange={true}
                            url="/api/account/create-customer"
                            method="POST"
                            buttonText={function () {
                                const plan = plansArr.find(plan => plan.title === selected.name);
                                const price = isYearly ? plan.price_yearly * 12 : plan.price;

                                const lessAmount = checkCoupon?.amount_off;
                                const lessPercentage = checkCoupon?.percent_off;

                                let term = ""
                                if(checkCoupon) {
                                    if(lessAmount) term = `$${lessAmount / 100} off `
                                    else if(lessPercentage) term = `${lessPercentage}% off `

                                    if(checkCoupon?.duration === "forever" || checkCoupon?.duration === "once") {
                                        term += checkCoupon?.duration
                                    } else if(checkCoupon?.duration === "repeating") {
                                        term += `for ${checkCoupon?.duration_in_months} months`;
                                    }
                                }

                                return (
                                  <div>
                                    Subscribe to {selected.name} Plan{' '}
                                    {isYearly ? 'Yearly' : 'Monthly'} at{' '}
                                    ${price}
                                    {'  '} {checkCoupon?`(${term})`:""}
                                  </div>
                                );
                            }()}
                            callback={(res) => {
                                if (res.data.success) {
                                    setViewAddCard(false);
                                    buyNow(selected?.subscription, selected?.name, res.data.coupon);
                                }
                            }}
                        />
                    </div>
                </CustomModal>
            )}

            <div>
                {type === '' && (
                    <div className="selectBilling">
                        {(plan?.name === 'free' || plan?.name === 'cancelled') && (
                            <div>You currently have not subscribed to any plans.</div>
                        )}
                        {plan?.name === 'Trial' && (
                            <div>
                                {props.subscription?.data?.object?.current_period_end ? (
                                    <div className='planView'>
                                        <h1 className="font-bold text-xl">
                                            Your trial period is till{' '}
                                            {props.subscription?.data?.object?.current_period_end}
                                        </h1>
                                        <h1 className="font-bold mt-3 mb-2">
                                            Your Trial Limits
                                        </h1>
                                        {planUsage && <PlanUsageView 
                                            planUsage={planUsage}
                                            type="Trial"
                                        />}
                                    </div>
                                ) : (
                                    <div>Your trial has expired.</div>
                                )}
                            </div>
                        )}
                        {expired ? (
                            <div className="font-bold">Your Plan has expired.</div>
                        ) : (
                            activePlans.length > 0 && (
                                <div className='planView'>
                                    <div className='planTitle'>Plan Usage (Resets Monthly)</div>

                                    {props.subscription?.data?.object?.cancel_at_period_end && <p className='flex csBadge warning cursor-pointer' style={{ marginRight: 10 }}>
                                        <UilExclamationTriangle size={16} style={{ marginTop: 3, marginRight: 5 }} />
                                        <span>
                                            Your subscription expires on {addDate()}, Please <span className='csLink' onClick={() => {
                                                setType('upgrade')
                                                props.setShowTab(false)
                                            }}>select a subscription</span> to continue using {whitelabelDetails?.agencyName}.
                                        </span>
                                    </p>}

                                    {planUsage && <PlanUsageView 
                                        planUsage={planUsage}
                                    />}

                                    <div className='planTitle'>
                                        Your Subscribed Plan
                                    </div>
                                    <CustomTable
                                        hasFooter={false}
                                        columns={columns}
                                        data={activePlans}
                                        pagination={false}
                                        onClick={(row) => {}}
                                    />
                                </div>
                            )
                        )}
                        <div style={{ display: 'flex' }}>
                            <CustomButton
                                className="ml-2"
                                title={
                                    plan?.name === 'free' ||
                                    plan?.name === 'cancelled' ||
                                    expired
                                        ? 'Buy Plan'
                                        : 'Update Plan'
                                }
                                variant="primary"
                                style={{ marginRight: 15 }}
                                onClick={(e) => {
                                    setType('upgrade')
                                    props.setShowTab(false)
                                }}
                            />
                            {activePlans.length > 0 && !expired && (
                                <CustomButton
                                    className="ml-2"
                                    title="Cancel Subscription"
                                    onClick={(e) => cancelSubscription()}
                                    variant="primary grey"
                                />
                            )}
                            {/* {plan?.name === 'free' && (
                                <button
                                    className="csBtnTrial"
                                    style={{ marginLeft: 10, marginTop: 8 }}
                                    onClick={(e) => navigator('/signup/plan')}
                                >
                                    Start Trial
                                </button>
                            )} */}
                        </div>
                    </div>
                )}
                
                {type === 'cancel' && (
                    <div>
                        <button
                            className="bg-grey-500 font-semibold "
                            onClick={(e) => setType('')}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            {type === 'upgrade' && (<div>
                <div>
                    <div style={{ marginBottom: 20 }} className="billing">
                        <button
                            onClick={() => {
                                props.setShowTab(true)
                                setType('');
                                navigator("/account/billing")
                            }}
                            className='csBadge'
                            style={{ border: '3px solid #A0A0A0', padding: 10, marginTop: 5 }}
                        >
                            <UilArrowLeft size={26} color="#A0A0A0" />
                        </button>

                        <div className='flex justify-center mb-2'>
                            <SubSubNav
                                selected={selectedTab}
                                onChange={(selectedItem) => setSelectedTab(selectedItem.title)}
                                menuitems={[
                                    {
                                        // icon: 'unicons',
                                        // image: <UilUsdCircle />,
                                        title: 'For Own Business',
                                    },
                                    {
                                        // icon: 'unicons',
                                        // image: <UilCreditCard />,
                                        title: 'For Agencies',
                                    }
                                ]}
                            />
                        </div>

                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <label style={{ fontSize: 16, fontWeight: 500 }}>Monthly</label>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                    <CustomSwitch
                                        value={isYearly}
                                        onChange={(value) => {
                                            if(!disableBuy) setIsYearly(value)
                                        }}
                                    />
                                </div>
                                <label style={{ fontSize: 16, fontWeight: 500 }}>Yearly</label>
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <Row>
                                    {selectedTab === "For Own Business" && plansArr.map((plan, i) => (
                                        <Col key={i}>
                                            <PricingPlanCard key={i} 
                                                buttonLabel={
                                                    (plans?.data?.active !== 'free' && plans?.data?.active !== 'Trial')
                                                    ? 
                                                        (plan?.name === 'free' || plan?.name === 'cancelled')
                                                            ? 'Buy Plan'
                                                        : 'Update Plan'
                                                    : 'Subscribe Now'
                                                } 
                                                selected={selected}
                                                disableBuy={disableBuy}
                                                isYearly={isYearly}
                                                onBuy={buyNow}
                                                activePlan={activePlan}
                                                {...plan} 
                                            />
                                            <Tooltip place='top' anchorSelect='.highlight.tooltip'/>
                                        </Col>
                                    ))}
                                    {selectedTab === "For Agencies" && agencyPlansArr.map((plan, i) => (
                                        <Col key={i}>
                                            <PricingPlanCard key={i} 
                                                buttonLabel={
                                                    (plans?.data?.active !== 'free' && plans?.data?.active !== 'Trial')
                                                    ? 
                                                        (plan?.name === 'free' || plan?.name === 'cancelled')
                                                            ? 'Buy Plan'
                                                        : 'Update Plan'
                                                    : 'Subscribe Now'
                                                } 
                                                selected={selected}
                                                disableBuy={disableBuy}
                                                isYearly={isYearly}
                                                onBuy={buyNow}
                                                activePlan={activePlan}
                                                {...plan} 
                                            />
                                            <Tooltip place='top' anchorSelect='.highlight.tooltip'/>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    );
}

const PlanUsageView = ({ planUsage, type }) => {

    return <div className='mt-2'>
        <Row>
            {planUsage.emailSendLimit.limit !== 0 && <Col md={6}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='teal'>
                            <UilAt size={25} />
                            </div>
                        </div>
                        <div className='title'>Email Sends</div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.emailSendLimit.used)}/{formatNumber(planUsage.emailSendLimit.limit)}
                            </div>
                            {planUsage.emailSendLimit.limit >= 1000 && <div className='usageInDetails'>
                            {planUsage.emailSendLimit.used} of {planUsage.emailSendLimit.limit}
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.contactsLimit.used / planUsage.contactsLimit.limit) * 100}%`, backgroundColor: '#1BE1C9' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
            {planUsage.emailVerifyLimit?.limit !== 0 && <Col md={6}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='success'>
                                <UilEnvelopeCheck size={25} />
                            </div>
                        </div>
                        <div className='title'>
                            Verify Emails
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.emailVerifyLimit?.used)}/{formatNumber(planUsage.emailVerifyLimit?.limit)}
                            </div>
                            {planUsage.emailVerifyLimit?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.emailVerifyLimit?.used} of {planUsage.emailVerifyLimit?.limit}
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.emailVerifyLimit?.used / planUsage.emailVerifyLimit?.limit) * 100}%`, backgroundColor: '#3AC569' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
        </Row>
        <div className='csCard csCenter' style={{ height: 60 }}>
            BlinkGPT AI Features
        </div>
        <Row>
            {planUsage.chatGPTSequence?.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='primary'>
                                <img src={BlinkGPT} height={25} width={25} className='flex m-auto'/>
                            </div>
                        </div>
                        <div className='title'>
                            Assisted Sequences
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.chatGPTSequence?.used)}/{formatNumber(planUsage.chatGPTSequence?.limit)}
                            </div>
                            {planUsage.chatGPTSequence?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.chatGPTSequence?.used} of {planUsage.chatGPTSequence?.limit}
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.chatGPTSequence?.used / planUsage.chatGPTSequence?.limit) * 100}%`, backgroundColor: '#ED317F' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
            {planUsage.personalizedEmail?.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='primary'>
                                <img src={BlinkGPT} height={25} width={25} className='flex m-auto'/>
                            </div>
                        </div>
                        <div className='title'>
                            Personalized Sequences
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.personalizedEmail?.used)}/{formatNumber(planUsage.personalizedEmail?.limit)}
                            </div>
                            {planUsage.personalizedEmail?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.personalizedEmail?.used} of {planUsage.personalizedEmail?.limit}
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.personalizedEmail?.used / planUsage.personalizedEmail?.limit) * 100}%`, backgroundColor: '#ED317F' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
            {planUsage.chatGPTEmailTemplate?.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='primary'>
                                <img src={BlinkGPT} height={25} width={25} className='flex m-auto'/>
                            </div>
                        </div>
                        <div className='title'>
                            Email Templates
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.chatGPTEmailTemplate?.used)}/{formatNumber(planUsage.chatGPTEmailTemplate?.limit)}
                            </div>
                            {planUsage.chatGPTEmailTemplate?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.chatGPTEmailTemplate?.used} of {planUsage.chatGPTEmailTemplate?.limit}
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.chatGPTEmailTemplate?.used / planUsage.chatGPTEmailTemplate?.limit) * 100}%`, backgroundColor: '#ED317F' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
        </Row>
        <div className='csCard csCenter' style={{ height: 60 }}>
            Other
        </div>
        <Row>
            {planUsage.userCount.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='warning'>
                                <UilUsersAlt size={25} />
                            </div>
                        </div>
                        <div className='title'>
                            Users
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.userCount?.used)}/{planUsage.userCount?.limit >= 100 ? "∞" : planUsage.userCount?.limit}
                            </div>
                            {planUsage.userCount?.limit >= 100 && <div className='usageInDetails'>
                                {planUsage.userCount?.used} of Unlimited
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.userCount?.used / planUsage.userCount?.limit) * 100}%`, backgroundColor: '#F9C00C' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
            {planUsage.senderCount.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='purple'>
                                <UilEnvelopes size={25} />
                            </div>
                        </div>
                        <div className='title'>
                            Email Sender
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.senderCount?.used)}/∞
                            </div>
                            {planUsage.senderCount?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.senderCount?.used} of Unlimited
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.senderCount?.used / planUsage.senderCount?.limit) * 100}%`, backgroundColor: '#8929E0' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
            {planUsage.warmUpCount?.limit !== 0 && <Col md={4}>
                <div className='csCard'>
                    <div className='details'>
                        <div className='cardIcon'>
                            <div className='orange'>
                                <UilFire size={25} />
                            </div>
                        </div>
                        <div className='title'>
                            Email WarmUp
                        </div>
                        <div className='usageNumber'>
                            <div className='usageInShort'>
                                {formatNumber(planUsage.warmUpCount?.used)}/∞
                            </div>
                            {planUsage.warmUpCount?.limit >= 1000 && <div className='usageInDetails'>
                                {planUsage.warmUpCount?.used} of Unlimited
                            </div>}
                        </div>
                    </div>
                    <div className='usageProgressBar'>
                        <div className='csProgressBar' style={{ width: '98%', marginTop: 10 }}>
                            <div style={{ width: `${(planUsage.warmUpCount?.used / planUsage.warmUpCount?.limit) * 100}%`, backgroundColor: '#FC9949' }}></div>
                        </div>
                    </div>
                </div>
            </Col>}
        </Row>
    </div>
}