import {
  UilAnalysis,
  UilAnalytics,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilSync,
  UilRocket,
  UilTimes,
  UilEdit,
  UilEnvelopeOpen,
  UilExclamationTriangle,
  UilLink,
  UilEnvelopeReceive,
  UilCalendarAlt,
  UilSearch,
  UilListUl,
  UilQuestionCircle,
  UilPen,
  UilHistory,
  UilArrowBreak,
  UilInfoCircle
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomDialog from 'components/custom/customDialog';
import {
  AuthContext,
  Checkbox,
  CustomSubNav,
  Dropdown,
  Loader,
  PageTitle,
  Radio,
  TextInput,
  WhitelabelContext,
} from 'components/lib';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import timezones from '../../assets/data/timezone';
import { FlowchartProvider, useFlowchartData } from './FlowchartContext';
import AddNodeDialog, { RemoveNodeDialog } from './addNodeDialog';
import Flowchart from './flowchart';
import FlowChartReport from './flowchartReport';
import { PixieProvider } from './pixieContext';
import { PixieEditor } from './pixieEditor';
import CustomButton from 'components/custom/customButton';
import AsyncSelect from 'react-select/async';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import RCSelect from 'react-select';
import languagesList from 'data/languages';
import BlinkGPT from '../../assets/BlinkGPT-filled.png';
import { Helmet } from 'react-helmet';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';
import { options } from '@fullcalendar/core/preact';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const times = [
  { value: '00:00', label: '00:00 AM' },
  { value: '01:00', label: '01:00 AM' },
  { value: '02:00', label: '02:00 AM' },
  { value: '03:00', label: '03:00 AM' },
  { value: '04:00', label: '04:00 AM' },
  { value: '05:00', label: '05:00 AM' },
  { value: '06:00', label: '06:00 AM' },
  { value: '07:00', label: '07:00 AM' },
  { value: '08:00', label: '08:00 AM' },
  { value: '09:00', label: '09:00 AM' },
  { value: '10:00', label: '10:00 AM' },
  { value: '11:00', label: '11:00 AM' },
  { value: '12:00', label: '12:00 PM' },
  { value: '13:00', label: '01:00 PM' },
  { value: '14:00', label: '02:00 PM' },
  { value: '15:00', label: '03:00 PM' },
  { value: '16:00', label: '04:00 PM' },
  { value: '17:00', label: '05:00 PM' },
  { value: '18:00', label: '06:00 PM' },
  { value: '19:00', label: '07:00 PM' },
  { value: '20:00', label: '08:00 PM' },
  { value: '21:00', label: '09:00 PM' },
  { value: '22:00', label: '10:00 PM' },
  { value: '23:00', label: '11:00 PM' },
  { value: '23:59', label: '11:59 PM' },
];

export default function EditSequence() {
  let { id } = useParams();
  const { whitelabelDetails } = useContext(WhitelabelContext);

  return (
    <FlowchartProvider>
      <Helmet>
        <title>{`Edit Sequence | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      {id ? <FlowChartReport /> : <EditSequenceView />}
    </FlowchartProvider>
  );
}

function SelectTime({ defaultValue, onChange }) {
  return (
    <select
      className="form-select appearance-none
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      onChange={(e) => onChange(e.target.value)}
    >
      {times.map((time) => (
        <option
          value={time.value}
          selected={defaultValue === time.value && true}
        >
          {time.label}
        </option>
      ))}
    </select>
  );
}

function TimeTable(props) {
  const [days, setDays] = useState([
    {
      enabled: true,
      name: 'Monday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: true,
      name: 'Tuesday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: true,
      name: 'Wednesday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: true,
      name: 'Thursday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: true,
      name: 'Friday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: false,
      name: 'Saturday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
    {
      enabled: false,
      name: 'Sunday',
      fromTime: '09:00',
      toTime: '17:00',
      estimate: '',
    },
  ]);
  const [randomDelayEnabled, setRandomDelayEnabled] = useState(true);
  const [delayFrom, setDelayFrom] = useState(10);
  const [delayTo, setDelayTo] = useState(20);
  const [changedTime, setChangedTime] = useState(1);

  useEffect(() => {
    // console.log("WKH ", props.workingHours)
    if (props.emailSendingHours && props.emailSendingHours.length > 0) setDays(props.emailSendingHours);
  }, [props.emailSendingHours]);

  function calculateEstimate(day) {
    // Get Range
    let startTime = parseInt(day.fromTime.split(':')[0]);
    let endTime = parseInt(day.toTime.split(':')[0]);
    let minutes = Math.abs(startTime - endTime) * 60;

    if (startTime > endTime) return '0-0';

    if (!randomDelayEnabled) {
      return `${minutes} - ${minutes*2}`;
    }

    let fromEstimate = delayFrom === 0 ? '' : Math.round(minutes / delayFrom);
    let toEstimate = delayTo === 0 ? '' : Math.round(minutes / delayTo);

    return `${toEstimate} - ${fromEstimate}`;
  }

  useEffect(() => {
    setRandomDelayEnabled(props.delayEnabled);
  }, [props.delayEnabled]);

  useEffect(() => {
    if (props.delayFrom) setDelayFrom(props.delayFrom);
  }, [props.delayFrom]);

  useEffect(() => {
    if (props.delayTo) setDelayTo(props.delayTo);
  }, [props.delayTo]);

  useEffect(() => {
    setDays((previousDays) => {
      const newDays = previousDays.map(day => {
        day.estimate = calculateEstimate(day);
        return day;
      });

      props.onChange('workinghours', newDays);

      return newDays;
    });
  }, [randomDelayEnabled, delayFrom, delayTo, changedTime]);

  function updateSelectedTime(dayName, key, value) {
    let newDays = days.map((day) => {
      if (day.name === dayName) {
        day[key] = value;
      }
      return day;
    });

    setDays(newDays);
    props.onChange('workinghours', newDays);
    setChangedTime(changedTime + 1);
  }

  return (
    <div>
      <div
        style={{ marginLeft: 11, marginBottom: 5, marginTop: 10 }}
        className="form-check"
      >
        <Checkbox
          name="randomDelay"
          onChange={(i, checked, option) => {
            props.onChange('delayEnabled', !randomDelayEnabled);
          }}
          checked={randomDelayEnabled}
          option={<div className="flex">
            Add Random Delays
            <UilQuestionCircle
              size={18}
              id={"random-delay"}
              style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
            />
          </div>}
        />
        <Tooltip anchorSelect={`#random-delay`} place={"top"}>
          Add random delays between emails that are sent from this email address for risk-free email deliverability.
        </Tooltip>
      </div>

      {randomDelayEnabled && (
        <div className="workingHourDelay">
          <div className="workingHourDelayTime">
            <div>FROM (minutes)</div>
            <input
              className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="number"
              min={1}
              value={delayFrom}
              onChange={(e) => {
                const value = e.target.value;
                const isValidInput = /^\d+$/.test(value);
                if (isValidInput) {
                  props.onChange('delayFrom', value);
                }
              }}
              placeholder="2"
            />
          </div>
          <div className="workingHourDelayTime">
            <div>TO (minutes)</div>
            <input
              className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="number"
              min={1}
              value={delayTo}
              onChange={(e) => {
                const value = e.target.value;
                const isValidInput = /^\d+$/.test(value);
                if (isValidInput) {
                  props.onChange('delayTo', value);
                }
              }}
              placeholder="5"
            />
          </div>
        </div>
      )}
      {!randomDelayEnabled && (<div className='flex gap-2 p-2 mt-2 rounded-md text-slate-500 ml-2' style={{ border: '1px solid #91baf7', backgroundColor: '#f3f6ff' }}><UilInfoCircle style={{ flexShrink: 0 }} />We add 30-60 second delay between emails sent from same email address to add human touch & enhance deliverability.</div>)}

      <Tooltip anchorSelect={`#approx-email`} place={"top"}>
        Approx. no. of  emails that will be sent based on random delays.
      </Tooltip>
      <label style={{ marginTop: 10, marginLeft: 10, fontWeight: 'bold' }} className='flex items-center relative'>
        <span>Configure Sending Hours & Days</span>
        <UilQuestionCircle
          size={18}
          id={"configure-sequence"}
          style={{ marginLeft: 5, cursor: 'pointer' }}
        />
      </label>
      <Tooltip anchorSelect={`#configure-sequence`} place={"top"}>
        Select the days and duration on which the emails should be sent once launched.
      </Tooltip>
      <table className="workingHoursTable">
        <thead className='font-semibold'>
          <tr>
            <td>Enabled</td>
            <td>Day</td>
            <td>From</td>
            <td>Till</td>
            {randomDelayEnabled && <td className='flex gap-2'>Emails Sends / Day / Sender <UilInfoCircle id='approx-emails' /></td>}
            {/* {randomDelayEnabled && <td className='flex'>
              Approx. Emails
              <UilQuestionCircle
                size={18}
                id={"approx-email"}
                style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              />
            </td>} */}
            <Tooltip anchorSelect='#approx-emails' place='top'>Calculated based on Random Delays</Tooltip>
          </tr>
        </thead>
        {days.map((day) => (
          <tr>
            <td>
              <Checkbox
                name="dailyCheckbox"
                onChange={(i, checked, option) => {
                  setDays((oldDays) => {
                    let newDays = oldDays.map((oldDay) => {
                      if (oldDay.name === day.name) oldDay.enabled = !checked;
                      return oldDay;
                    });
                    props.onChange('workinghours', newDays);
                    return newDays;
                  });
                }}
                checked={day.enabled}
                option=""
              />
            </td>
            <td>
              <p>{day.name}</p>
            </td>
            <td style={{ minWidth: 120 }}>
              <SelectTime
                onChange={(val) =>
                  updateSelectedTime(day.name, 'fromTime', val)
                }
                defaultValue={day.fromTime}
              />
            </td>
            <td style={{ minWidth: 120 }}>
              <SelectTime
                onChange={(val) => updateSelectedTime(day.name, 'toTime', val)}
                defaultValue={day.toTime}
              />
            </td>
            {randomDelayEnabled && <td style={{ minWidth: 240 }}><p className='mt-4 px-3 py-1.5 rounded text-gray-700' style={{ backgroundColor: '#e9e9e9' }}>{day.estimate}</p></td>}
          </tr>
        ))}
      </table>
    </div>
  );
}

function SequenceSettingsDialog({
  sequence,
  userID,
  nodes,
  edges,
  hide,
  fetchSequence,
  showSettings,
  showPersonalizedSettings,
  setSequence
}) {
  const [senders, setSenders] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [timezoneList, setTimezoneList] = useState([]);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [bcc, setBcc] = useState('');
  const [selectedSenders, setSelectedSenders] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [selectTiming, setSelectTiming] = useState(true);
  const [emailSendingHours, setEmailSendingHours] = useState(null);
  const [delayEnabled, setDelayEnabled] = useState(false);
  const [delayFrom, setDelayFrom] = useState(2);
  const [delayTo, setDelayTo] = useState(5);
  const [loading, setLoading] = useState(false);
  const [loadingSequence, setLoadingSequence] = useState(false);

  const [sendToOnlyVerifiedEmail, setSendToOnlyVerifiedEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [riskyEmail, setRiskyEmail] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [checkEmailOpen, setCheckEmailOpen] = useState(false);
  const [checkEmailClick, setCheckEmailClick] = useState(false);
  const [checkEmailReply, setCheckEmailReply] = useState(false);
  const [autoTagReplies, setAutoTagReplies] = useState(false);

  const [checkEmailBeforeSending, setCheckEmailBeforeSending] = useState(false);
  const [stopWhenReplyRecieved, setStopWhenReplyRecieved] = useState(false);
  const [stopWhenReplyRecievedWhen, setStopWhenReplyRecievedWhen] = useState('contact');
  const [matchProvider, setMatchProvider] = useState(false);
  const [plainText, setPlainText] = useState(false);
  const [evergreen, setEvergreen] = useState(false);
  const [folders, setFolders] = useState([]);

  const [contactCount, setContactCount] = useState(0);
  const [integration, setIntegration] = useState('');
  const [contactLoading, setContactLoading] = useState(false);
  const [contactLoadingError, setContactLoadingError] = useState(null);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [name, setName] = useState(sequence?.aiPersonalizationData?.name);
  const [companyName, setCompanyName] = useState(sequence?.aiPersonalizationData?.business_name);
  const [offer, setOffer] = useState(sequence?.aiPersonalizationData?.offer);
  const [selectedLanguage,setSelectedLanguage] = useState(sequence?.aiPersonalizationData?.language);
  const deviceWidth = useWindowResize();

  let navigator = useNavigate();
  const [ownedBy, setOwnedBy] = useState('');
  const authContext = useContext(AuthContext);

  const fetchFolders = async () => {
    try {
      let url = '/api/lists/folders?type=email-sender';
      let result = await Axios.get(url);
      let fetchedFolders = result?.data?.data ?? [];
      fetchedFolders = fetchedFolders.map((folder) => ({
        key: folder.id,
        value: folder.id,
        label: folder.name,
        serviceName: 'folder'
      }));
      setFolders(fetchedFolders);
    } catch (err) {
      console.log('Error in getting data ', err);
    }
  }

  useEffect(() => {
    setOwnedBy(authContext.user.id);
    fetchFolders();
  }, []);

  useEffect(() => {
    fetchTimezoneLists('')
    if(ownedBy && ownedBy.length>0) reloadSenders('');
  }, [ownedBy])

  useEffect(() => {
    if (sequence) {
      if (sequence.scheduledAt) setShowSummary(true);
      // setScheduledTime(sequence.scheduledAt);
      if(sequence.scheduledAt) {
        setScheduledTime(DateTime.fromMillis(sequence.scheduledAt).toJSDate())
        setSelectedDate(DateTime.fromMillis(sequence.scheduledAt).toJSDate())
        setSelectedTime(DateTime.fromMillis(sequence.scheduledAt).toJSDate())
      } else {
        setScheduledTime(DateTime.now().plus({ minutes: 10 }).toJSDate())
        setSelectedDate(DateTime.now().plus({ minutes: 10 }).toJSDate())
        setSelectedTime(DateTime.now().plus({ minutes: 10 }).toJSDate())
      }
      setBcc(sequence.bcc);
      if (sequence.timezone) {
        if(sequence.timezone.includes('/')) setTimezone(sequence.timezone)
        else setTimezone(timezones.find(t => t.id === sequence.timezone)?.utc[0]);
      } else setTimezone(getCurrentUsersTimezone());

      setEmailSendingHours(sequence.emailSendingHours);
      setDelayEnabled(sequence.delayEnabled);
      setDelayFrom(sequence.delayFrom);
      setDelayTo(sequence.delayTo);

      setSendToOnlyVerifiedEmail(sequence.sendToOnlyVerifiedEmail);
      setValidEmail(sequence.validEmail);
      setRiskyEmail(sequence.riskyEmail);
      setInvalidEmail(sequence.invalidEmail);

      setCheckEmailOpen(sequence.checkEmailOpen);
      setCheckEmailClick(sequence.checkEmailClick);
      setCheckEmailReply(sequence.checkEmailReply);
      setAutoTagReplies(sequence.autoTagReplies);

      if (sequence.checkEmailBeforeSending) setCheckEmailBeforeSending(sequence.checkEmailBeforeSending);
      if (sequence.stopWhenReplyRecieved) setStopWhenReplyRecieved(sequence.stopWhenReplyRecieved);
      if (sequence.stopWhenReplyRecievedWhen) setStopWhenReplyRecievedWhen(sequence.stopWhenReplyRecievedWhen);
      if (sequence?.plainText) setPlainText(sequence?.plainText);
      if (sequence?.evergreen) setEvergreen(sequence?.evergreen);
      if (sequence?.emailSenders?.length > 0) setSelectedSenders(sequence?.emailSenders);
      else if (sequence.senders && ownedBy && ownedBy.length>0) {
        fetchSenders(sequence.senders?.split(',') || []);}
      }
  }, [sequence, ownedBy]);

  useEffect(() => {
    if (showSettings) fetchContactCount();
  }, [showSettings]);

  useEffect(() => {
    let dataValue = selectedDate ? new Date(selectedDate) : new Date()
    let timeValue = selectedTime ? new Date(selectedTime) : new Date()
    let newDate = DateTime.fromJSDate(dataValue).toFormat('MM-dd-yyyy');
    let newTime = DateTime.fromJSDate(timeValue).toFormat('hh:mm a');
    
    // Split the time into hours, minutes, and period (AM/PM)
    const [hours, minutes, period] = newTime.split(/:| /);

    // Create a new Date object with the combined date and time values
    const combinedDateTime = new Date(newDate);
    combinedDateTime.setHours(period === 'AM' ? Number(hours) % 12 : Number(hours) % 12 + 12);
    combinedDateTime.setMinutes(Number(minutes));
    combinedDateTime.setSeconds(0);

    setScheduledTime(combinedDateTime)
  }, [selectedDate, selectedTime])

  const fetchTimezoneLists = async (inputValue) => {
    try {
      let result = await Axios.get(
        `/api/public/timezone?search=${inputValue}`
      );
      const data = result?.data?.data;
      setTimezoneList(data);
      return data;
    } catch (err) {
      console.log('Error in Fetching lists ', err);
      return [];
    }
  };

  async function reloadSenders(inputValue) {
    try {
      const folderMatches = folders?.length > 0 ? folders.filter((folder) =>
        folder.label.toLowerCase().includes(inputValue.toLowerCase())
      ) : [];
      let result ;
      if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
        result = await Axios.get(`/api/senders?limit=100&skip=0&search=${inputValue}&ninids=${selectedSenders.length > 0 ? selectedSenders.map(s => s.value).join(',') : ''}`);
      }else {
        result = await Axios.get(`/api/senders?limit=100&skip=0&ownedby=${ownedBy}&search=${inputValue}&ninids=${selectedSenders.length > 0 ? selectedSenders.map(s => s.value).join(',') : ''}`);
      }
      if (result?.data?.success) {
        let senderList = result.data.data.map((sender) => ({
          key: sender.id,
          label: `${
            sender.alias ||
            sender.microsoft_email ||
            sender.google_email ||
            sender.smtpImapEmail
          }`,
          value: sender.id,
          serviceName: sender.serviceName
        }))
        setSenders(senderList);
        const combinedList = [
          {
            label: "Folders",
            options: folderMatches.slice(0, 3)
          },
          {
            label: "Emails",
            options: senderList.slice(0, 5)
          }
        ]
        return combinedList;
      }

      return [{ label: 'Folders', options: folderMatches.slice(0, 5) }];
    } catch (err) {
      return [];
    }
  }

  const fetchSenders = async (selectedList) => {
    if (!sequence.user_id) return;
    let senders;
    if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
      senders = await Axios.get(`/api/senders?limit=100&ids=${selectedList.join(',')}`);   
    }else {
      senders = await Axios.get(`/api/senders?limit=100&ids=${selectedList.join(',')}&ownedby=${ownedBy}`);
    }
    if (senders?.data?.success) {
      let sndr = senders.data.data;
      let selectedSndr = [];
      for (let i = 0; i < sndr.length; i++) {
        if (selectedList.find((s) => s === sndr[i].id)) {
          selectedSndr.push({
            key: sndr[i].id,
            label: `${
              sndr[i].alias ||
              sndr[i].microsoft_email ||
              sndr[i].google_email ||
              sndr[i].smtpImapEmail
            }`,
            value: sndr[i].id,
          });
        }
      }
      setSelectedSenders(selectedSndr);
    }
  };

  const fetchContactCount = async () => {
    setContactLoading(true);
    try {
      let result = await Axios.post(
        `/api/sequences/${sequence.id}/contact-count`,
        {
          flowchart: {
            nodes: nodes,
            edges: edges,
          },
        }
      );

      setContactCount(result.data.data);
      setIntegration(result.data.integration);
      setContactLoading(false);
    } catch (err) {
      setContactLoadingError('Unable to fetch contacts count.');
      setContactLoading(false);
    }
  };

  const updateSequence = async () => {
    setLoadingSequence(true);
    try {
      await Axios.put(`/api/sequences/${sequence?.id}`, {
        flowchart: {
          nodes: nodes,
          edges: edges,
        },
        bcc: bcc,
        timezone,
        scheduledAt: DateTime.fromJSDate(new Date(scheduledTime)).toMillis(),
        sendToOnlyVerifiedEmail,
        validEmail,
        riskyEmail,
        invalidEmail,
        checkEmailOpen,
        checkEmailClick,
        checkEmailReply,
        autoTagReplies,
        checkEmailBeforeSending,
        stopWhenReplyRecieved,
        plainText,
        evergreen, 
        stopWhenReplyRecievedWhen,
        matchProvider,
        emailSendingHours,
        delayEnabled,
        delayFrom,
        delayTo,
        paused: false,
        emailSenders: selectedSenders
      });
      Toast.fire({ icon: 'success', title: 'Sequence saved successfully.' });
      // fetchSequence()
      // hide()
      setTimeout(() => {
        if (checkEmailBeforeSending) navigator(`/outreach/outbox?sequenceID=${sequence?.id}&team=${sequence?.user_id}`)
        else navigator(`/outreach/reports?sequence=${sequence?.id}`);
        setLoadingSequence(false)
      },20000)
    } catch (err) {
      hide();
      console.log('Error in updating sequence ', err);
      setLoadingSequence(false)
      if (err?.response?.data?.message === 'No senders found!') await fetchSequence();
      Swal.fire({ 
        icon: 'error', 
        title: err?.response?.data?.message || 'Could not save the sequence!' ,
        html: err?.response?.data?.message === 'No senders found!' ? '<h5>Either add a sender or make sure folders have senders.</h5>' : '<h5>Something went wrong while saving the sequence.</h5>'
      });
    }
  };

  const updateSequenceGPTSettings = async () => {
      
      if(name.trim() === "") return Toast.fire({ icon: 'error', title: 'Please provide your name' });
      if(companyName.trim() === "") return Toast.fire({ icon: 'error', title: 'Please provide company name' });
      if(offer.trim() === "") return Toast.fire({ icon: 'error', title: 'Please provide what you are selling' });
      if(selectedLanguage.trim() === "") return Toast.fire({ icon: 'error', title: 'Please select language' });

      setLoadingSequence(true);
      
      try {
        await Axios.put(`/api/sequences/${sequence?.id}`, {
          aiPersonalizationData:{
            name,
            business_name : companyName,
            offer,
            language:selectedLanguage
          }
        });
        Toast.fire({ icon: 'success', title: 'Sequence saved successfully.' });
        setSequence((oldSequence) => ({
          ...oldSequence, 
          aiPersonalizationData:{
            ...oldSequence.aiPersonalizationData,
            name,
            business_name : companyName,
            offer,
            language:selectedLanguage
          }}))
        hide();
        
      } catch (err) {
        hide();
        Toast.fire({ icon: 'error', title: 'Unable to update settings' });
        setLoadingSequence(false)
      }
  }

  const schedule = () => {
    if (validate()) {
      setShowSummary(true);
      if (showSummary) {
        updateSequence();
      }
    }
  };

  const validate = () => {
    if(!timezone || timezone === "") return Swal.fire({
      icon: 'warning',
      title: ``,
      html: `<h5>Please select a timezone!</h5>`,
      showCancelButton: true,
      confirmButtonText: `Ok`,
      confirmButtonColor: '#0066ff',
    });

    let message = '';
    let currentUTC = DateTime.utc().toMillis()
    let currentTime = DateTime.fromMillis(currentUTC).setZone(timezone).toMillis();
    let scheduledTimeUTC = DateTime.fromJSDate(scheduledTime).toMillis()

    // console.log("TIME ", currentUTC, timezone, currentTime, scheduledTimeUTC)
    if (currentTime > scheduledTimeUTC) message = 'Please select a time in future to Schedule.';
    else if (selectedSenders.length === 0) message = 'At least one Email Sender is required.';
    else if (stopWhenReplyRecieved && stopWhenReplyRecievedWhen === "") message = 'Please select an option for reply recieved!';
    
    let count = 0
    if(emailSendingHours) {
      for(let emailSendingHour of emailSendingHours) {
        if(emailSendingHour.enabled) {
          let start = emailSendingHour.fromTime.split(':')[0];
          let end = emailSendingHour.toTime.split(':')[0];
          if(Number(start) > Number(end)) message = `"Till" time should be more than "From" time on ${emailSendingHour.name}`
          count += 1
        }
      }
      if(count === 0) message = `At least one day for sending should be selected.`
    }

    if (message) {
      Swal.fire({
        icon: 'warning',
        title: ``,
        html: `<h5>${message}</h5>`,
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    } else {
      return true;
    }
  };

  // returns current user's timezone if found or else the first timezone in the timezones data
  function getCurrentUsersTimezone() {
    const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return country
  }

  function checkTimezoneValueView() {
    if(!timezone) return null;
    if(timezone.includes('/')) return timezone
    let timezoneInfo = timezones.find(t => t.id === timezone)
    if(!timezoneInfo) return null;
    return timezoneInfo.utc[0]
  }

  const handleChange = (name, value) => {
    switch (name) {
      case 'workinghours':
        return setEmailSendingHours(value);
      case 'delayEnabled':
        return setDelayEnabled(value);
      case 'delayFrom':
        return setDelayFrom(value);
      case 'delayTo':
        return setDelayTo(value);
    }
  };

  function checkOutlookSender() {
    if(delayEnabled) {
      if(selectedSenders.find(s => s.serviceName === "Outlook")) {
        if(delayFrom < 5) return true
        else return false
      }
    }
    if(selectedSenders.find(s => s.serviceName === "Outlook")) return true;
    return false;
  }

  return (
    <CustomDialog scroll={true} scrollX={true}>
      {loadingSequence && <div
        className="csFormLoader"
        style={{ background: `rgba(255, 255, 255, 0.5)` }}
      >
        <Loader>
          <div style={{ fontWeight: 'bold' }}>
            {!showPersonalizedSettings && "🚀 Scheduling your Sequence 🚀"}
          </div>
        </Loader>
      </div>}
      {  <div className='sequenceSettingsDialog'>
        <div style={{ display: 'flex', borderBottom: '1px solid #d7d7d7', paddingBottom: (showSummary && checkEmailBeforeSending && !showPersonalizedSettings) ? 0 : 15 }}>
          <div>
            <h1 className='dialogTitle'>
              {showPersonalizedSettings ?<div className='flex flex-col pb-0'><h1 className='dialogTitle'>BlinkGPT AI Settings</h1><p className='text-modal-description pl-0 pb-0'>Configure your BlinkGPT AI Settings for the personalized sequence.</p></div> : showSummary === true ? 'Sequence Summary' :'Sequence Settings'} 
            </h1>
            {!showPersonalizedSettings && showSummary && checkEmailBeforeSending && <p className='flex csBadge warning cursor-pointer' style={{ marginRight: 10 }} onClick={() => setShowSummary(false)}>
              <UilExclamationTriangle size={16} style={{ marginTop: 3, marginRight: 5 }} />
              <span>
                You have selected to review emails before sending, No emails will be sent unless you approve them from outbox.
              </span>
            </p>}
          </div>
          <div className='ml-auto'>
            <button
              onClick={() => hide()}
              className='customButton secondary red'
              style={{ 
                padding: 3,
                marginLeft: 'auto',
              }}
            >
              <UilTimes size={24} />
            </button>
          </div>
        </div>
        
       {!showPersonalizedSettings && (showSummary === true ? 
          <div style={{ paddingTop: 10 }} className='showSummary'>
            {!contactLoading && (
              <div style={{ marginTop: 10 }}>
                {contactLoadingError ? (
                  <div style={{ display: 'flex' }}>
                    Error: {contactLoadingError}
                  </div>
                ) : integration === '' ? (
                  <h2>
                    Total Leads: <span style={{ marginLeft: 5 }}>{contactCount}</span>
                  </h2>
                ) : (
                  <h2>
                    Leads From {integration}
                  </h2>
                )}
              </div>
            )}
            {contactLoading && (
              <div style={{ display: 'flex' }}>
                <UilSync
                  style={{ marginTop: 8, cursor: `not-allowed` }}
                  className={`rotate`}
                  size={20}
                />{' '}
                <span style={{ marginTop: 5, marginLeft: 5 }}>
                  Fetching contact count
                </span>
              </div>
            )}

            <h2 style={{ marginTop: 10 }}> Scheduled For <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2>

            <div style={{ marginTop: 5 }}>
              {scheduledTime &&
                DateTime.fromJSDate(scheduledTime)
                  // .toUTC()
                  .toFormat('dd-MMM-yyyy hh:mm a')}{' '}
              {timezone ? (
                <span>({checkTimezoneValueView()})</span>
              ) : null}
              <br />
            </div>

            {selectedSenders && <h2 style={{ marginTop: 10 }}> {selectedSenders.length > 1 ? "Rotating Email Senders" : "Email Senders"} <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2>}
            {selectedSenders && <div style={{maxHeight: 400, overflow:"auto"}}  className='senderView'>
              {selectedSenders.map((selectedSender) => (
                <div>{selectedSender.label}</div>
              ))}
            </div>}

            {bcc && <h2 style={{ marginTop: 10 }}> BCC <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2>}

            {bcc && <TextInput
              value={bcc}
              name="bcc"
              style={{ width: 390, backgroundColor: '#f2f2f2', marginTop: 5 }}
            />}

            <h2 style={{ marginTop: 10 }}> Sending to <text style={{ marginLeft: 5, color: '#03A6FF' }}>{sendToOnlyVerifiedEmail?"Verified Emails Only":"All Emails"}</text> <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2>

            {stopWhenReplyRecieved && <h2 style={{ marginTop: 10 }}> Stop Sequence for Lead <text style={{ marginLeft: 5, color: '#03A6FF' }}>when they reply.</text> <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2>}

            {(checkEmailOpen || checkEmailClick || checkEmailReply) ? <h2 style={{ marginTop: 10 }}> Tracking <UilEdit size={20} onClick={() => setShowSummary(false)} /> </h2> : null}
            
            {(checkEmailOpen || checkEmailClick || checkEmailReply) ? <div style={{ display: 'flex', marginTop: 5 }}>
              {checkEmailOpen && <div className='trackingBox' style={{ marginRight: 15 }}>
                <UilEnvelopeOpen size={40} />
                <div>Opens</div>  
              </div>}
              {checkEmailClick && <div className='trackingBox' style={{ marginRight: 15 }}>
                <UilLink size={40} />
                <div>Clicks</div>  
              </div>}
              {checkEmailReply && <div className='trackingBox'>
                <UilEnvelopeReceive size={40} />
                <div>Replies</div>  
              </div>}
            </div> : null}
          </div>
        :
        selectTiming ? 
          <div className='showEdit' style={{ paddingBottom: 20 }}>
            <div id='schedule-header' style={{ display: 'flex', marginTop: 10 }}>
              <div>
                <label>
                  <b>Launch on - Date</b>
                </label>
                <div className='custom-datetime-picker only-date'>
                  <UilSearch style={{ marginTop: 5, marginRight: 5 }} size={21} />
                  <DateTimePicker
                    onChange={(e) => {
                      setSelectedDate(e)
                    }}
                    value={selectedDate || new Date()}
                    format="MM-dd-yyyy"
                    disableClock={true}
                    calendarIcon={<UilCalendarAlt size={19} />}
                    clearIcon={<UilTimes color="#E53A40" />}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                <label>
                  <b>Time</b>
                </label>
                <br/>
                <div className='custom-datetime-picker' style={{ width: 170 }}>
                  <UilSearch style={{ marginTop: 5, marginRight: 5 }} size={21} />
                  <DateTimePicker
                    value={selectedTime || new Date()}
                    onChange={(e) => {
                      setSelectedTime(e);
                    }}
                    format="hh:mm a"
                    disableClock={true}
                    clearIcon={<UilTimes color="#E53A40" />}
                    disableCalendar={true}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                <label>Timezone</label>
                <div style={{ marginTop: 10 }}>
                  <AsyncSelect
                    styles={{
                      control: () => ({
                        padding: '3px 1px',
                        minWidth: 250,
                        width: "100%",
                        maxWidth: 500,
                        borderRadius: 5,
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        border: '1px solid #D7D7D7',
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                      }),
                    }}
                    className="bg-gray-100 hover:bg-gray-200 mb-2 mr-2 text-gray-800 rounded-full inline-flex"
                    placeholder="Select a timezone"
                    defaultOptions={timezoneList}
                    value={(function () {
                      if(!timezone) return null;
                      if(timezone.includes('/')) return { label: timezone, value: timezone }
                      let timezoneInfo = timezones.find(t => t.id === timezone)
                      if(!timezoneInfo) return null;
                      return { label: timezoneInfo.utc[0], value: timezoneInfo.utc[0] }
                    })()}
                    menuPortalTarget={document.body}
                    loadOptions={fetchTimezoneLists}
                    onChange={(values, currentValue) => setTimezone(values.value)}
                    noOptionsMessage={() => 'No Results, Type to Search'}
                  />
                </div>
              </div>
            </div>
            <div>
              <TimeTable 
                onChange={(name, value) => handleChange(name, value)}
                emailSendingHours={emailSendingHours}
                delayEnabled={delayEnabled}
                delayFrom={delayFrom}
                delayTo={delayTo}
              />
            </div>
          </div>
        :
          <div className='showEdit' style={{ paddingBottom: 20 }}>
            <div>
              <label style={{ marginTop: 10, display: "flex" }}>
                Select Email Sender(s)
                <UilQuestionCircle size={18} style={{ marginLeft: 3, marginTop: 2, cursor: 'pointer' }} id="select-email-sender" />
              </label>
              <Tooltip anchorSelect="#select-email-sender" place="right">
                Pick multiple email senders to enable email sender rotation.
              </Tooltip>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <AsyncSelect
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={selectedSenders}
                  placeholder="Type to Search Email Senders & Folders"
                  styles={{
                    control: () => ({
                      padding: 5,
                      width: deviceWidth > 991 ? 566 : '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7',
                      maxHeight: 300,
                    }),
                    valueContainer: (other) => ({
                      ...other,
                      overflow: "auto",
                    })
                  }}           
                  onChange={(sendersL) => {
                    const deselected = selectedSenders.filter(
                      (sender) => !sendersL.find((selected) => selected.value === sender.value)
                    );
                    const updatedSenders = [...senders, ...deselected].filter(
                      (sender, index, self) => 
                        self.findIndex((s) => s.value === sender.value) === index
                    );
                    setSenders(updatedSenders);
                    setSelectedSenders(sendersL);
                  }}
                  loadOptions={reloadSenders}
                  defaultOptions={[
                    {
                      label: "Folders",
                      options: folders.slice(0, 3)
                    },
                    {
                      label: "Emails",
                      options: senders.slice(0, 5)
                    }
                  ]}
                  noOptionsMessage={() => 'Search for an Email Sender'}
                />
              </div>
              <a
                href="/outreach/email-senders"
                target="_blank"
              >
                Add New Email Sender
              </a>
            </div>
            {checkOutlookSender() && <div style={{ fontSize: 12 }}>Random delay from 5(minutes) will be added to outlook sender.</div>}

            <div style={{ marginTop: 10 }}>
              <div className='flex'>
              <label>BCC</label>
              <UilQuestionCircle
                size={18}
                id={"bcc"}
                style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              />
              </div>
              <Tooltip anchorSelect={`#bcc`} place={"right"}>
                Add your CRM BCC email to send all the outgoing emails to your CRM. 
              </Tooltip>
              <TextInput
                value={bcc}
                name="bcc"
                style={{ width: deviceWidth > 991 ? 566 : '100%', backgroundColor: '#fff', marginTop: 5 }}
                placeholder="crm@myCRM.com"
                onChange={(name, value) => setBcc(value)}
              />
            </div>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ cursor: 'pointer' }} className='flex' onClick={() => setEvergreen(!evergreen)}>
                <Checkbox
                  name="stopWhenReplyRecieved"
                  onChange={(i, checked, option) => {
                    console.log(!evergreen);
                  }}
                  checked={evergreen}
                  disabled={sequence.scheduledAt ? true : false}
                  id='disabledEvergreen'
                />
                {sequence.scheduledAt && <Tooltip anchorSelect='#disabledEvergreen'>Evergreen settings can not be updated once a sequence is scheduled.</Tooltip>}
                <div className='flex'>
                  <span>Make it Evergreen Sequence</span>
                  <UilQuestionCircle
                    size={18}
                    id={"evergreen"}
                    style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
                  />
                </div>
                <Tooltip anchorSelect={`#evergreen`} place={"right"}>
                  When enabled, sequence will keep running & you can add more leads to lists.
                </Tooltip>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ cursor: sequence.personalized ? 'auto' : 'pointer' }} className='flex' onClick={() => {
                if(!sequence.personalized) setCheckEmailBeforeSending(!checkEmailBeforeSending)
              }}>
                <Checkbox
                  name="checkEmailBeforeSchedule"
                  onChange={(i, checked, option) => {
                    console.log(!checkEmailBeforeSending);
                  }}
                  checked={checkEmailBeforeSending}
                  disabled={sequence.personalized}
                />
                  <div className='flex'>
                    <span>Review emails in Outbox before sending</span>
                    <UilQuestionCircle
                      size={18}
                      id={"review-emails"}
                      style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
                    />
                  </div>
                  <Tooltip anchorSelect={`#review-emails`} place={"right"}>
                    When checked, you will have to approve the emails in Outbox before they are sent. 
                  </Tooltip>
                </div>
            </div>

            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ cursor: 'pointer' }} className='flex' onClick={() => setStopWhenReplyRecieved(!stopWhenReplyRecieved)}>
                <Checkbox
                  name="stopWhenReplyRecieved"
                  onChange={(i, checked, option) => {
                    console.log(!stopWhenReplyRecieved);
                  }}
                  checked={stopWhenReplyRecieved}
                />
                <div className='flex'>
                  <span>Stop sequence for lead when they reply</span>
                  <UilQuestionCircle
                    size={18}
                    id={"stop-sequence"}
                    style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
                  />
                </div>
                <Tooltip anchorSelect={`#stop-sequence`} place={"right"}>
                  Stop the next step in sequence for particular leads when they reply to your emails.
                </Tooltip>
              </div>
            </div>
            {stopWhenReplyRecieved && (
              <div className='row-col-responsive' style={{ display: 'flex', marginTop: 5, paddingLeft: 10 }}>
                <div style={{ marginRight: 20, cursor: 'pointer' }} className='flex' onClick={() => setStopWhenReplyRecievedWhen('contact')}>
                  <Radio
                    name="stopWhenReplyRecievedWhen"
                    onChange={(i, checked, option) => console.log(stopWhenReplyRecievedWhen)}
                    checked={stopWhenReplyRecievedWhen === "contact"}
                  />
                  <span>For that lead only</span>
                </div>
                <div style={{ marginRight: 20, cursor: 'pointer' }} className='flex' onClick={() => setStopWhenReplyRecievedWhen('contact-with-same-domain')}>
                  <Radio
                    name="stopWhenReplyRecievedWhen"
                    onChange={(i, checked, option) => console.log(stopWhenReplyRecievedWhen)}
                    checked={stopWhenReplyRecievedWhen === "contact-with-same-domain"}
                    style={{ marginLeft: deviceWidth > 991 ? 10 : 0 }}
                  />
                  <span>For all leads with same domain</span>
                </div>
              </div>
            )}

            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ cursor: 'pointer' }} className='flex' onClick={() => setMatchProvider(!matchProvider)}>
                <Checkbox
                  name="matchProvider"
                  onChange={(i, checked, option) => {
                    console.log(!matchProvider);
                  }}
                  checked={matchProvider}
                />
                <div className='flex'>
                  <span>Enable Provider Matching</span>
                  <UilQuestionCircle
                    size={18}
                    id={"stop-sequence"}
                    style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
                  />
                </div>
                <Tooltip anchorSelect={`#stop-sequence`} place={"right"}>
                  Matches your lead's email provider with your email provider to boost deliverability
                </Tooltip>
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: 10 }}>
              <div style={{ cursor: 'pointer' }} className='flex' 
                onClick={() => {
                  if (!plainText) {
                    setCheckEmailOpen(false);
                    setCheckEmailClick(false);
                  }
                  setPlainText(!plainText);}
                }>
                <Checkbox
                  name="sendPlainText"
                  onChange={(i, checked, option) => {
                    console.log(!plainText);
                  }}
                  checked={plainText}
                />
                <div className='flex'>
                  <span>Boost your deliverability by sending emails in plain text, without HTML</span>
                  <UilQuestionCircle
                    size={18}
                    id={"plain-text"}
                    style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer', flexShrink: 0 }}
                  />
                </div>
                <Tooltip style={{ zIndex: 99 }} anchorSelect={`#plain-text`} place={"top"}>
                  When optimizing email delivery,<br /> Open Rate and Click Tracking will be disabled,<br /> and unsubscribe links, along with other HTML links,<br /> will be removed from the email.
                </Tooltip>
              </div>
            </div>
            <div className='row-col-responsive' style={{ marginTop: 10, display: 'flex' }}>
              <div className='flex' style={{ cursor: 'pointer' }} onClick={() => setSendToOnlyVerifiedEmail(false)}>
                <Radio
                  name="sendEmailToOnly"
                  checked={!sendToOnlyVerifiedEmail}
                  onChange={(i, checked, value) =>
                    setSendToOnlyVerifiedEmail(false)
                  }
                />
                <span>Schedule for All Leads</span>
              </div>
              <div className='flex' style={{ marginLeft: deviceWidth > 991 ? 20 : 0, cursor: 'pointer' }} onClick={() => setSendToOnlyVerifiedEmail(true)}>
                <Radio
                  name="sendEmailToOnly"
                  checked={sendToOnlyVerifiedEmail}
                  onChange={(i, checked, value) => setSendToOnlyVerifiedEmail(true)}
                />
                <span>Schedule for Leads with Verified Emails</span>
              </div>
            </div>
            {sendToOnlyVerifiedEmail && (
              <div className='row-col-responsive' style={{ display: 'flex', marginTop: 5, paddingLeft: 10, gap: deviceWidth > 991 ? 0 : 8 }}>
                <div style={{ marginRight: 20, cursor: 'pointer' }} className='flex' onClick={() => setValidEmail(!validEmail)}>
                  <Checkbox
                    name="sendEmailToOnly"
                    onChange={(i, checked, option) => console.log(!validEmail)}
                    checked={validEmail}
                  />
                  <span>Valid Email</span>
                </div>
                <div style={{ marginRight: 20, cursor: 'pointer' }} className='flex' onClick={() => setRiskyEmail(!riskyEmail)}>
                  <Checkbox
                    name="sendEmailToOnly"
                    onChange={(i, checked, option) => console.log(!riskyEmail)}
                    checked={riskyEmail}
                    style={{ marginLeft: deviceWidth > 991 ? 10 : 0 }}
                  />
                  <span>Risky Email</span>
                </div>
                <div style={{ cursor: 'pointer' }} className='flex' onClick={() => setInvalidEmail(!invalidEmail)}>
                  <Checkbox
                    name="sendEmailToOnly"
                    onChange={(i, checked, option) => console.log(!invalidEmail)}
                    checked={invalidEmail}
                  />
                  <span>Invalid Email</span>
                </div>
              </div>
            )}

            <div style={{ marginTop: 10 }} className='flex items-center'>
              <h2>Tracking </h2>
              <UilQuestionCircle
                size={20}
                id={"tracking-title"}
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </div>
            <Tooltip anchorSelect={`#tracking-title`} place={"right"}>
              Enable to track the emails performance to optimize your outreach.
            </Tooltip>
            <div className='row-col-responsive' style={{ display: 'flex', marginTop: 5, gap: deviceWidth > 991 ? 0 : 8 }}>
              <div style={{ cursor: 'pointer', marginRight: 20 }} className='flex' 
                onClick={() => {
                  if (!checkEmailOpen) {
                    setPlainText(false);
                  }
                  setCheckEmailOpen(!checkEmailOpen)}
                }>
                <Checkbox
                  name="checkEmail"
                  onChange={(i, checked, option) => {
                    console.log(!checkEmailOpen);
                  }}
                  checked={checkEmailOpen}
                />
                <span>Track Email Opens</span>
              </div>
              <div style={{ cursor: 'pointer', marginRight: 20 }} className='flex' 
                onClick={() => {
                  if (!checkEmailClick) {
                    setPlainText(false);
                  }
                  setCheckEmailClick(!checkEmailClick)}
                }>
                <Checkbox
                  name="checkEmail"
                  onChange={(i, checked, option) => {
                    console.log(!checkEmailClick);
                  }}
                  checked={checkEmailClick}
                  style={{ marginLeft: deviceWidth > 991 ? 10 : 0 }}
                />
                <span>Track Link Clicks in Emails</span>
              </div>
              <div style={{ cursor: 'pointer' }} className='flex' onClick={() => setCheckEmailReply(!checkEmailReply)}>
                <Checkbox
                  name="checkEmail"
                  onChange={(i, checked, option) => {
                    console.log(!checkEmailReply);
                  }}
                  checked={checkEmailReply}
                />
                <span>Track Email Replies</span>
              </div>
            </div>

            {checkEmailReply && <>
              <div style={{ marginTop: 10 }} className='flex items-center'>
                <h2>Tracking Options </h2>
                <UilQuestionCircle
                  size={20}
                  id={"tracking-options"}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>
              <Tooltip anchorSelect={`#tracking-options`} place={"right"}>
                Options to select while tracking emails.
              </Tooltip>
              <div className='row-col-responsive' style={{ display: 'flex', marginTop: 5, gap: deviceWidth > 991 ? 0 : 8 }}>
                <div style={{ cursor: 'pointer', marginRight: 20 }} className='flex' 
                  onClick={() => {setAutoTagReplies(prev => !prev)}}>
                  <Checkbox
                    name="checkEmail"
                    onChange={(i, checked, option) => {
                      console.log(!autoTagReplies);
                    }}
                    checked={autoTagReplies}
                  />
                  <span>Auto-Tag Incoming Replies</span>
                </div>
              </div>
            </>}
          </div>
        )}
      </div>}

      {showPersonalizedSettings && <div className='mt-4 '>
            <TextInput
              value={name}
              onChange={(nme, value) => setName(value)}
              placeholder="Enter your name"
              label={<div className='flex items-center '>
                <span>Your Name</span>
                <span style={{ color: 'red', marginLeft: 5 }}>
                  *
                </span>
                <UilQuestionCircle
                  size={18}
                  id={"your-name"}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>}
            />
            <Tooltip anchorSelect={`#your-name`} place={"right"}>
              Your full name, used in emails to personalize them.
            </Tooltip>
            <TextInput
              value={companyName}
              onChange={(nme, value) => setCompanyName(value)}
              placeholder="Enter the name of your product/service"
              label={<div className='flex items-center '>
                <span>Product/Service Name</span>
                <span style={{ color: 'red', marginLeft: 5 }}>
                  *
                </span>
                <UilQuestionCircle
                  size={18}
                  id={"product-name"}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>}
            />
            <Tooltip anchorSelect={`#product-name`} place={"right"}>
              Name of your product or service that you will be offering in this sequence.
            </Tooltip>
            <TextInput
              value={offer}
              onChange={(nme, value) => setOffer(value)}
              placeholder="Write your offer, as descriptive as possible."
              label={<div className='flex items-center '>
                <span>Your Product/Service Offer</span>
                <span style={{ color: 'red', marginLeft: 5 }}>
                  *
                </span>
                <UilQuestionCircle
                  size={18}
                  id={"your-product-offer"}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>}
            />
            <Tooltip anchorSelect={`#your-product-offer`} place={"right"}>
              Describe your product or service that you offer as in depth as possible for BlinkGPT AI to write amazing emails.
            </Tooltip>
            <label htmlFor="language" className='flex items-center relative font-medium mb-2' >
              <span>Select Language</span>
              <span style={{ color: 'red', marginLeft: 5 }}>
                  *
              </span>
              <UilQuestionCircle
                  size={18}
                  id={"select-language"}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </label>
            <Tooltip anchorSelect={`#select-language`} place={"right"}>
              Language in which you wish BlinkGPT AI to write emails.
            </Tooltip>
            <RCSelect
                  styles={{
                    control: () => ({
                      padding: '0.3rem',
                      minWidth: 200,
                      width: "100%",
                      borderRadius: 5,
                      backgroundColor: '#f9f9f9',
                      display: 'flex',
                      border: '1px solid #D7D7D7',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                    }),
                  }}
                  id='language'
                  className="bg-gray-100 hover:bg-gray-200 w-full text-gray-800 rounded-full inline-flex"
                  placeholder="Select Language"
                  value={(function () {
                    const value = languagesList.find((prop) => prop?.language === selectedLanguage);
                    if (value) return value;
                    return null;
                  })()}
                  // defaultOptions={languagesList}
                  isClearable
                  menuPortalTarget={document.body}
                  options={languagesList}
                  // loadOptions={fetchLists} // Function to fetch list options asynchronously
                  getOptionValue={(option) => option.language} // Define how to get the value of each option
                  getOptionLabel={(option) => option?.language} // Define how to get the label of each option
                  onChange={(values, currentValue) => {
                    console.log(values)
                    setSelectedLanguage(values?.language);
                  }}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />
          </div>}

      <div
        style={{
          display: 'flex',
          marginTop: 10,
          justifyContent: 'end',
          width: '100%',
        }}
      >
        {!showPersonalizedSettings && showSummary ? <CustomButton
          variant="secondary orange"
          onClick={() => {
            setSelectTiming(true);
            setShowSummary(false)
          }}
          title={"Edit Settings"}
          style={{ marginRight: 20 }}
        />: selectTiming ? null : <CustomButton
          variant="secondary grey"
          onClick={() => {
            setSelectTiming(true)
          }}
          title={"Back"}
          style={{ marginRight: 20 }}
        />}
       {!showPersonalizedSettings && <CustomButton
          variant="primary"
          onClick={() => {
            if(selectTiming) setSelectTiming(false)
            else schedule()
          }}
          title={showSummary ? 'Schedule' : selectTiming ? 'Next' : 'Review'}
        />}
        {
          showPersonalizedSettings &&
          <CustomButton
          variant="primary"
          onClick={() => {
            updateSequenceGPTSettings()
          }}
          title={'Update'}
        />
        }
      </div>
    </CustomDialog>
  );
}

function EditSequenceView() {
  const flowchartData = useFlowchartData();
  const [sequenceID, setSequenceID] = useState(null);
  const [selectedTab, setSelectedTab] = useState('sequences');
  const [sequence, setSequence] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState(sequence?.name || "");
  const [showPersonalizedSettings,setShowPersonalizedSettings] = useState(false)
  const location = useLocation();
  let navigator = useNavigate();
  let { tab } = useParams();

  // update newName with sequence.name
  useEffect(() => {
    setNewName(sequence?.name);
  }, [sequence?.name])

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to discard changes?';
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    } // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let url = window.location.pathname.split('/');
    let id = url[url.length - 1];
    if (id) setSequenceID(id);
  }, []);

  useEffect(() => {
    if (sequenceID) fetchSequence();
  }, [sequenceID]);

  async function fetchSequence() {
    try {
      let result = await Axios.get(`/api/sequences/${sequenceID}`);
      let _sequence = result?.data?.data;
      setSequence(_sequence);
      if(_sequence.personalized) flowchartData.setAiPersonalized(true)
      if (_sequence?.flowchart?.nodes) {
        flowchartData.setNodes(_sequence?.flowchart?.nodes);
        if (_sequence?.flowchart?.nodes.length > 0) setShowReport(true);
      }
      if (_sequence?.flowchart?.edges)
        flowchartData.setEdges(_sequence?.flowchart?.edges);
      if (_sequence?.flowchart?.nodes === undefined) flowchartData.createInitialBlocks()
        // flowchartData?.showAddDialog();
    } catch (err) {
      console.log('Error in fetching sequence ', err);
    }
  }

  useEffect(() => {
    if (tab) setSelectedTab(tab.replace('-', ' '));
  }, [tab, location]);

  const updateSequence = async () => {
    try {
      let result = await Axios.put(`/api/sequences/${sequence?.id}`, {
        flowchart: {
          nodes: flowchartData?.nodes,
          edges: flowchartData?.edges,
        },
        paused: true,
      });
      Toast.fire({ icon: 'success', title: 'Sequence saved successfully.' });
    } catch (err) {
      // console.log('Error in updating sequence ', err);
      Toast.fire({ icon: 'error', title: 'Unable to save sequence.' });
    }
  };
  
  const updateSequenceName = async() => {
    try {
      await Axios.put(`/api/sequences/${sequence?.id}`, { name: newName });
      Toast.fire({ icon: "success", title: "Name updated." });
      setSequence((oldSequence) => ({...oldSequence, name: newName}));
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Unable to update name.' });
    }
  }

  return (
    <PixieProvider>
      <PixieEditor />

      <CustomSubNav
        selected={selectedTab}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />
      <div
        style={{
          backgroundColor: '#f9f9f9',
          padding: 20,
          borderRadius: 5,
          minHeight: '81vh',
        }}
      >
        {flowchartData.dialogShowing && <AddNodeDialog />}

        {flowchartData.showRemoveDialog && <RemoveNodeDialog />}

        {flowchartData.editNode && (
          <AddNodeDialog
            editNode={flowchartData.editNode}
            setEditNode={flowchartData.setEditNode}
          />
        )}
        <div id='edit-sequence-header' style={{ display: 'flex' }}>
          <div style={{ marginLeft: 15 }}>
            <h3
                style={{
                  fontSize: 20,
                  display: 'flex',
                }}
                className="listNameInput"
              >
                {editName ? (
                  <TextInput
                    style={{ display: 'flex' }}
                    value={newName}
                    onChange={(name, value) => {
                      setNewName(value);
                    }}
                    placeholder="Enter a new sequence name"
                  />
                ) : (
                  <p
                    style={{
                      display: 'flex',
                      fontSize: 24,
                      fontWeight: 500,
                      color: '#3a3a3a',
                    }}
                  >
                    {sequence?.name}
                  </p>
                )}
                {!editName && <UilPen
                  size={20}
                  id="edit-sequence-name"
                  style={{ marginLeft: 8, marginTop: 8, cursor: 'pointer' }}
                  onClick={() => {
                    if (editName && newName !== "") updateSequenceName();
                    setEditName(!editName);
                  }}
                />}
                {editName && <UilCheck
                  size={26}
                  style={{ marginLeft: 8, marginTop: 3, cursor: 'pointer' }}
                  onClick={() => {
                    if (editName && newName !== "") updateSequenceName();
                    setEditName(!editName);
                  }}
                />}
                {editName && (
                  <UilTimes
                    size={20}
                    style={{ marginLeft: 8, marginTop: 6, cursor: 'pointer' }}
                    onClick={() => {
                      setEditName(false);
                    }}
                  />
                )}
              </h3>
            <PageTitle 
              headerStyle={{marginTop: 0}}
              description="Click on a block to configure and add it in sequence."
            />
            <Tooltip anchorSelect="#edit-sequence-name" place="right">
              Edit Sequence Name
            </Tooltip>
          </div>
          <div id='edit-sequence-header-buttons' className='flex'>
            <div>
              {showReport && (
                <button
                  id="show-stats"
                  className="flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  type="button"
                  style={{outline: "3px solid #97d8fb", backgroundColor: '#03A6FF', width: 40, height: 40}}
                  onClick={() => {
                    // navigator(`/outreach/sequences/${sequenceID}/reports`);
                    window.location.href = `/outreach/sequences/${sequenceID}/stats`
                  }}
                >
                  <UilAnalytics
                    size={30}
                    style={{ marginTop: 3 }}
                  />
                </button>
              )}
              {showReport && (
                <button
                  id="show-reports"
                  className="flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  type="button"
                  style={{outline: "3px solid #8929E0", width: 40, height: 40}}
                  onClick={() => {
                    // navigator(`/outreach/sequences/${sequenceID}/reports`);
                    window.location.href = `/outreach/sequences/${sequenceID}/reports`
                  }}
                >
                  <UilArrowBreak
                    size={30}
                    style={{ marginTop: 3 }}
                    fill="#8929E0"
                  />
                </button>
              )}
              {showReport && (
                <button
                  id="show-logs"
                  className="flex float-right mr-5 px-2 py-1 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  type="button"
                  style={{outline: "3px solid #ED317F", width: 40, height: 40}}
                  onClick={() => {
                    // navigator(`/outreach/sequences/${sequenceID}/reports`);
                    window.location.href = `/outreach/reports?sequence=${sequence?.id}&team=${sequence?.user_id}`
                  }}
                >
                  <UilHistory
                    size={30}
                    style={{ marginTop: 3 }}
                    fill="#ED317F"
                  />
                </button>
              )}
            </div>
            <Tooltip anchorSelect="#show-stats" place="left">
              View Stats
            </Tooltip>
            <Tooltip anchorSelect="#show-reports" place="left">
              View Step wise Stats
            </Tooltip>
            <Tooltip anchorSelect="#show-logs" place="left">
              View Sequence Logs
            </Tooltip>

           {sequence?.personalized && <CustomButton
              variant="secondary"
              icon={<img src={BlinkGPT} height={22} width={22}/>}
              title={'BlinkGPT Settings'}
              style={{ padding: 10, marginTop: 0, marginRight: "1.25rem"}}
              onClick={() => setShowPersonalizedSettings(true)}
            />}
            
            <Dropdown
              label="Save & Schedule"
              seprator={true}
              icon={<UilRocket/>}
              variant="primary"
              defaultAction={() => {
                const totalNodes = flowchartData?.nodes.filter(
                  (_node) => _node?.type !== 'add' && _node?.group !== 'source' && _node?.type !== 'source_connect'
                );
                let sourceNodeExist = flowchartData?.nodes.filter(
                  (_node) => _node?.group === 'source'
                );
                if(sourceNodeExist.length <= 1) return Swal.fire({
                    icon: 'error',
                    title: ``,
                    html: `<h5>A Source block is required.</h5>`,
                    confirmButtonText: `Ok`,
                    confirmButtonColor: '#0066ff',
                  });
                if (totalNodes?.length < 1)
                  return Swal.fire({
                    icon: 'error',
                    title: ``,
                    html: `<h5>An Action block is required.</h5>`,
                    confirmButtonText: `Ok`,
                    confirmButtonColor: '#0066ff',
                  });
                if(sequence.scheduledAt) {
                  return Swal.fire({
                    icon: 'info',
                    html: `<div>
                      <b>Saving a sequence that has sent emails already</b> <br /><br />

                      <ol style="text-align: left">
                        <li>1. Won't send same emails to leads if already sent.</li>
                        <li style="margin-top: 10px">2. Will reschedule all pending emails & tasks according to updated settings.</li>
                      </ol>
                    </div>`,
                    confirmButtonText: `Got it`,
                    confirmButtonColor: '#0066FF',
                  }).then(async (result) => {
                    if (result.isConfirmed) return setShowSettings(true);
                  });
                } else setShowSettings(true);
              }}
              items={[
                {
                  title: 'Save as Paused',
                  action: () => {
                    updateSequence();
                  },
                },
              ]}
              align="right"
            />
          </div>
        </div>

        <div
          style={{
            height: '72vh',
            border: '1px solid #e6e6e6',
            marginTop: 19,
            borderRadius: 6,
          }}
        >
          <Flowchart />
        </div>
        {(showSettings || showPersonalizedSettings) && (
          <SequenceSettingsDialog
            hide={() => {
              setShowSettings(false)
              setShowPersonalizedSettings(false)
            }}
            sequence={sequence}
            userID={sequence?.user_id}
            nodes={flowchartData?.nodes}
            edges={flowchartData?.edges}
            fetchSequence={fetchSequence}
            showSettings={showSettings}
            showPersonalizedSettings={showPersonalizedSettings}
            setSequence={setSequence}
          />
        )}
      </div>
    </PixieProvider>
  );
}
