/***
 *
 *   CREDIT CARD
 *   Credit card component used in billing UI
 *
 *   PROPS
 *   last_four: last 4 digits of card number
 *   expires: expiry date
 *   brand: card provider
 *
 **********/

import React from 'react';
// import Style from './creditcard.tailwind.js';
import './creditcard.scss'

// export function CreditCard(props) {
//   return (
//     <div className={Style.creditCard} style={{ width: '90%', backgroundColor: '#06F' }}>
//       <div className={Style.brand}>{props.brand}</div>

//       <div className={Style.number}>XXXX-XXXX-XXXX-{props.last_four}</div>

//       <div className={Style.col}>
//         <div className={Style.title}>EXPIRES</div>
//         <div className={Style.value}>{props.expires}</div>
//       </div>

//       <div className={Style.col}>
//         <div className={Style.title}>CVV</div>
//         <div className={Style.value}>•••</div>
//       </div>
//     </div>
//   );
// }

export function CreditCard(props) {
  return (
    <div className='viewCreditCard'>
      <div className='cardType'>{props.brand}</div>

      <div className='cardNumber'>XXXX-XXXX-XXXX-{props.last_four}</div>

      <div className='cardDetail'>
        <div className='cardValidity'>
          <div>EXPIRES</div>
          <div>{props.expires}</div>
        </div>

        <div className='cardCvv'>
          <div>CVV</div>
          <div>•••</div>
        </div>
      </div>
    </div>
  );
}