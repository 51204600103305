import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilListUl,
  UilEdit,
  UilTrash,
  UilArrowLeft
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import {
  AddCondition,
  CustomDropdown,
  CustomSubNav,
  CustomTable,
  useNavigate,
} from 'components/lib';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import './tasks.scss';
import './conditions.scss';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function Conditions(props) {
  const [search, setSearch] = useState(''); // eslint-disable-line
  const [taskName, setTaskName] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState([]);
  const [listCount, setListCount] = useState(2);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1); // eslint-disable-line
  const [page, setPage] = useState(0);
  const [showAddCondition, setShowAddCondition] = useState(false);
  let { taskID } = useParams();
  let navigator = useNavigate();

  const columns = [
    {
      Header: 'Condition Name',
      accessor: 'name',
      Cell: ({ row, value }) => {
        return value;
      },
    },
    {
      Header: 'Condition Description',
      accessor: 'description',
    },
    {
      Header: 'Actions',
      Footer: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { cells } = row;
        let items = [
          {
            title: 'Edit',
            icon: <UilEdit size={18} style={{ marginTop: 2, marginRight: 5 }} />,
            action: () => {
              setSelectedId((oldData) => (oldData = cells[0].row.original));
              setShowAddCondition(true);
            },
          },
          {
            title: 'Delete',
            icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
            action: () => {
              deleteTaskTypes(cells[0].value);
            },
          },
        ];
        return <CustomDropdown right={true} items={items} align="left" />;
      },
    },
  ];

  const getTaskConditions = async () => {
    try {
      const res = await Axios({
        url: '/api/task-conditions/' + taskID,
        method: 'get',
        headers: {
          limit: perPage,
          skip: page,
        },
      });
      setData(res.data.data);
      setListCount(res.data.count);
      setTaskName(res.data.task?.name)
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  const deleteTaskTypes = async (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h5>This task condition will be deleted?</h5>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#E53A40',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await Axios({
            url: '/api/task-condition/' + id,
            method: 'delete',
          });
          // console.log("RESP ", res.data)
          Toast.fire({
            icon: 'success',
            title: `Task condition deleted successfully.`,
          });
          getTaskConditions();
        } catch (err) {
          console.log('ERROR ', err.response);
          if (err.response.data?.message)
            return Toast.fire({
              icon: 'error',
              title: `${err.response.data?.message}`,
            });
          Toast.fire({ icon: 'error', title: `Internal server error.` });
        }
      }
    });
  };

  useEffect(() => {
    if (taskID) getTaskConditions();
  }, [taskID, perPage, page]);

  return (
    <>
      <CustomSubNav
        selected={'tasks'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />
      <Row>
        <Col xs={12}>
          {showAddCondition && (
            <AddCondition
              onClose={() => setShowAddCondition(false)}
              onComplete={() => setShowAddCondition(false)}
              onUpdate={() => {
                setShowAddCondition(false);
                getTaskConditions();
              }}
              taskID={{ _id: taskID }}
              id={selectedId}
              setData={setData}
            />
          )}
          <div id='conditions-header' className='flex'>
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: '3px solid #A0A0A0',
                height: 40,
                width: 40,
                borderRadius: 5,
                marginTop: 10,
                marginLeft: 5
              }}
              className="mr-5 text-white"
              role="button"
              onClick={() => navigator('/task-types')}
            >
              <UilArrowLeft size={25} color="#A0A0A0" />
            </button>
            <h3
              style={{ fontSize: 20, marginTop: 13 }}
              className="font-semibold"
            >
              Conditions for Task - {taskName}
            </h3>
            <CustomButton
              id='new-condition-button'
              title="New Condition"
              variant="secondary"
              style={{ marginBottom: 10, marginRight: 5 }}
              onClick={() => {
                setSelectedId(null);
                setShowAddCondition(true);
              }}
            />
          </div>
          <Row>
            <Col xs={12}>
              <CustomTable
                hasFooter={false}
                pagination={false}
                filter={search}
                columns={columns}
                data={data}
                count={listCount}
                perPage={perPage}
                onClick={(row) => {}}
                setPerPage={setPerPage}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
