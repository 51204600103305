import React,{forwardRef} from 'react';
import { Label, Error, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';
import { UilCheck } from '@iconscout/react-unicons'

export const TextInput = forwardRef((props,ref) => {
  const error = props.errorMessage || 'Please enter a value';

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '') valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true;

    if (props.required && value !== '') valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      {props.type === 'textarea' ? (
        <textarea
          id={props.id}
          name={props.name}
          value={props.value}
          className={textStyle}
          maxLength={props.maxLength}
          minLength={props.minLength}
          disabled={props.disabled}
          style={{
            border: '1px solid #D7D7D7',
            borderRadius: 5,
            backgroundColor: props.style?.backgroundColor || '#F9F9F9',
            ...props.style,
          }}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => validate(e)}
          autoFocus={props.autoFocus}
        ></textarea>
      ) : (
        <input
          type={props.type}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          minLength={props.minLength}
          id={props.id}
          name={props.name}
          disabled={props.disabled}
          value={props.value || ''}
          style={{
            border: '1px solid #D7D7D7',
            borderRadius: 5,
            backgroundColor: props.style?.backgroundColor || '#F9F9F9',
            ...props.style,
          }}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(
              props.name,
              !props.type || props.type !== 'file' ? e.target.value : e,
              undefined
            )
          }
          onBlur={(e) => {
            validate(e)
            props.onBlur?.(props.value);
          }}
          onWheel={props.onWheel}
          accept={props.accept}
          autoFocus={props.autoFocus}
          onFocus={props.onFocus}
          ref={ref}
        />
      )}

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <UilCheck 
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
})
