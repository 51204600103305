import { useContext, useEffect, useRef, useState } from 'react';
import { Animate, AuthContext, Loader, SubSubNav, TextInput, WhitelabelContext } from 'components/lib';
import { UilEnvelopes, UilSearch, UilExclamationOctagon, UilLink, UilEnvelopeCheck, UilEnvelopeExclamation, UilMessage, UilEnvelopeOpen, UilAngleDoubleLeft, UilAngleLeft, UilAngleRight, UilAngleDoubleRight, UilSync, UilCornerUpLeftAlt, UilCornerUpRightAlt, UilArchive, UilEnvelopeReceive, UilArrowLeft } from '@iconscout/react-unicons';
import { Col, Row } from 'react-grid-system';
import { DateTime } from 'luxon';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import { Tooltip } from "react-tooltip";
import BundledEditor from 'components/custom/buldledEditor';
import CustomButton from 'components/custom/customButton';
import Toast from 'utils/toast';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import timezones from '../../assets/data/timezone';
import BlinkGPT from "../../assets/BlinkGPT-filled.png";
import './index.scss'
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const outcomes = [
    { value: 'interested', label: 'Interested' },
    { value: 'not-interested', label: 'Not Interested' },
    { value: 'automatic-response', label: 'Automatic Response' },
    { value: 'meeting-request', label: 'Meeting Request' },
    { value: 'out-of-office', label: 'Out of Office' },
    { value: 'do-not-contact', label: 'Do not contact' },
    { value: 'wrong-person', label: 'Wrong Person' },
    { value: 'closed', label: 'Closed' }
]

const outcomeJson = {
    'interested': {
        name: "Interested",
        color: 'success'
    },
    'not-interested': {
        name: "Not Interested",
        color: 'danger'
    },
    'automatic-response': {
        name: "Automatic Response",
        color: 'danger'
    },
    'meeting-request': {
        name: "Meeting Request",
        color: 'success'
    },
    'out-of-office': {
        name: "Out of Office",
        color: 'success'
    },
    'do-not-contact': {
        name: "Do not contact",
        color: 'danger'
    },
    'wrong-person': {
        name: "Wrong Person",
        color: 'danger'
    },
    'closed': {
        name: "Closed",
        color: 'success'
    }
}

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
}

function htmlToText(htmlString, onlyFirst) {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');

    if(onlyFirst) {
        const firstParagraph = doc.querySelector('p');
  
        if (firstParagraph) {
            const textContent = firstParagraph.textContent.trim();
            return textContent.substring(0, 100);
        }

        return "";
    }

    return doc.body.textContent || "";
}

const mailDate = (sequences, item, format) => {
    const seqInfo = sequences[item?.sequence]
    let zoneInfo = timezones?.find((t) => t.id === seqInfo?.timezone);
    if(!zoneInfo && seqInfo?.timezone.includes('/')) zoneInfo = { utc: [seqInfo?.timezone] }
    let scheduledDate;
    if(typeof item?.scheduled_time === "number") {
        scheduledDate = DateTime.fromMillis(item?.scheduled_time);
        if (zoneInfo) scheduledDate = scheduledDate.setZone(zoneInfo.utc[0]);
    }

    if(!scheduledDate) return "";

    if(format) return <span>
        {scheduledDate.toFormat(`MMM d, hh:mm a`)}
    </span>

    const day = scheduledDate.day;
    const suffix =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th';

    return (
      <span>
        {scheduledDate.toFormat(`d`) +
          suffix +
          scheduledDate.toFormat(` MMM yyyy, hh:mm a`)}
      </span>
    );
}

export default function Inbox(props) {
    const query = new URLSearchParams(window.location.search);
    
    const [sequences, setSequences] = useState([]);
    const [sequencesData, setSequencesData] = useState({});
    const [sequence, setSequence] = useState(null);
    const [outcome, setOutcome] = useState("");

    const [senders, setSenders] = useState([]);
    const [sendersList, setSendersList] = useState([]);
    const [signatureList, setSignatureList] = useState({});
    const [sender, setSender] = useState("");
    
    const [selectedTab, setSelectedTab] = useState('Unread');
    const [selectedMail, setSelectedMail] = useState(null)
    const [mails, setMails] = useState([])
    const [search, setSearch] = useState("")
    const [showDatePicker, setShowDatePicker] = useState('');
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [RangeView, setRangeView] = useState('');
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [searched, setSearched] = useState(false)
    const [isRefreshClickable, setIsRefreshClickable] = useState(true);
    const [planUsage, setPlanUsage] = useState(null);
    const [chatGPTUsed, setChatGPTUsed] = useState(0);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [messageIDs, setMessageIDs] = useState([]);
    const navigator = useNavigate();
    const perPage = 20;
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
    const [ownedBy, setOwnedBy] = useState('');
    const authContext = useContext(AuthContext);
    const { whitelabelDetails } = useContext(WhitelabelContext);
    const deviceWidth = useWindowResize();

    useEffect(() => {
        setOwnedBy(authContext?.user?.id);
        getActivePlans()
        fetchSequences('');
        if(query.get("tab") === "replies") setSelectedTab('Replies')
    }, [])
  
    useEffect(()=>{
        if(ownedBy && ownedBy.length>0) {
            getAllSenders()
            reloadSenders('')
        } 
    },[ownedBy])

    useEffect(() => {
        if (query.get('sequence') && query.get('sequence').length>0) {
          fetchquerysequence(query.get('sequence'))
        }
    },[])

    useEffect(() => {
        if (query.get('sequence') && query.get('sequence').length>0 && typeof sequence !== 'undefined') {
          if(query.get('tab') && query.get('tab').length>0 && typeof selectedTab !== 'undefined'){
            getAllMails()
          }else if(query.get('tab') == null){
            getAllMails()
          }
        }else if(query.get('sequence') == null){
            getAllMails()
        }
    }, [sequence, sender, outcome, start, end, selectedTab])

    useEffect(() => {
        // Check localStorage for the last button click time
        const lastClickTime = JSON.parse(localStorage.getItem('mail-check'));

        if (lastClickTime) {
          // Calculate the time difference
          const timeDifference = new Date().getTime() - new Date(lastClickTime).getTime();
          
          // If less than 15 minutes have passed, disable the button
          if (timeDifference < fifteenMinutes) {
            
            setIsRefreshClickable(false);
    
            // Enable the button after the remaining time
            setTimeout(() => {
              setIsRefreshClickable(true);
            }, fifteenMinutes - timeDifference);
          }
        }
    }, []);

    useEffect(() => {
        // signatureList, setSignatureList
        if(selectedMail) {
            if(!signatureList[selectedMail?.sender]) getSenderSignature(selectedMail?.sender)
        }
    }, [selectedMail])

    const fetchquerysequence = async (sequenceid) =>{
        try {
          let result = await Axios.get(`/api/sequences/${sequenceid}`);
          let _sequence = result?.data?.data;
          setSequence(_sequence);
        } catch (error) {
          console.log('ERROR: ', error);
        }
    }

    async function getActivePlans() {
        try {
            let res = await Axios.get(`/api/account/plan`);
            // console.log("DATA ", res.data)
            setPlanUsage(res.data.data);
            setChatGPTUsed(res.data.data?.chatGPTEmailTemplate?.used || 0);
        } catch (err) {
            console.log('ERR ', err);
        }
    }
    
    const handleRefreshClick = () => {
        if (isRefreshClickable) {
            // Disable the button
            setIsRefreshClickable(false);
        
            // Save the current time to localStorage
            localStorage.setItem('mail-check', new Date().getTime().toString());
        
            // Enable the button after 15 minutes
            setTimeout(() => {
                setIsRefreshClickable(true);
            }, fifteenMinutes);
        }
    };

    const getAllMails = async (pageNumber, post) => {
        setLoadingPage(true)
        try {
            let dateStr = '';
            if (start && end) dateStr = `&date=${start}-${end}`;

            let pageNo = pageNumber || 0
            setPage(pageNo)

            let config = {
                url: `/api/mail?limit=${perPage}&skip=${page * perPage}&sender=${sender}&sequence=${sequence?.id}&outcome=${outcome}&search=${search}&type=${selectedTab.toLocaleLowerCase()}${dateStr}`,
                method: post ? 'post' : 'get',
            }
            if(post) config['data'] = {
                messageIDs
            }

            const res = await Axios(config);
            const data = res.data.data
            // console.log("MASILS ", data)

            if(data.result.length > 0 && pageNumber) setMails([...mails, ...data.result])
            if(!pageNumber) setMails(data.result)
            setTotal(data.totalCount)
            setMessageIDs(data.messageIDs)
            setTotalPages(Math.ceil(data.totalCount / perPage));
            getRequiredSequence(data.result)
            // if(data?.result?.length > 0) {
            //     if(selectedMail) setSelectedMail(data.result.find(mail => mail._id === selectedMail._id))
            //     else setSelectedMail(data.result[0])
            // } 
            // else setSelectedMail(null)
            if(data.count > 0) setSearched(true)
            setLoading(false)
            setLoadingPage(false)
        } catch (err) {
            setLoading(false)
            setLoadingPage(false)
        }
    }

    const deleteMails = async (id) => {
        try {
            const res = await Axios({
                url: `/api/mail/${id}`,
                method: 'delete',
            });
            setSelectedMail(null)
            getAllMails()
        } catch (err) {

        }
    }

    const updateMails = async (id, updateData, message, unread) => {
        try {
            const res = await Axios({
                url: `/api/mail/${id}`,
                method: 'patch',
                data: updateData
            });
            // if(unread) setMails(oldMails => {
            //     return oldMails.map(mail => {
            //         if (mail._id === id) return {
            //                 ...mail,
            //                 tasks: mail.tasks.map(task => {
            //                     return {
            //                         ...task,
            //                         unread: updateData.unread,
            //                     };
            //                 }),
            //             };
            //         return mail;
            //     });
            // });
            Toast.fire({ icon: 'success', title: message || "Updated mail successfully" });
        } catch (err) {
            Toast.fire({ icon: 'error', title: "Unable to update mail." });
        }
    }

    const refreshMails = async () => {
        setLoaderMessage("")
        setLoading(true)
        setLoadingPage(true)
        try {
            const res = await Axios({
                url: `/api/mail-refresh`,
                method: 'get',
            });

            handleRefreshClick()
            setTimeout(() => {
                checkInboxCheck(0)
            }, 5000)
        } catch (err) {
            setLoading(false)
            setLoadingPage(false)
        }
    }

    async function checkInboxCheck(count) {
        if(count >= 6) {
            setLoaderMessage("")
            setLoading(false)
            setLoadingPage(false)
            return
        }
        try {
            let selectedSender = senders.length > 0 ? senders[0] : null
            if(!selectedSender) {
                setLoading(false)
                setLoadingPage(false)
                return
            }
            const res = await Axios({
                url: `/api/senders/${selectedSender.id}`,
                method: 'get',
            });

            if(res.data.data?.inboxLastCheck) {
                if(selectedSender.inboxLastCheck !== res.data.data?.inboxLastCheck) getAllMails()
                else {
                    setLoaderMessage("Seems like it is taking a while to check all those inboxes…")
                    setTimeout(() => {
                        checkInboxCheck(count+1)
                    }, 5000)
                }
            } else {
                setLoaderMessage("Seems like it is taking a while to check all those inboxes…")
                setTimeout(() => {
                    checkInboxCheck(count+1)
                }, 5000)
            }
        } catch (err) {
            setLoaderMessage("Seems like it is taking a while to check all those inboxes…")
            setTimeout(() => {
                checkInboxCheck(count+1)
            }, 5000)
        }
    }

    const getRequiredSequence = async (fetchedMails) => {
        try {
            let seqInfo = sequencesData
            let seqNeeded = []
            for(let mail of fetchedMails) {
                const firstMailSeq = mail?.sequence
                if(!seqInfo[firstMailSeq]) seqNeeded.push(firstMailSeq)
            }
        
            if(seqNeeded?.length > 0) {
                let result = await Axios.get(
                    `/api/multi-sequence?ids=${seqNeeded.join(",")}`
                );
                const data = result?.data?.data;
        
                for(let item of data) {
                    seqInfo[item.id] = { id: item.id, name: item.name, timezone: item.timezone };
                }
                setSequencesData(seqInfo)
            }
        } catch (e) { }
    }

    async function fetchSequences(inputValue) {
        try {
            let result;
            if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
                result = await Axios.get(`/api/sequences?limit=10&search=${inputValue}`);
            }else {
                result = await Axios.get(`/api/sequences?limit=10&search=${inputValue}&userID=${ownedBy}`);
            }
            const options = result?.data?.data;
            setSequences(options);
            return options;
        } catch (err) {
            console.log('Error in getting sequences ', err);
            return [];
        }
    }

    async function getAllSenders() {
        try {
            let result;
            if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
                result = await Axios.get(`/api/senders?limit=200&skip=0&inboxEnabled=true`);
            }else {
                result = await Axios.get(`/api/senders?limit=200&skip=0&inboxEnabled=true&ownedby=${ownedBy}`);
            }
            if (result?.data?.success) setSendersList(result.data.data);
        } catch (err) {}
    }

    async function getSenderSignature(id) {
        if(!id) return false;
        try {
            let result = await Axios.get(`/api/signature/${id}/sender`);
            if (result?.data?.success) {
                setSignatureList(prevSignatureList => {
                    prevSignatureList[id] = result.data.data
                    return prevSignatureList
                });
            }
        } catch (err) {}
    }

    async function reloadSenders(inputValue) {
        try {
            let result;
            if((authContext.user.permission === 'owner' || authContext.user.permission === 'admin')){
                result = await Axios.get(`/api/senders?limit=10&skip=0&inboxEnabled=true&search=${inputValue}`);
            }else {
                result = await Axios.get(`/api/senders?limit=10&skip=0&inboxEnabled=true&ownedby=${ownedBy}&search=${inputValue}`);
            }
            if (result?.data?.success) {
                let senderList = result.data.data.map((sender) => ({
                    key: sender.id,
                    label: `${
                        sender.alias ||
                        sender.microsoft_email ||
                        sender.google_email ||
                        sender.smtpImapEmail
                    }`,
                    value: sender.id,
                    ...sender
                }))
                setSenders(senderList);
                return senderList
            }
        
            return []
        } catch (err) {
            return []
        }
    }

    const handleSelect = (ranges) => {
        if(!searched) setSearched(true)

        setStartDate(ranges.selection.startDate);
        setEndDate(ranges.selection.endDate);
    
        setStart(DateTime.fromJSDate(ranges.selection.startDate).ts);
        setEnd(DateTime.fromJSDate(ranges.selection.endDate).ts);
    
        const startD = DateTime.fromJSDate(ranges.selection.startDate);
        const endD = DateTime.fromJSDate(ranges.selection.endDate);
    
        setRangeView(
          `From ${startD.toFormat('MM-dd-yyyy')} To ${endD.toFormat('MM-dd-yyyy')}`
        );
    };

    if(total === 0 && !searched) return <Animate type="pop">
        <Helmet>
            <title>{`Inbox | ${whitelabelDetails?.agencyName}`}</title>
        </Helmet>
        {loadingPage && (
            <div
                className="csFormLoader"
                style={{
                    background: `rgba(255, 255, 255, 0.5)`,
                    marginTop: 70,
                    height: '105vh',
                    zIndex: 2
                }}
            >
                <Loader>
                    <b className='font-bold'>{loaderMessage}</b>
                </Loader>
            </div>
        )}
        <div onClick={() => {}} style={{ display: 'flex' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    textAlign: 'center',
                    marginTop: 50,
                    marginBottom: 50,
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
                    <path d="M88.915,88.033c-3.418,0-6.268-2.672-6.486-6.084c-0.058-0.706-1.781-17.383-31.312-19.531v8.04 c0,2.494-1.455,4.798-3.708,5.87c-0.864,0.412-1.831,0.631-2.792,0.631c-1.487,0-2.942-0.517-4.098-1.454L10.984,51.508 c-1.525-1.239-2.4-3.078-2.4-5.045s0.875-3.806,2.402-5.045l29.531-23.995c1.156-0.939,2.611-1.456,4.1-1.456 c0.961,0,1.928,0.219,2.796,0.632c2.249,1.07,3.704,3.374,3.704,5.868v8.575c43.187,4.207,44.292,49.929,44.299,50.407 c0.042,3.555-2.703,6.442-6.25,6.579L88.915,88.033z" opacity=".35"></path><path fill="#f2f2f2" d="M86.915,86.033c-3.418,0-6.268-2.672-6.486-6.084c-0.058-0.706-1.781-17.383-31.312-19.531v8.04 c0,2.494-1.455,4.798-3.708,5.87c-0.864,0.412-1.831,0.631-2.792,0.631c-1.487,0-2.942-0.517-4.098-1.454L8.984,49.508 c-1.525-1.239-2.4-3.078-2.4-5.045s0.875-3.806,2.402-5.045l29.531-23.995c1.156-0.939,2.611-1.456,4.1-1.456 c0.961,0,1.928,0.219,2.796,0.632c2.249,1.07,3.704,3.374,3.704,5.868v8.575c43.187,4.207,44.292,49.929,44.299,50.407 c0.042,3.555-2.703,6.442-6.25,6.579L86.915,86.033z"></path><path fill="#70bfff" d="M42.617,20.468v14.766c43.745,0,44.299,44.299,44.299,44.299s-1.661-25.841-44.299-25.841v14.766 L13.084,44.463L42.617,20.468z"></path><path fill="#0080FF" d="M86.915,81.033c-0.787,0-1.445-0.612-1.496-1.404c-0.069-0.98-2.148-23.857-41.302-24.427v13.256 c0,0.579-0.333,1.106-0.855,1.354c-0.521,0.249-1.14,0.176-1.591-0.19L12.138,45.627c-0.351-0.285-0.554-0.712-0.554-1.164 s0.203-0.879,0.554-1.164l29.533-23.996c0.45-0.366,1.068-0.439,1.591-0.19c0.522,0.249,0.855,0.776,0.855,1.354v13.284 c43.235,1.001,44.293,45.311,44.299,45.763c0.01,0.813-0.63,1.486-1.442,1.518C86.954,81.032,86.935,81.033,86.915,81.033z M42.617,52.191c24.021,0,35.472,8.21,40.913,15.617c-0.83-3.066-2.024-6.462-3.743-9.838 c-7.173-14.091-19.679-21.236-37.17-21.236c-0.828,0-1.5-0.671-1.5-1.5V23.619L15.463,44.463l25.654,20.844V53.691 C41.117,52.863,41.789,52.191,42.617,52.191z"></path>
                </svg>
                    Start a Sequence, wait for replies to start playing with Inbox.
                <div
                    style={{
                        margin: 'auto',
                        marginTop: '0 !important',
                    }}
                >
                    <CustomButton
                        variant="primary"
                        onClick={() => navigator(`/outreach`)}
                        title="Go to Sequences"
                    />
                </div>
                <div
                    style={{
                        margin: 'auto',
                        marginTop: 5,
                    }}
                >
                    <CustomButton
                        variant="secondary"
                        onClick={() => {
                            if (isRefreshClickable) refreshMails();
                        }}
                        title="Check Replies"
                        disabled={!isRefreshClickable}
                    />
                </div>
            </div>
        </div>
    </Animate>

    return (
        <Animate type="pop">
            <Helmet>
                <title>{`Inbox | ${whitelabelDetails?.agencyName}`}</title>
            </Helmet>
            <div style={{ display: deviceWidth > 991 ? 'flex' : selectedMail ? 'none' : 'flex' }} id='top-filter-container'>
                <div className="searchPeople rounded" style={{ height: 38, marginLeft: -5 }} onClick={() => setShowDatePicker(false)}>
                    <input
                        type="text"
                        placeholder={'Search Conversations'}
                        value={search}
                        onChange={(e) => {
                            if(!searched) setSearched(true)
                            setSearch(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') getAllMails();
                        }}
                    />
                    <UilSearch style={{ marginTop: -3, cursor: 'pointer' }} onClick={() => getAllMails()} />
                </div>
                <div id='filter-right-part'>
                    <div className='' onClick={() => setShowDatePicker(false)}>
                        <Select
                            styles={{
                                control: () => ({
                                    padding: 1,
                                    width: '100%',
                                    borderRadius: 5,
                                    display: 'flex',
                                    border: '1px solid #d7d7d7',
                                }),
                            }}
                            placeholder="All Outcomes"
                            isSearchable={false}
                            isClearable
                            options={outcomes}
                            onChange={(selected) => {
                                if(!searched) setSearched(true)

                                if(selected) setOutcome(selected.value)
                                else setOutcome("")
                            }}
                        />
                    </div>
                    <div className='' onClick={() => setShowDatePicker(false)}>
                        <AsyncSelect
                            styles={{
                                control: () => ({
                                    padding: 1,
                                    width: '100%',
                                    borderRadius: 5,
                                    display: 'flex',
                                    border: '1px solid #d7d7d7',
                                }),
                            }}
                            placeholder="All Sequences"
                            defaultOptions={sequences}
                            value={sequence}
                            isClearable
                            loadOptions={fetchSequences} // Function to fetch sequence options asynchronously
                            getOptionValue={(option) => option.id} // Define how to get the value of each option
                            getOptionLabel={(option) => option.name} // Define how to get the label of each option
                            onChange={(selected) => {
                                if(!searched) setSearched(true)
                                setSequence(selected)
                            }}
                        />
                    </div>
                    <div className='' onClick={() => setShowDatePicker(false)}>
                        <AsyncSelect
                            placeholder="All Email Senders"
                            styles={{
                                control: () => ({
                                    padding: 1,
                                    width: '100%',
                                    borderRadius: 5,
                                    display: 'flex',
                                    border: '1px solid #d7d7d7',
                                }),
                            }}
                            isClearable
                            onChange={(selected) => {
                                if(!searched) setSearched(true)

                                if(selected) setSender(selected.value);
                                else setSender("");
                            }}
                            loadOptions={reloadSenders}
                            defaultOptions={senders}
                            noOptionsMessage={() => 'Search for an Email Sender'}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder={'Date Range'}
                            className="rounded"
                            style={{
                                width: '100%',
                                border: '1px solid #e5e5e5',
                                padding: '7px 12px',
                                borderRadius: 3,
                                marginBottom: 8
                            }}
                            value={RangeView}
                            onClick={(e) => setShowDatePicker(!showDatePicker)}
                            onChange={(e) => {}}
                        />
                        {showDatePicker && (
                            <Animate type="slidedown" timeout={50}>
                                <div
                                    className="dropdownMenu"
                                    style={{
                                        zIndex: 9999,
                                        right: 16,
                                        left: 'initial',
                                        marginTop: 0,
                                    }}
                                >
                                    <div className="dropdownMenuBody">
                                        <DateRangePicker
                                            ranges={[
                                                {
                                                    startDate: StartDate,
                                                    endDate: EndDate,
                                                    key: 'selection',
                                                }
                                            ]}
                                            onChange={handleSelect}
                                            inputRanges={[]}
                                        />
                                    </div>
                                </div>
                            </Animate>
                        )}
                    </div>
                </div>
            </div>

            <div style={{ display: deviceWidth > 991 ? 'flex' : selectedMail ? 'none' : 'flex', marginTop: 25, marginLeft: 2 }} className='flex' onClick={() => setShowDatePicker(false)}>
                <div style={{ display: deviceWidth > 567 ? 'block' : 'none' }}>
                    <SubSubNav
                        selected={selectedTab}
                        onChange={(selectedItem) => {
                            setSearched(true)
                            setSelectedTab(selectedItem.title)
                        }}
                        menuitems={[
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeExclamation />,
                                title: 'Unread',
                            },
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeReceive />,
                                title: 'Replies',
                            },
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeCheck />,
                                title: 'Sent',
                            },
                            {
                                icon: 'unicons',
                                image: <UilEnvelopes />,
                                title: 'All'
                            }
                        ]}
                        style={{ flexWrap: 'wrap' }}
                    />
                </div>
                <div style={{ display: deviceWidth <= 567 ? 'block' : 'none' }}>
                    <SubSubNav
                        selected={selectedTab}
                        onChange={(selectedItem) => {
                            setSearched(true)
                            setSelectedTab(selectedItem.title)
                        }}
                        menuitems={[
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeExclamation />,
                                title: 'Unread',
                            },
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeReceive />,
                                title: 'Replies',
                            },
                            // {
                            //     icon: 'unicons',
                            //     image: <UilEnvelopeCheck />,
                            //     title: 'Sent',
                            // },
                            // {
                            //     icon: 'unicons',
                            //     image: <UilEnvelopes />,
                            //     title: 'All'
                            // }
                        ]}
                        style={{ flexWrap: 'wrap' }}
                    />
                    <SubSubNav
                        selected={selectedTab}
                        onChange={(selectedItem) => {
                            setSearched(true)
                            setSelectedTab(selectedItem.title)
                        }}
                        menuitems={[
                            {
                                icon: 'unicons',
                                image: <UilEnvelopeCheck />,
                                title: 'Sent',
                            },
                            {
                                icon: 'unicons',
                                image: <UilEnvelopes />,
                                title: 'All'
                            }
                        ]}
                        style={{ flexWrap: 'wrap' }}
                    />
                </div>
                <div className='ml-auto'>
                    <UilSync
                        style={{ cursor: isRefreshClickable ? 'pointer' : 'default', opacity: isRefreshClickable ? '1' : '0.3' }}
                        className={`${loading?"sync":""}`}
                        size={20}
                        id={"recheck-all-inboxes"}
                        onClick={() => {
                            if (isRefreshClickable) refreshMails();
                        }}
                    />
                    <Tooltip anchorSelect={`#recheck-all-inboxes`}>
                        {isRefreshClickable ? 'Recheck All Inboxes' : 'Try again in some time.'}
                    </Tooltip>
                </div>
            </div>

            <Row className="mails" onClick={() => setShowDatePicker(false)}>
                <Col style={{ display: deviceWidth > 991 ? 'block' : selectedMail ? 'none' : 'block' }} xs={12} lg={3} id='preview-email-section'>
                    <div style={{ height: '73vh', marginTop: 0 }} className="mailContainer">
                        {mails?.map(mail => <MailPreviewItem 
                            key={mail._id}
                            item={mail}
                            setSelectedMail={setSelectedMail}
                            active={mail._id === selectedMail?._id}
                            sequences={sequencesData}
                        />)}
                    </div>

                    {total > mails?.length ? (
                        <div className="csTableFooter">
                            <CustomButton title="Load More" variant="secondary grey" style={{ width: "100%" }} onClick={() => getAllMails(page + 1, true)}/>
                        </div>
                    ) : null}
                </Col>
                <Col xs={12} lg={9} style={{ display: deviceWidth > 991 ? 'block' : selectedMail ? 'block' : 'none' }}>
                    {selectedMail ? <SelectedMailPreview 
                        mail={selectedMail}
                        setSelectedMail={setSelectedMail}
                        sequences={sequencesData}
                        senders={sendersList}
                        deleteMails={deleteMails}
                        updateMails={updateMails}
                        planUsage={planUsage}
                        chatGPTUsed={chatGPTUsed}
                        setChatGPTUsed={setChatGPTUsed}
                        section={selectedTab.toLowerCase()}
                        signatureList={signatureList}
                    /> : <div style={{ display: 'flex' }}>
                        <div
                            style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 'auto',
                            textAlign: 'center',
                            marginTop: 50,
                            marginBottom: 50,
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto', marginBottom: 10 }} viewBox="0 0 100 100">
                                <path d="M19,94.5c-3.584,0-6.5-2.916-6.5-6.5V62c0-0.563,0.073-1.122,0.218-1.665	l9.997-37.989C23.462,19.494,26.047,17.5,29,17.5h50c3.027,0,5.629,2.058,6.325,5.003l9,37.999C94.442,61.006,94.5,61.504,94.5,62	v26c0,3.584-2.916,6.5-6.5,6.5H19z" opacity=".35"></path><path fill="#f2f2f2" d="M17,92.5c-3.584,0-6.5-2.916-6.5-6.5V60c0-0.563,0.073-1.122,0.218-1.665l9.997-37.989	C21.462,17.494,24.047,15.5,27,15.5h50c3.027,0,5.629,2.058,6.325,5.003l9,37.999C92.442,59.006,92.5,59.504,92.5,60v26	c0,3.584-2.916,6.5-6.5,6.5H17z"></path><polygon fill="#ffc571" points="17,60 17,86 86,86 86,60 77,22 27,22"></polygon><polygon fill="#d47f56" points="19,86 84,86 72,27 32,27"></polygon><polygon fill="#0080FF" points="72,44 32,44 23,86 80,86" opacity=".35"></polygon><path fill="#ffc571" d="M59,60c0,4.418-3.582,8-8,8s-8-3.582-8-8H17v26h69V60H59z"></path><path fill="#0080FF" d="M86,87.5H17c-0.828,0-1.5-0.672-1.5-1.5V60c0-0.129,0.017-0.257,0.05-0.382l10-38	C25.723,20.959,26.318,20.5,27,20.5h50c0.695,0,1.3,0.478,1.46,1.154l9,38c0.026,0.113,0.04,0.229,0.04,0.346v26	C87.5,86.828,86.828,87.5,86,87.5z M18.5,84.5h66V60.175L75.813,23.5H28.156L18.5,60.194V84.5z"></path>
                            </svg>
                            No emails found
                        </div>
                    </div>}
                </Col>
            </Row>
            <Tooltip place='top' anchorSelect='.highlight.tooltip'/>
        </Animate>
    );
}

const MailPreviewItem = (props) => {
    const [item, setItem] = useState(null);
    const [contact, setContact] = useState(null);
    const [sequence, setSequence] = useState(null);

    useEffect(() => {
        setItem(props.item)
    }, [])

    useEffect(() => {
        if(item) {
            setContact(item?.integration?.contact || item?.contactID)

            setTimeout(() => {
                setSequence(props.sequences[item.sequence])
            }, 900)
        }
    }, [item]);

    return <div
            onClick={() => props.setSelectedMail(props.item)}
            className={`mail ${props.active?'active':''}`}
        >
            <div className="details">
                <div className='flex'>
                    <div className='name' style={{ fontSize: 13 }}>
                        {contact?.First_Name || ""} {contact?.Last_Name || ""}
                        {/* {unread && <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="#03A6FF" />
                        </svg>} */}
                    </div>
                    <div className='dated ml-auto'>{item && mailDate(props.sequences, item, true)}</div>
                </div>
                <div style={{ fontSize: 12 }}>
                    {contact?.Email}
                </div>
                {/* <div className='subject'>{item?.data?.email?.subject}</div>
                <div className='bodyPreview'>{htmlToText(item?.data?.email?.body, true)}</div> */}
                <div>
                    {item?.task_type === "forwarding" && "You forwarded the email"}
                    {item?.task_type === "reply" && item?.replied && !item?.show_reply_report && "Got a reply from lead"}
                    {item?.task_type === "reply" && item?.show_reply_report && "You Replied to lead"}
                </div>
                <div className='flex sequence mt-1'>
                    <div>
                        {item?.outcome && <span className={`csBadge ${item && outcomeJson[item?.outcome]?.color} csCenter`} style={{ padding: '2px 5px', fontSize: 12 }}>{item && outcomeJson[item?.outcome]?.name}</span>}
                        {!item?.outcome && <span className={`csBadge gray csCenter`} style={{ padding: '2px 5px', fontSize: 12 }}>No Outcome</span>}
                    </div>
                    <div className='ml-2'>
                        <span className="csBadge primary csCenter" style={{ padding: '2px 5px', fontSize: 12 }}><UilMessage size={12} style={{ marginTop: 3, marginRight: 3 }} /> {truncateText(sequence?.name || "", 15)}</span>
                    </div>
                </div>
            </div>
        </div>
}

const SelectedMailPreview = (props) => {
    const [selectMailToReply, setSelectMailToReply] = useState(null)
    const [selectMailToForword, setSelectMailToForword] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [email, setEmail] = useState('');
    const [CC, setCC] = useState('');
    const [BCC, setBCC] = useState('');
    const [content, setContent] = useState('');
    const [mail, setMail] = useState(null);
    const [mails, setMails] = useState([]);
    const [contact, setContact] = useState(null);
    const [sender, setSender] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const [sequence, setSequence] = useState(null);
    const [promptText, setPromptText] = useState('');
    const editorRef = useRef(null);

    useEffect(() => {
        setMail(props.mail)
        setSelectMailToForword(null)
        setSelectMailToReply(null)
    }, [props.mail])

    useEffect(() => {
        if(mail) {
            setContact(mail?.integration?.contact || mail?.contactID)
            
            setSender(props.senders?.find(s => s.id === mail?.sender))
            setPromptText(formatThreadMessage())
            getThread()
            markUnread()

            setTimeout(() => {
                setSequence(props.sequences[mail.sequence])
            }, 900)
        }
    }, [mail])

    useEffect(() => {
        setSelectedStatus(null)
        if(selectMailToForword && !selectMailToReply) {
            setTimeout(() => {
                let messageContent = formatThreadMessage()
                insertText(` <br /> ---------- Forwarded message --------- <br /><br />${messageContent}`, true)
            }, 300)
        } if(!selectMailToForword && selectMailToReply) {
            if(props.signatureList[mail?.sender]?.content) setTimeout(() => {
                insertText(` <br /><br /><br />${props.signatureList[mail?.sender]?.content}`, true)
            }, 300)
        }
    }, [selectMailToForword, selectMailToReply])

    function insertText(text, html) {
        if (!editorRef.current) return;
        if (html) editorRef.current.execCommand('mceInsertContent', false, text);
        else editorRef.current.execCommand('InsertText', false, text);
    }

    async function getThread() {
        try {
            const res = await Axios({
                url: `/api/mail/thread/${mail?.messageId}`,
                method: 'get',
            });
            setMails(res.data.data)
        } catch (e) {}
    }

    async function markUnread() {
        try {
            const res = await Axios({
                url: `/api/mail/${mail?.messageId}`,
                method: 'patch',
                data: { unread: false }
            });
        } catch (e) {}
    }

    function formatThreadMessage() {
        let messages = mails?.map(t => {
            let txt = t.data?.email?.body || ""
            let sub = t.data?.email?.subject || ""
            let fromAndTo = ""
            const mailDateAsString = ReactDOMServer.renderToStaticMarkup(mailDate(props.sequences, t));
            if(t.task_type === "email") {
                fromAndTo = `
                    From: ${sender?.senderName} &lt;${sender?.smtpImapEmail || sender?.smtpUsername || sender?.alias || sender?.microsoft_email || sender?.google_email}&gt;
                    <br />
                    Sent: ${mailDateAsString}
                    <br />
                    To: ${contact?.First_Name || ""} ${contact?.Last_Name || ""} &lt;${contact?.Email}&gt;
                    <br />
                    Subject: ${sub}
                    <br />
                `
            } else if(t.task_type === "reply") {
                fromAndTo = `
                    From: ${contact?.First_Name || ""} ${contact?.Last_Name || ""} &lt;${contact?.Email}&gt;
                    <br />
                    Sent: ${mailDateAsString}
                    <br />
                    To: ${sender?.senderName} &lt;${sender?.smtpImapEmail || sender?.smtpUsername || sender?.alias || sender?.microsoft_email || sender?.google_email}&gt;
                    <br />
                    Subject: ${sub}
                    <br />
                `
            } else {
                fromAndTo = `
                    From: ${sender?.senderName} &lt;${sender?.smtpImapEmail || sender?.smtpUsername || sender?.alias || sender?.microsoft_email || sender?.google_email}&gt;
                    <br />
                    Sent: ${mailDateAsString}
                    <br />
                    To: &lt;${t.email}&gt;
                    <br />
                    Subject: ${sub}
                    <br />
                `
            }
            return `${fromAndTo} ${txt}`
        })
        let messageContent = messages.join(" <hr /> ")
        return messageContent
    }

    const onDiscard = () => {
        setEmail("")
        setContent("");
        setSelectMailToReply(null)
        setSelectMailToForword(null)
    }

    const generateEmailSubjectBody = async () => {
        if (!props.planUsage || props.chatGPTUsed >= props.planUsage?.chatGPTEmailTemplate?.limit)
          return Swal.fire({
            icon: 'warning',
            title: 'You have exceeded your monthly limit, Contact Support for help.',
            showCancelButton: false,
            confirmButtonText: `Okay`,
            confirmButtonColor: '#0066FF',
          });
    
        setLoadingGenerate(true);
        try {
          let res = await Axios.post('/api/chat-gpt-email-reply', {
            prompt: promptText,
          });
        //   console.log("TEST ", res.data.data)
          setContent(res.data.data?.body);
          props.setChatGPTUsed(props.chatGPTUsed + 1);
          setLoadingGenerate(false);
        } catch (err) {
          console.log('Error in creating sequence ', err);
          setLoadingGenerate(false);
        }
    };

    const onSend = async () => {
        let plainText = htmlToText(content, true)
        if(plainText.trim() === "") return Toast.fire({ icon: 'error', title: "Cannot send mail with no content" });
        if(selectMailToForword && email.trim() === "") return Toast.fire({ icon: 'error', title: "Email address required." });
        // console.log("Testing ", htmlToText(content, true))
        setLoading(true)
        let url = `/api/mail-reply`;
        if(selectMailToForword) url = `/api/mail-forwarding`
        try {
            const res = await Axios({
                url,
                method: 'post',
                data: {
                    messageID: mail?.messageId,
                    content,
                    email,
                    cc: CC,
                    bcc: BCC
                }
            });

            setMails([res.data.data, ...mails])
            setLoading(false)
            Toast.fire({ icon: 'success', title: selectMailToForword?"Mail forwarded successfully.":"Replied to mail." });
            onDiscard()
        } catch (err) {
            setLoading(false)
            console.log("ERR ", err)
            Toast.fire({ icon: 'error', title: `Failed to ${selectMailToForword?"forward":"reply to"} mail` });
        }
    }

    return <div>
        <div id='main-container' className='flex sequence' style={{ height: 32 }}>
            <button
                id='back-button'
                onClick={() => props?.setSelectedMail(null)}
                style={{ border: '3px solid #A0A0A0' }}
                >
                <UilArrowLeft size={26} color="#A0A0A0" />
            </button>
            <p id='sequence-name' className="flex" style={{ fontSize: 16 }}><UilMessage size={16} style={{ marginTop: 5, marginRight: 5 }} /> {sequence?.name}</p>
        </div>
        <div id='subject-section'>
            <div className='mt-2 text-lg font-bold'>
                {mail?.data?.email?.subject}
            </div>
            <div id='right-part'>
                <div>
                    <Select
                        styles={{
                            control: () => ({
                                padding: 1,
                                width: '100%',
                                borderRadius: 5,
                                display: 'flex',
                                border: '1px solid #d7d7d7',
                            }),
                        }}
                        placeholder="Select Outcome"
                        isSearchable={false}
                        options={outcomes}
                        value={outcomes?.find((ot) => ot.value === mail?.outcome) || selectedStatus}
                        onChange={(selected) => {
                            if(selected) props.updateMails(mail?.messageId, { outcome: selected.value }, "Contact outcome updated")
                            setSelectedStatus(selected);
                        }}
                    />
                </div>
                {/* <div className='mr-1'>
                    {mail.unread?
                        <UilEnvelopeOpen size={18} style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => props.updateMails(props.mail._id, { unread: false }, true)} />
                    :
                        <UilEnvelope size={18} style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => props.updateMails(props.mail._id, { unread: true }, true)} />
                    }
                </div> */}
                <div id='second-part' className='flex gap-4'>
                    <div>
                        <span
                            className="csBadge warningOutline ml-2 highlight tooltip"
                            style={{ cursor: 'pointer', padding: 5 }}
                            data-tooltip-content='Archive'
                            onClick={() => props.deleteMails(mail?.messageId)}
                        >
                            <UilArchive size={24} color="#FBD355" />
                        </span>
                    </div>
                    <div>
                        <CustomButton
                            onClick={() => {
                                setContent("")
                                setSelectMailToReply(true)
                                setSelectMailToForword(null)
                            }}
                            title="Reply"
                            variant="primary"
                            icon={<UilCornerUpLeftAlt />}
                            style={{
                                marginTop: 0,
                                minWidth: 'max-content',
                                padding: 6
                            }}
                        />
                    </div>
                    <div>
                        <CustomButton
                            onClick={() => {
                                setContent("")
                                setSelectMailToForword(true)
                                setSelectMailToReply(null)
                            }}
                            iconOnly={true}
                            variant="secondary"
                            icon={<UilCornerUpRightAlt />}
                            id="mail-forwarding"
                            style={{
                                marginTop: 0,
                                minWidth: 'max-content',
                                padding: 6
                            }}
                        />
                        <Tooltip anchorSelect={`#mail-forwarding`}>
                            Forward
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>

        {(selectMailToForword || selectMailToReply) && <div id='single-card' className='csCard mt-2'>
            <div id='compose-and-gpt-button-section' className='flex'>
                <div className='flex flex-col'>
                    <div className='compose-email-reply-forward'>
                        <p style={{ marginTop: selectMailToForword?10:3 }} className='font-semibold'>From:</p>
                        <p className='csBadge info' style={{ width: 'max-content' }}>{sender?.smtpImapEmail || sender?.smtpUsername || sender?.alias || sender?.microsoft_email || sender?.google_email}</p>
                    </div>
                    <div className='compose-email-reply-forward'>
                        <p style={{ marginTop: selectMailToForword?10:3 }} className='font-semibold'>To:</p>
                        {selectMailToReply && <p className='csBadge info' style={{ width: 'max-content' }}>{contact?.Email}</p>}
                        {selectMailToForword && <p>
                            <TextInput
                                id='text-input-field'
                                style={{ display: 'flex' }}
                                value={email}
                                onChange={(n, value) => setEmail(value)}
                                placeholder="example@email.com"
                            />
                        </p>}
                    </div>
                    <div className='compose-email-reply-forward'>
                        <p style={{ marginTop: 10 }} className='font-semibold'>CC:</p>
                        <p>
                            <TextInput
                                id='text-input-field'
                                style={{ display: 'flex' }}
                                value={CC}
                                onChange={(n, value) => setCC(value)}
                                placeholder="example@email.com"
                            />
                        </p>
                    </div>
                    <div className='compose-email-reply-forward'>
                        <p style={{ marginTop: 10 }} className='font-semibold'>BCC:</p>
                        <p>
                            <TextInput
                                id='text-input-field'
                                style={{ display: 'flex' }}
                                value={BCC}
                                onChange={(n, value) => setBCC(value)}
                                placeholder="example@email.com"
                            />
                        </p>
                    </div>
                </div>
                {selectMailToReply && <div id='draft-gpt-mail'>
                    {loadingGenerate ? (
                        <UilSync
                            size="20"
                            className={`rotate mb-4 ml-4`}
                            id="generate-loading"
                        />
                    ) : (
                        <button
                            className="ml-4 csGenerateButton mb-4"
                            disabled={loadingGenerate}
                            id="generate-with-chatgpt"
                            type="button"
                            onClick={() => generateEmailSubjectBody()}
                        >
                            <span className="mr-1">
                            <img src={BlinkGPT} height={22} width={22}/>
                            </span>
                            <span style={{marginTop: 4}}>Draft Email</span>
                        </button>
                    )}
                </div>}
            </div>
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={content}
              onEditorChange={(editedContent) => setContent(editedContent)}
              init={{
                height: 300,
                menubar: false,
                elementpath: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'code',
                  'emoticons',
                ],
                // setup: function (editor) {
                //   editor.ui.registry.addButton('unsubButton', {
                //     text: 'Add Unsubscribe Link',
                //     onAction: function () {
                //       insertText(UNSUBSCRIBE_LINK, true);
                //     },
                //   });
                // },
                toolbar_mode: 'sliding',
                toolbar:
                  'blocks | ' +
                  'bold italic forecolor fontsize | code link emoticons | alignleft | aligncenter ' +
                  'alignright | alignjustify | bullist | numlist | ' +
                  'image | removeformat | unsubButton',
                image_title: true,  
                automatic_uploads: true,
                file_picker_types: 'image',
                contextmenu: 'copy paste cut | link ',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                
                    /*
                      Note: In modern browsers input[type="file"] is functional without
                      even adding it to the DOM, but that might not be the case in some older
                      or quirky browsers like IE, so you might want to add it to the DOM
                      just in case, and visually hide it. And do not forget do remove it
                      once you do not need it anymore.
                    */
                
                    input.onchange = function () {
                        var file = this.files[0];
                    
                        var reader = new FileReader();
                        reader.onload = function () {
                            /*
                                Note: Now we need to register the blob in TinyMCEs image blob
                                registry. In the next release this part hopefully won't be
                                necessary, as we are looking to handle it internally.
                            */
                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache = editorRef.current.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                    
                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };
                
                    input.click();
                },
              }}
            />
            <div className='flex'>
                <CustomButton
                    variant="secondary orange"
                    onClick={() => onDiscard()}
                    disabled={loading}
                    title={"Discard"}
                    style={{ marginRight: 20, marginLeft: 'auto' }}
                />
                <CustomButton
                    variant="primary"
                    onClick={() => onSend()}
                    title={"Send"}
                    disabled={loading}
                />
            </div>
        </div>}

        {mails?.map((task) => (
            <MailCard 
                task={task}
                sender={sender}
                contact={contact}
                sequences={props.sequences}
                onReply={setSelectMailToReply}
                replyTo={selectMailToReply}
            />
        ))}
    </div>
}

const MailCard = (props) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const editorRef = useRef(null);
    
    const onDiscard = () => {
        setContent("");
        props.onReply(null)
    }

    const onSend = async () => {
        let plainText = htmlToText(content, true)
        if(plainText.trim() === "") return Toast.fire({ icon: 'error', title: "Cannot send mail with no content" });
        // console.log("Testing ", htmlToText(content, true))
        setLoading(true)
        try {
            const res = await Axios({
                url: `/api/mail-reply`,
                method: 'post',
                data: {
                    taskID: props.task.id,
                    content
                }
            });

            setLoading(false)
            Toast.fire({ icon: 'success', title: "Replied to mail." });
        } catch (err) {
            setLoading(false)
            Toast.fire({ icon: 'error', title: "Failed to reply to mail" });
        }
    }

    function showToAddress() {
        if(props.task?.task_type === 'reply' && !props.task?.show_reply_report) {
            // return `${props.sender?.senderName} (Me) <${props.sender?.smtpImapEmail || props.sender?.smtpUsername || props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email}>`
            return `${props.sender?.smtpImapEmail || props.sender?.smtpUsername || props.sender?.alias || props.sender?.microsoft_email || props.sender?.google_email} (Me)`
        }
        else if(props.task?.task_type === 'forwarding') return `${props.task?.email} (Forwarded)`
        else {
            // return `${props.contact?.First_Name || ""} ${props.contact?.Last_Name || ""} <${props.contact?.Email}>`
            return `${props.contact?.Email || ""}`
        }
    }

    return <>
        <div id='single-card' className='csCard mt-2'>
            <div className='mb-3'>
                <div id='from-to-section'>
                    <div>
                        <div>
                            {(props.task?.task_type === 'email' || props.task?.task_type === 'forwarding') ?
                                <div><span className='font-semibold'>{props.sender?.senderName} (Me)</span> <span style={{ fontSize: 14 }}>{`<${props.sender?.alias || props.sender?.google_email || props.sender?.microsoft_email || props.sender?.smtpImapEmail}>`}</span></div>
                            : props.task?.show_reply_report ?
                                <div><span className='font-semibold'>{props.sender?.senderName} (Me)</span> <span style={{ fontSize: 14 }}>{`<${props.sender?.alias || props.sender?.google_email || props.sender?.microsoft_email || props.sender?.smtpImapEmail}>`}</span></div>
                            : <div><span className='font-semibold'>{props.contact?.First_Name || ""} {props.contact?.Last_Name || ""}</span> <span style={{ fontSize: 14 }}>{`<${props.contact?.Email}>`}</span></div>}
                        </div>
                        <div><span className='font-semibold'>To:</span> <span className='csLink' style={{ cursor: "default" }}>{showToAddress()}</span></div>
                        {props.task?.data?.email?.cc && <div><span className='font-semibold'>CC:</span> <span className='csLink' style={{ cursor: "default" }}>{props.task?.data?.email?.cc}</span></div>}
                        {props.task?.data?.email?.bcc && <div><span className='font-semibold'>BCC:</span> <span className='csLink' style={{ cursor: "default" }}>{props.task?.data?.email?.bcc}</span></div>}
                    </div>
                    <div style={{ fontSize: 13 }}>
                        <div>
                            {mailDate(props.sequences, props.task)}
                        </div>
                        {props.task?.show_reply_report && <div className='flex ml-auto' style={{ textAlign: 'end', justifyContent: 'end' }}>
                            {props.task?.completed && <div>
                                <span className="csBadge success csCenter" style={{ padding: 5 }} id={`sent-${props.task._id}`}>
                                    <UilEnvelopeCheck size={16} />
                                </span>
                                <Tooltip anchorSelect={`#sent-${props.task._id}`} place="top">
                                    Email Sent
                                </Tooltip>
                            </div>}
                            {props.task?.error_log && <div>
                                <span className="csBadge danger csCenter" style={{ padding: 5 }} id={`error-${props.task._id}`}>
                                    <UilExclamationOctagon size={16} />
                                </span>
                                <Tooltip anchorSelect={`#error-${props.task._id}`} place="top">
                                    Failed to {props.task?.task_type === "reply"?"send reply":"forward mail"}
                                </Tooltip>
                            </div>}
                            {props.task?.opened && <div className='ml-2'>
                                <span className="csBadge success csCenter" style={{ padding: 5 }} id={`opened-${props.task._id}`}>
                                    <UilEnvelopeOpen
                                        size={16}
                                    />
                                </span>
                                <Tooltip anchorSelect={`#opened-${props.task._id}`} place="top">
                                    Email Opened
                                </Tooltip>
                            </div>}
                            {props.task?.clicked && <div className='ml-2'>
                                <span className="csBadge success csCenter" style={{ padding: 5 }} id={`clicked-${props.task._id}`}>
                                    <UilLink size={16} />
                                </span>
                                <Tooltip anchorSelect={`#clicked-${props.task._id}`} place="top">
                                    Link in Email Clicked
                                </Tooltip>
                            </div>}
                        </div>}
                    </div>
                    {/* <div>
                        <UilCornerUpLeftAlt className='highlight tooltip' data-tooltip-content='Reply' size={18} style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => props.onReply(props.task)} />
                    </div>
                    <div>
                        <UilCornerUpRightAlt className='highlight tooltip' data-tooltip-content='Forword' size={18} style={{ marginTop: 8, cursor: 'pointer' }} onClick={() => console.log(props.task)} />
                    </div> */}
                </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: props.task?.data?.email?.body ? props.task?.data?.email?.body.replace(/<a href="([^"]+)">/g, '<a href="$1" target="_blank">') : "" }} style={{
              backgroundColor: '#F2F2F2',
              padding: 15,
              borderRadius: 10,
            }}>
                {/* {htmlToText()} */}
            </div>
        </div>
        {/* {props.replyTo && props.replyTo?.id === props.task?.id && <div className='csCard mt-2'>
            <div className='flex mb-2'>
                <span style={{ marginTop: 3 }}>To:</span>
                <span className='csBadge info csCenter ml-2'>{props.contact?.Email}</span>
            </div>
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={content}
              onEditorChange={(editedContent) => setContent(editedContent)}
              init={{
                height: 300,
                menubar: false,
                elementpath: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'code',
                  'emoticons',
                ],
                // setup: function (editor) {
                //   editor.ui.registry.addButton('unsubButton', {
                //     text: 'Add Unsubscribe Link',
                //     onAction: function () {
                //       insertText(UNSUBSCRIBE_LINK, true);
                //     },
                //   });
                // },
                toolbar_mode: 'sliding',
                toolbar:
                  'blocks | ' +
                  'bold italic forecolor | code link emoticons | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist | ' +
                  'removeformat | unsubButton',
                contextmenu: 'copy paste cut | link ',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <div className='flex'>
                <CustomButton
                    variant="secondary orange"
                    onClick={() => onDiscard()}
                    disabled={loading}
                    title={"Discard"}
                    style={{ marginRight: 20, marginLeft: 'auto' }}
                />
                <CustomButton
                    variant="primary"
                    onClick={() => onSend()}
                    title={"Send"}
                    disabled={loading}
                />
            </div>
        </div>} */}
    </>
}