import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateTemplate from './createTemplate';
import { PixieProvider } from './pixieContext';
import { PixieEditor } from './pixieEditor';
import { CustomSubNav, Loader, PageTitle, UpgradePlan, useAPI, useRouteCheck } from 'components/lib';
import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilListUl
} from '@iconscout/react-unicons';

export default function EmailTemplate() {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTab, setSelectedTab] = useState('templates');
  let { id } = useParams();
  let navigator = useNavigate();
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );

  useEffect(() => {
    if (id !== 'create') getTemplate();
  }, []);

  const getTemplate = async () => {
    try {
      let result = await Axios.get(`/api/template/${id}`);
      setSelectedTemplate(result?.data?.data);
    } catch (err) {
      console.log('Error in Fetching template ', err);
    }
  };

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <>
      <CustomSubNav
        selected={selectedTab}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />
      <div style={{ textAlign: 'center' }}>
        <PageTitle 
          title="Email Template"
          description="Create/Edit email template to add it to Outreach Sequences."
          headerStyle={{ justifyContent: 'center', marginTop: 10 }}
        />
      </div>
      <PixieProvider>
        <PixieEditor />
        <CreateTemplate
          width="97%"
          marginTop={20}
          top={210}
          // position="fixed"
          selected={selectedTemplate}
          onClose={() => navigator(`/outreach/templates`)}
        />
      </PixieProvider>
    </>
  );
}
