import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import CustomDialog from 'components/custom/customDialog';
import CustomModal from 'components/custom/customModal';
import CustomToggle from 'components/custom/customToggle';
import { CustomSelect } from 'components/customselect/customselect';
import {
  AuthContext,
  Button,
  CustomDropdown,
  CustomSearch,
  CustomTable,
  Dropdown,
  Expander,
  Loader,
  PageTitle,
  TextInput,
  UpgradePlan,
  useAPI,
  useRouteCheck,
  WhitelabelContext,
} from 'components/lib';
import {
  UilEnvelope,
  UilCheckCircle,
  UilEdit,
  UilUsersAlt,
  UilStar,
  UilArchive,
  UilPlusCircle,
  UilFolder,
  UilFolderSlash,
  UilEnvelopeOpen,
  UilLink,
  UilEnvelopeReceive,
  UilArrowLeft,
  UilCopy,
  UilRefresh,
  UilTrash,
  UilAngleDown,
  UilAngleUp,
  UilExternalLinkAlt,
  UilTimes,
  UilFilter
} from '@iconscout/react-unicons';
import { UisStar } from '@iconscout/react-unicons-solid'
import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import { Tooltip } from 'react-tooltip';
import './templates.scss';
import useWindowResize from 'components/hooks/getCurrentWindowWidth';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function CreateFolder(props) {
  const [folderName, setFolderName] = useState('');

  async function createFolder() {
    try {
      if (folderName.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Enter a valid folder name',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
        return;
      }

      await Axios.post(`/api/list/folders`, {
        name: folderName,
      });
      if (props.onCreate) props.onCreate();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in creating new folder',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    }
  }

  return (
    <div>
      <h1 className="text-modal-header">Create New Folder</h1>
      <p className="text-modal-description">
        Organize your sequences, templates or lists in folders.
      </p>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <TextInput
          required={true}
          label="Folder Name"
          value={folderName}
          onChange={(name, value) => {
            setFolderName(value);
          }}
          placeholder="Enter a Folder Name"
        />

        <Row>
          <div
            style={{
              display: 'flex',
              margin: '0 16px',
              marginTop: 11,
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              className='custom-buttons'
              style={{ marginLeft: 'auto', marginRight: 5 }}
              variant="primary grey"
              text="Cancel"
              action={() => props?.onClose?.()}
            />
            <Button
              className='custom-buttons'
              variant="primary"
              style={{ marginLeft: 15 }}
              text="Save"
              action={() => createFolder()}
            />
          </div>
        </Row>
      </div>
    </div>
  );
}

function MoveToFolder(props) {
  const [folderID, setFolderID] = useState('');
  const [folderName, setFolderName] = useState('');

  async function moveToFolder() {
    try {
      if (folderID.length < 3) {
        Swal.fire({
          icon: 'error',
          title: 'Please Select a folder',
          showCancelButton: false,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066ff',
        });
        return;
      }

      await Axios.put(`/api/template/${props.templateID.join(',')}`, {
        folder: folderID,
      });
      if (props.onSuccess) props.onSuccess();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in moving template to folder',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
    }
  }

  return (
    <div>
      <div>
        <h1 className='text-2xl text-modal-header'>Move Template(s) to Folder</h1>
        <p className='text-modal-description'>Moving {props.templateID.length} Template(s) to Folder</p>
      </div>

      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <Select
          placeholder="Select Folder"
          defaultValue={folderID}
          options={props?.folders?.map((userObj) => ({
            label: userObj?.name,
            value: userObj?.id,
          }))}
          onChange={(selected) => {
            setFolderID(selected.value);
            setFolderName(selected?.label)
          }}
        />

        <Row>
          <div
            style={{
              display: 'flex',
              margin: '0 16px',
              marginTop: 11,
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <Button
              className='custom-buttons'
              style={{ marginLeft: 'auto', marginRight: 5 }}
              variant="primary grey"
              text="Cancel"
              action={() => props?.onClose?.()}
            />
            <Button
              className='custom-buttons'
              variant="primary"
              style={{ marginLeft: 15 }}
              text={`Move ${folderName ? "to " + folderName : ""}`}
              action={() => moveToFolder()}
            />
          </div>
        </Row>
      </div>
    </div>
  );
}

export default function Templates() {
  const authContext = useContext(AuthContext);
  const [viewTaskTemplate, setViewTaskTemplate] = useState(false);
  const [selectAll, setSelectAll] = useState([]);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [tasksList, setTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState('');
  const [searchFolder, setSearchFolder] = useState('');
  const [users, setUsers] = useState([]);
  const [folders, setFolders] = useState([]);
  const [searchOwnedBy, setSearchOwnedBy] = useState(authContext?.user?.id);
  const [showArchived, setShowArchived] = useState(false);
  const [showStarred, setShowStarred] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [moveToFolder, setMoveToFolder] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isSorted, setIsSorted] = useState('date_created');
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [isloading,setIsloading] = useState(true)
  const [filterVisible, setFilterVisible] = useState(false);
  const [showDeleteFolderConfirmationModal, setShowDeleteFolderConfirmationModal] = useState(false);
  const [selectedFolderToDelete, setSelectedFolderToDelete] = useState(null);
  const deviceWidth = useWindowResize();

  const navigator = useNavigate();
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );
  const { whitelabelDetails } = useContext(WhitelabelContext);

  const columns = [
    {
      Header: (
        <>
          <CustomDropdown
            type="select"
            checked={selectAll?.length === data?.length}
            disabled={selectAll?.length === 0}
            indeterminate={
              selectAll?.length > 0 && selectAll?.length < data?.length
            }
            selectAction={() => {
              if (selectAll.length === data.length) setSelectAll([]);
              else if (selectAll?.length > 0 && selectAll?.length < data?.length)
                setSelectAll([]);
              else {
                let selectArr = [];
                for (let i = 0; i < data?.length; i++) {
                  selectArr.push(data[i]._id);
                }
                setSelectAll(selectArr);
              }
            }}
            items={[
              {
                title: 'Star',
                icon: <UisStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                action: () => {
                  starOrUnstar(selectAll, 'star', true);
                },
              },
              {
                title: 'Unstar',
                icon: <UilStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                action: () => {
                  starOrUnstar(selectAll, 'unstar', true);
                },
              },
              {
                title: searchFolder ? "Remove from Folder" : 'Move to Folder',
                icon: searchFolder ? <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                action: () => {
                  if(searchFolder) removeFromFolder(selectAll)
                  else setMoveToFolder(true);
                },
              },
              {
                title: !showArchived ? 'Archive' : 'Un-Archive',
                icon: showArchived ? <UilRefresh size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilArchive size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                action: () => {
                  if (showArchived) archiveList(selectAll, false, true);
                  else archiveList(selectAll, true, true);
                },
              },
            ]}
            align="left"
          />
        </>
      ),
      style: { width: '100px' },
      Footer: '',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { cells } = row;
        let id = cells[0].value;
        let isSelected = false;

        if (selectAll.find((item) => item === id)) isSelected = true;
        return (
          <input
            type="checkbox"
            className="csCheckbox"
            style={{ marginLeft: 10 }}
            defaultChecked={isSelected}
            onChange={(e) => {
              if (selectAll.find((item) => item === id))
                setSelectAll(selectAll.filter((item) => item !== id));
              else setSelectAll([...selectAll, id]);
            }}
          />
        );
      }, // formatting date
    },
    {
      Header: '',
      style: { width: '75px' },
      accessor: 'starred',
      disableSortBy: true, // disable sorting for the column
      Cell: ({ row, value }) => {
        const { cells } = row;
        if (value)
          return <UisStar 
            size={18} 
            style={{ cursor: 'pointer', color: '#F9C00C' }}
            onClick={() => starOrUnstar([cells[0].value], 'unstar')} 
          />;
        else
          return <UilStar 
            size={18} 
            style={{ cursor: 'pointer', color: '#F9C00C' }}
            onClick={(e) => starOrUnstar([cells[0].value], 'star')}
          />
      }, // formatting date
    },
    {
      Header: '',
      style: { width: '130px' },
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <div className="text-center flex justify-start">
            <span
              style={{
                textTransform: 'capitalize',
                display: 'inline-flex',
                width: 85,
              }}
              className="csBadge info"
            >
              {value === 'Email' ? (
                <UilEnvelope
                  size={16}
                  style={{ marginTop: 4, marginRight: 5 }}
                />
              ) : null}
              {value === 'task' ? (
                <UilCheckCircle
                  size={16}
                  style={{ marginTop: 4, marginRight: 5 }}
                />
              ) : null}
              {value}
            </span>
          </div>
        );
      },
    },
    {
      Header: (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (isSorted === '' || isSorted !== 'name') setIsSorted('name');
            else if (isSorted === 'name') setIsSortedDesc(!isSortedDesc);
          }}
        >
          Name
          <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginTop: 1,
            }}
          >
            {isSorted === 'name' ? (
              isSortedDesc ? (
                <UilAngleDown />
              ) : (
                <UilAngleUp />
              )
            ) : (
              ''
            )}
          </span>
        </div>
      ),
      style: { width: '500px' },
      Footer: 'Name',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        return (
          <Link
            to={`${original.type === "Email"?`/outreach/templates/${original._id}`:`/task-types/${original.task_type_id}/templates/${original._id}`}`}
            className="csCustomeLink"
          >
            {value} <UilExternalLinkAlt size={16} className="csLinkIcon shrink-0" />{' '}
            <span className="csLinkEmpty"></span>
          </Link>
        );
      },
    },
    {
      Header: <span className="csCenter">Performance</span>,
      style: { width: '150px' },
      Footer: 'Performance',
      accessor: 'performance',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        if(original.type === "Email") return <div className='flex csCenter'>
          <div className='flex' data-tooltip-content="Opens" data-tooltip-show="true"><UilEnvelopeOpen size={18} className="mr-1" style={{ marginTop: 2 }} /> {original.opens}</div>
          <div className='flex ml-2' data-tooltip-content="Clicks" data-tooltip-show="true"><UilLink size={18} className="mr-1" style={{ marginTop: 2 }} />{original.clicks}</div>
          <div className='flex ml-2' data-tooltip-content="Replies" data-tooltip-show="true"><UilEnvelopeReceive size={18} className="mr-1" style={{ marginTop: 2 }} />{original.replies}</div>
        </div>
        return <span className="csCenter" data-tooltip-content="Completed" data-tooltip-show="true"><UilCheckCircle size={18} className="mr-1" style={{ marginTop: 2 }} />{original.completed}</span>
      },
    },
    {
      Header: <span className="csCenter">Actions</span>,
      style: { width: '100px' },
      Footer: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { cells, original } = row;
        let starred = cells[1].value;
        let isArchived = cells[1].row.original.archived;
        let label = 'Star';
        if (starred) label = 'Unstar';
        return (
          <div className="csCenter">
            <CustomDropdown
              right={true}
              items={[
                {
                  key: 'edit',
                  title: 'Edit',
                  icon: <UilEdit size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if (original?.type === 'Email')
                      navigator(`/outreach/templates/${original._id}`);
                  },
                },
                {
                  key: label,
                  title: label,
                  icon: starred ? <UilStar size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UisStar size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if (starred) starOrUnstar([cells[0].value], 'unstar');
                    else starOrUnstar([cells[0].value], 'star');
                  },
                },
                ...(cells[2].value === 'Email' ? [
                  {
                    key: 'Clone',
                    title: 'Clone',
                    icon: <UilCopy size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                    action: function() {
                      cloneTemplate(cells[0].value);
                    }
                  },
                ] : []),
                {
                  key: 'folder',
                  title: original.folder ? "Remove from Folder" : 'Move to Folder',
                  icon: original.folder ? <UilFolderSlash size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilFolder size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    if(original.folder) removeFromFolder(selectAll)
                    else setMoveToFolder(original._id);
                  },
                },
                {
                  key: 'delete',
                  title: 'Delete',
                  icon: <UilTrash size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    deleteTemplate(original._id, original.name);
                  },
                },
                {
                  key: isArchived ? 'Un-Archive' : 'Archive',
                  title: isArchived ? 'Un-Archive' : 'Archive',
                  icon: isArchived ? <UilRefresh size={18} style={{ marginTop: 2, marginRight: 5 }} /> : <UilArchive size={18} style={{ marginTop: 2, marginRight: 5 }} />,
                  action: () => {
                    archiveList([cells[0].value], !isArchived);
                  },
                },
              ]}
              align="left"
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setPage(0)
  }, [searchFolder])

  useEffect(() => {
    let searchParams = new URLSearchParams();
    searchParams.append('limit', perPage);
    searchParams.append('skip', page);
    searchParams.append('sortBy', isSorted);
    searchParams.append('sortType', isSortedDesc ? 'DESC' : 'ASC');
    searchParams.append('search', search)
    if (showArchived) searchParams.append('archived', true);
    if (showStarred) searchParams.append('starred', true);
    if (searchFolder.length > 0) searchParams.append('folder', searchFolder);
    searchParams.append('userID', searchOwnedBy);

    fetchData(searchParams.toString());
  }, [
    showArchived,
    showStarred,
    searchFolder,
    searchOwnedBy,
    perPage,
    page,
    isSorted,
    isSortedDesc,
    search
  ]);

  useEffect(() => {
    getTaskTypes();
    fetchUsers();
    fetchFolders();
  }, []);

  async function cloneTemplate(templateID) {
    try {
      const response = await Axios.post('/api/template/clone', { templateID });

      if (response.status === 200) {
        setData((previousData) => [response.data, ...previousData]);
        Toast.fire({ icon: 'success', title: `Email Template cloned.` });
      }
    } catch (error) {
      console.error(error);

      if(error?.response?.status === 400) return Toast.fire({ icon: 'error', title: error?.response?.data });

      Toast.fire({ icon: 'error', title: 'Unable to clone template' });
    }
  }

  const deleteTemplate = async (templateID, templateName) => {
    Swal.fire({
      icon: 'info',
      title: `Permanently Delete ${templateName}?`,
      // html: `<h5>Can not show Monthly Chart data for more than 12 Months</h5>`,
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#D14343',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Axios.delete(`/api/template/${templateID}`);
          fetchData();
        } catch (err) {
          Swal.fire({
            icon: 'error',
            title: 'Error in deleting',
            showCancelButton: false,
            confirmButtonText: `Ok`,
            confirmButtonColor: '#0066ff',
          });
        }
      }
    });
  };

  const fetchData = async (searchQuery) => {
    setIsloading(true)
    try {
      let url = '/api/template';
      if (searchQuery?.length > 0) {
        url += '?' + searchQuery;
      }
      let result = await Axios.get(url);
      setData(result?.data?.data);
      setListCount(result.data.count);
      setTotalPages(Math.ceil(result.data.count / perPage));
    } catch (err) {
      console.log('Error in Fetching templates ', err);
    }
    setIsloading(false)
  };

  async function fetchUsers() {
    try {
      let result = await Axios.get(`/api/users`);
      let users = result?.data?.data?.map((userObj) => ({
        label: userObj?.name,
        value: userObj?.id,
      }));
      users.unshift({
        label: "Owned by Anyone",
        value: '',
      });
      setUsers(users);
      setIsOwner(result?.data?.isOwner);
      console.log('Successfully fetched ', result?.data?.isOwner)
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  async function fetchFolders() {
    try {
      let url = '/api/lists/folders';
      let result = await Axios.get(url);
      setFolders(result?.data?.data || []);
    } catch (err) {
      console.log('Error in getting data ', err);
    }
  }

  async function deleteFolder(folderID) {
    try {
      await Axios.delete(`/api/lists/folders/${folderID}`);
      fetchFolders();
      if (searchFolder === folderID) {
        setPageTitle("");
        setSearchFolder("");
      }
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  async function updateTemplate(id, body) {
    try {
      await Axios.put(`/api/template/${id}`, body);

      let searchParams = new URLSearchParams();
      searchParams.append('limit', perPage);
      searchParams.append('skip', page);
      searchParams.append('sortBy', isSorted);
      searchParams.append('sortType', isSortedDesc ? 'DESC' : 'ASC');
      if (showArchived) searchParams.append('archived', true);
      if (showStarred) searchParams.append('starred', true);
      if (searchFolder.length > 0) searchParams.append('folder', searchFolder);
      searchParams.append('userID', searchOwnedBy);

      fetchData(searchParams.toString());
      
      return true;
    } catch (err) {
      console.log('Error in updating list! ', err);
      return false;
    }
  }

  const starOrUnstar = (id, value, bulk) => {
    if (!id || id.length === 0 || !value) return;
    if (value === 'star') {
      updateTemplate(id.join(','), {
        starred: true,
      });
    } else if (value === 'unstar') {
      updateTemplate(id.join(','), {
        starred: false,
      });
    }
    setData((oldData) => {
      let newData = oldData.map((oldDataItem) => {
        if (id.find((i) => i === oldDataItem._id))
          oldDataItem.starred = value === 'star' ? true : false;
        return oldDataItem;
      });
      if (showStarred) {
        newData = newData.filter((oldDataItem) => {
          if (id.find((i) => i === oldDataItem._id)) return false;
          return oldDataItem;
        });
      }
      return newData;
    });
  };

  const archiveList = (id, value, bulk) => {
    if (!id || id.length === 0) return;
    updateTemplate(id.join(','), {
      archived: value,
    });
    setData((oldData) => {
      let newData = oldData.filter((oldDataItem) => {
        if (id.find((i) => i === oldDataItem._id)) return false;
        return oldDataItem;
      });
      return newData;
    });
  };

  const removeFromFolder = (id) => {
    if (!id || id.length === 0) return;
    updateTemplate(id.join(','), {
      folder: "",
    });
    setData((oldData) => {
      let newData = oldData.map((oldDataItem) => {
        if (id.find((i) => i === oldDataItem._id))
          oldDataItem.folder = "";
        return oldDataItem;
      });
      return newData;
    });
  };

  const getTaskTypes = async (inputValue) => {
    try {
      const res = await Axios({
        url: `/api/task-types?template=true&limit=10&search=${inputValue}`,
        method: 'get',
      });
      const data = res?.data?.data;
      setTaskList(data);
      return data;
    } catch (err) {
      console.log('ERROR ', err.response);
      return [];
    }
  };

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Templates | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <Row>
        <Col id='templates-filter' xs={12} lg={3} className="listFilter">
          <div
            style={{
              height: '80vh',
              borderRight: '1px solid #D7D7D7',
              paddingRight: 30,
              borderRadius: 0,
            }}
            className="templatesContaier"
          >
            <CustomSearch
              value={search}
              onChange={(e) => {setPage(0); setSearch(e);}}
              placeholder={'Search Templates'}
            />
            <CustomToggle
              title="Starred"
              icon={<UilStar />}
              onChange={(starred) => {
                if (starred) {
                  setPage(0); 
                  setShowStarred(true);
                }
                else setShowStarred(false);
              }}
            />
            <CustomSelect
              dontCollapseOnDelete={true}
              icon={<UilFolder />}
              zIndex={'0'}
              customClearAction={() => {
                setPageTitle("")
                setSearchFolder('');
              }}
              new={() => {
                setShowCreateFolder(true);
              }}
              onDelete={(folderID) => {
                const folder = folders?.find(folder => folder.id === folderID);
                setSelectedFolderToDelete(folder);
                setShowDeleteFolderConfirmationModal(true);
              }}
              onChange={(selectedFolder) => {
                if (selectedFolder?.value === 'All Folders') {
                  setPageTitle("")
                  setSearchFolder('');
                  return 
                }
                setSearchFolder(selectedFolder.value);
                setPageTitle(`Templates in Folder ${selectedFolder.label}`)
              }}
              default={{ label: 'Folders', value: 'All Folders' }}
              options={folders?.map((folderObj) => ({
                label: folderObj.name,
                value: folderObj.id,
              }))}
              style={{ width: '100%', marginLeft: 'auto' }}
            />
            {isOwner && (
              <Expander
                style={{ width: '100%', marginTop: 15 }}
                label="Owned By"
                icon={<UilUsersAlt />}
              >
                {
                  <Select
                    placeholder="Select User"
                    defaultValue={'All'}
                    value={users?.find((userObj) => userObj.value === searchOwnedBy) || null}
                    options={users}
                    onChange={(selected) => {
                      setPage(0);
                      setSearchOwnedBy(selected?.value);
                    }}
                  />
                }
              </Expander>
            )}
            <CustomToggle
              title="Archived"
              icon={<UilArchive />}
              onChange={(archived) => {
                if (archived) {
                  setPage(0);
                  setShowArchived(true);
                }
                else setShowArchived(false);
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={9}>
          {showCreateFolder && (
            <CustomDialog>
              <CreateFolder
                onClose={() => setShowCreateFolder(false)}
                onCreate={() => {
                  fetchFolders();
                  setShowCreateFolder(false);
                }}
              />
            </CustomDialog>
          )}

          {moveToFolder && (
            <CustomDialog>
              <MoveToFolder
                templateID={selectAll?.length > 0 ? selectAll : [moveToFolder]}
                folders={folders}
                onClose={() => setMoveToFolder(false)}
                onSuccess={() => {
                  setMoveToFolder(false);
                }}
              />
            </CustomDialog>
          )}
          <Row className="mt-4">
            <Col xs={12} lg={9} className='flex'>
              {pageTitle && <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  outline: '3px solid #A0A0A0',
                  height: 40,
                  width: 40,
                  borderRadius: 5,
                  marginTop: 5
                }}
                className="mr-5 text-white"
                role="button"
                id="show-all-templates"
                onClick={() => {
                  setPageTitle("")
                  setSearchFolder('');
                }}
              >
                <UilArrowLeft size={25} color="#A0A0A0" />
              </button>}
              <Tooltip anchorSelect="#show-all-templates" place="top">
                View all Templates
              </Tooltip>
              <PageTitle
                title={pageTitle || "Email & Task Templates"}
                description="Create & Manage Outreach Templates."
                tooltip="Templates are used in Outreach Sequences."
                headerStyle={{ marginTop: -3 }}
              />
            </Col>
            <Col
              xs={12}
              lg={3}
              style={{
                display: 'flex',
                marginBottom: 10,
              }}
              id='create-new-template-button-container'
            >
              <div id='create-new-template-button'>
                <Dropdown
                  label="Create New"
                  style={{ margin: 0, gap: 0 }}
                  icon={<UilPlusCircle />}
                  variant="primary"
                  defaultAction={() => console.log('Perform Default Action...')}
                  items={[
                    {
                      title: 'Create Email Template',
                      action: () => {
                        navigator(`/outreach/templates/create`);
                      },
                    },
                    {
                      title: 'Create Task Template',
                      action: () => {
                        setViewTaskTemplate(true);
                      },
                    },
                  ]}
                  align="right"
                  leftAligned={true}
                />
              </div>
            </Col>
            <div id='mobile-template-filter' style={{ flexGrow: 1 }}>
              <CustomButton
                onClick={() => setFilterVisible(prev => !prev)}
                title={filterVisible ? 'Hide Filters' : 'View Filters'}
                variant="secondary"
                icon={filterVisible ? <UilTimes /> : <UilFilter />}
                style={{ marginBottom: 16 }}
              />
              <div
                style={{
                  borderRadius: 0,
                  display: filterVisible ? 'block' : 'none',
                  marginTop: 8
                }}
              >
                <CustomSearch
                  value={search}
                  onChange={(e) => {setPage(0); setSearch(e);}}
                  placeholder={'Search Templates'}
                />
                <CustomToggle
                  title="Starred"
                  icon={<UilStar />}
                  onChange={(starred) => {
                    if (starred) {
                      setPage(0);
                      setShowStarred(true);
                    }
                    else setShowStarred(false);
                  }}
                />
                <CustomSelect
                  dontCollapseOnDelete={true}
                  icon={<UilFolder />}
                  zIndex={'0'}
                  customClearAction={() => {
                    setPageTitle("")
                    setSearchFolder('');
                  }}
                  new={() => {
                    setShowCreateFolder(true);
                  }}
                  onDelete={(folderID) => {
                    const folder = folders?.find(folder => folder.id === folderID);
                    setSelectedFolderToDelete(folder);
                    setShowDeleteFolderConfirmationModal(true);
                  }}
                  onChange={(selectedFolder) => {
                    if (selectedFolder?.value === 'All Folders') {
                      setPageTitle("")
                      setSearchFolder('');
                      return 
                    }
                    setSearchFolder(selectedFolder.value);
                    setPageTitle(`Templates in Folder ${selectedFolder.label}`)
                  }}
                  default={{ label: 'Folders', value: 'All Folders' }}
                  options={folders?.map((folderObj) => ({
                    label: folderObj.name,
                    value: folderObj.id,
                  }))}
                  style={{ width: '100%', marginLeft: 'auto' }}
                />
                {isOwner && (
                  <Expander
                    style={{ width: '100%', marginTop: 15 }}
                    label="Owned By"
                    icon={<UilUsersAlt />}
                  >
                    {
                      <Select
                        placeholder="Select User"
                        defaultValue={'All'}
                        value={users?.find((userObj) => userObj.value === searchOwnedBy) || null}
                        options={users}
                        onChange={(selected) => {
                          setPage(0);
                          setSearchOwnedBy(selected?.value);
                        }}
                      />
                    }
                  </Expander>
                )}
                <CustomToggle
                  title="Archived"
                  icon={<UilArchive />}
                  onChange={(archived) => {
                    if (archived) {
                      setPage(0);
                      setShowArchived(true);
                    }
                    else setShowArchived(false);
                  }}
                />

                {showCreateFolder && (
                  <CustomDialog>
                    <CreateFolder
                      onClose={() => setShowCreateFolder(false)}
                      onCreate={() => {
                        fetchFolders();
                        setShowCreateFolder(false);
                      }}
                    />
                  </CustomDialog>
                )}

                {moveToFolder && (
                  <CustomDialog>
                    <MoveToFolder
                      templateID={selectAll?.length > 0 ? selectAll : [moveToFolder]}
                      folders={folders}
                      onClose={() => setMoveToFolder(false)}
                      onSuccess={() => {
                        setMoveToFolder(false);
                      }}
                    />
                  </CustomDialog>
                )}
              </div>
            </div>
          </Row>
          {!isloading ? (
            listCount > 0 ? (
            <CustomTable
              hasFooter={false}
              columns={columns}
              data={data}
              count={listCount}
              perPage={perPage}
              onClick={(row) => {}}
              setPerPage={setPerPage}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
            )
          :
          (<div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
                textAlign: 'center',
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto', marginBottom: 10 }} viewBox="0 0 100 100">
                <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
              </svg> 
              No templates found.
              <div style={{ margin: 'auto', marginTop: '0 !important' }}>
                <CustomButton
                  className=""
                  variant="primary"
                  onClick={() => navigator(`/outreach/templates/create`)}
                  title="Create Email Template"
                />
              </div>
            </div>
          </div>)
          ): (<div
          className="csFormLoader"
          style={{ background: `rgba(255, 255, 255, 0.5)`,marginTop:"10rem"}}
          >
            <Loader />
          </div>)}
        </Col>
        <Tooltip anchorSelect='[data-tooltip-show="true"]' place="bottom" />
        {showDeleteFolderConfirmationModal && (
          <CustomDialog width={200}>
            <h2 className="text-2xl text-modal-header">
              Deleting Folder {selectedFolderToDelete.name}.
            </h2>
            <p style={{ paddingLeft: 15 }}>
              Assets within the folder will not be deleted.
            </p>
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Col md={6} className='addTopMargin'>
                <Button
                  style={{ width: '100%' }}
                  text="Cancel"
                  variant="secondary grey"
                  action={() => {
                    setSelectedFolderToDelete(null);
                    setShowDeleteFolderConfirmationModal(false);
                  }}
                />
              </Col>
              <Col md={6}>
                <Button
                  style={{ width: '100%', marginTop: deviceWidth > 768 ? 0 : 16 }}
                  color="red"
                  variant="primary red"
                  text="Delete Folder"
                  action={() => {
                    deleteFolder(selectedFolderToDelete.id)
                    setSelectedFolderToDelete(null);
                    setShowDeleteFolderConfirmationModal(false);
                  }}
                />
              </Col>
            </Row>
          </CustomDialog>
        )}
        {viewTaskTemplate && (
          <CustomModal
            close={true}
            onClose={() => {
              setViewTaskTemplate(false);
            }}
          >
            <h1 className="text-2xl text-modal-header">Create Task Template</h1>
            <div style={{ padding: 10 }}>
              <AsyncSelect
                placeholder="Select Task"
                defaultOptions={tasksList}
                isClearable
                loadOptions={getTaskTypes} // Function to fetch template options asynchronously
                getOptionValue={(option) => option._id} // Define how to get the value of each option
                getOptionLabel={(option) => option.name} // Define how to get the label of each option
                onChange={(selected) => {
                  if (selected) setSelectedTask(selected._id);
                  else setSelectedTask('');
                }}
                noOptionsMessage={() => 'No Results, Type to Search'}
              />
              <Row>
                <Col>
                  {selectedTask !== '' && (
                    <Button
                      style={{ marginLeft: 'auto', marginTop: '1rem' }}
                      variant="primary"
                      action={() =>
                        navigator(`/task-types/${selectedTask}/templates`)
                      }
                      text="Create Task Template"
                    />
                  )}
                </Col>
              </Row>
            </div>
          </CustomModal>
        )}
      </Row>
    </div>
  );
}
