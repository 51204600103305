/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

// auth context
export const AuthContext = createContext();
const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');

const getQuery = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return false;
};

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const auth = useAPI(user ? '/api/auth' : null);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    // update the auth status
    if (!auth.loading && auth.data) {
      auth.data.authenticated ? update(auth.data) : signout();
    }
  }, [auth]);

  const checkIntegrationInstall = () => {
    const code = getQuery('code');
    const state = getQuery('state');
    if (code && state === 'connect-pipedrive') {
      window.sessionStorage.setItem('code', code);
      window.sessionStorage.setItem('integration', 'pipedrive');
    }
  };

  function signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data));
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + res.data.token;
      Event('signin');

      if (res.data.integrationExist)
        return (window.location = `/account/integration/${res.data.integration}`);
      if (res.data.ltd) return (window.location = '/account/billing');
      if (res.data.newUser) {
        if (!res.data.plan || res.data.plan === 'free')
          return (window.location = '/account/billing');
        if(!res.data.phone) {
          sessionStorage.setItem('phone', 'required')
          if (res.data.plan === "Trial") {
            sessionStorage.setItem('onboarding', 'required')
            // return (window.location = '/dashboard?onboarding=true');
            return (window.location = '/dashboard')
          } else return (window.location = '/dashboard');
        }
        if (res.data.plan === "Trial" && res.data.newSignUp) {
          sessionStorage.setItem('onboarding', 'required')
          // return (window.location = '/dashboard?onboarding=true');
          return (window.location = '/dashboard')
        }
        return (window.location = '/dashboard');
      } else return (window.location = '/dashboard');
    }
  }

  async function signout() {
    axios({ method: 'delete', url: '/api/auth' });
    localStorage.clear();
    return (window.location = '/signin');
  }

  async function switchAccount(id) {
    const res = await axios({
      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },
    });

    if (res.data) signin(res);
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key];
        } else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  useEffect(() => {
    getUseOnboarding();
  }, [auth]);

  const getUseOnboarding = async () => {
    try {
      if(window.location.pathname !== '/signin'){
          const res = await axios({
            url: '/api/active-plan',
            method: 'get',
          });
          setPlan(res.data.data);
        }
    } catch (err) {
      console.log('ERROR ', err.response);
    }
  };

  checkIntegrationInstall(window.location.search);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        switchAccount: switchAccount,
        permission: permissions[user?.permission],
        plan,
      }}
      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'));
  const path = window.location.pathname;

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      // user has no plan
      if (!user.plan && path !== '/account/profile' && path !== '/account/billing')
        return <Navigate to="/account/billing" />;

      // user is good
      return props.children;
    }
    if (user.permission == 'user') {
      if(user.expired) localStorage.setItem('expiredPlan', user.permission);
      else localStorage.setItem('noPermission', user.permission);
      return <Navigate to="/" />;
    }
  }

  // user is not authenticated
  return <Navigate to="/signin" />;
}
