import './subnav.scss';

function SubMenuItem({ menuitem, onClick, selected }) {
  return (
    <>
      <div
        onClick={() => onClick(menuitem)}
        className={`SubNavMenuItem ${menuitem.title === selected && 'active'}`}
      >
        <div className="SubNavItemIcon">{menuitem?.icon}</div>
        <div className="SubNavItemTitle">{menuitem?.title}</div>
      </div>
    </>
  );
}

export function CustomSubNav({ menuitems, onChange, selected }) {
  return (
    <div className="SubNav">
      {menuitems.map((menuitem) => (
        <SubMenuItem
          key={`${menuitem.icon}-${menuitem.title}`}
          onClick={(selectedItem) => onChange(selectedItem)}
          menuitem={menuitem}
          selected={selected}
        />
      ))}
    </div>
  );
}
