/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

export function AuthLayout(props) {
  return <>{<props.children {...props.data} />}</>;
}
