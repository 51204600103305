import { Pixie } from 'pixie';
import { useEffect, useState } from 'react';
import { usePixieContext } from './pixieContext';
import { UilTimes } from '@iconscout/react-unicons';
import './pixieEditor.scss';

export function PixieEditor() {
  const [instance, setInstance] = useState(null);
  const pixieContext = usePixieContext();
  const [pixieData, setPixieData] = useState(null);

  useEffect(() => {
    if (!pixieData) return;
    pixieContext.setIsVisible(false);
    pixieContext?.setDone({
      id: pixieContext?.imageState?.id || `img-${new Date().getTime()}`,
      data: pixieData,
      imageState: instance?.getState(),
    });
    instance?.close();
    setTimeout(() => {
      instance?.resetEditor();
    }, 200);

    if (!pixieData) return false;
    setPixieData(null);
  }, [pixieData]);

  useEffect(() => {
    if (!instance) {
      initializePixie();
    }
    if (instance && pixieContext.isVisible === true) {
      instance.open();
      instance?.togglePanel('newImage');
    }
  }, [pixieContext.isVisible]);

  useEffect(() => {
    if (!instance) return;
    instance.open();
    if (!pixieContext.imageState) {
      instance?.togglePanel('newImage');
      return;
    }
    instance?.resetEditor();
    setTimeout(
      () => instance.setState(pixieContext?.imageState?.imageState),
      500
    );
  }, [pixieContext.imageState]);

  const initializePixie = () => {
    // stops creation of a new pixie instance
    if (window.pixie) return;

    Pixie.init({
      selector: '#pixieContainer',
      baseUrl: `${window.location.origin}`,
      onLoad: () => {
        console.log('ready!');
      },
      objectControls: {
        global: {
          unlockAspectRatio: true,
        },
        shape: {
          hideTopLeft: true,
        },
      },
      ui: {
        mode: 'inline',
        nav: {
          position: 'bottom',
          replaceDefault: false,
        },
        menubar: {
          replaceDefaultItems: false,
          items: [
            {
              type: 'button',
              icon: <UilTimes />,
              align: 'right',
              label: 'Close',
              buttonVariant: '#BF3035',
              action: editor => {
                pixieContext.setIsVisible(false);
                window.pixie.resetEditor();
                window.pixie.close();
              },
            },
          ],
        },
        openImageDialog: {
          show: true,
          sampleImages: [
            {
              url: 'personalization/sample1.jpg',
              thumbnail: 'personalization/sample1_thumb.jpg',
            },
            {
              url: 'personalization/sample2.jpg',
              thumbnail: 'personalization/sample2_thumb.jpg',
            },
            {
              url: 'personalization/sample3.jpg',
              thumbnail: 'personalization/sample3_thumb.jpg',
            },
          ],
        },
      },
      onSave: function (data) {
        setPixieData(data);
      },
    }).then((pixieInstance) => {
      window.pixie = pixieInstance;
      pixieInstance.open();
      pixieInstance.resetEditor();
      pixieInstance.close();
      setInstance(pixieInstance);
    });
  };

  return (
    <div
      className={`csPixieEditorContainer ${
        pixieContext.isVisible ? 'visible' : 'none'
      }`}
    >
      <div style={{ height: '100vh' }} id="pixieContainer" />
    </div>
  );
}
