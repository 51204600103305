/***
 *
 *   BILLING
 *   Update card details
 *
 **********/

import { CreditCard, PaymentForm, useAPI, WhitelabelContext } from 'components/lib';
import { useContext, useEffect, useState } from 'react';

export function BillingCard(props) {
  const [card, setCard] = useState(null);
  const data = useAPI('/api/account/card');
  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    if (data.data) setCard(data.data);
  }, [data.data]);

  return (
    <div loading={data.loading} className={props.className} style={{ width: 350 }}>
      {card && (
        <CreditCard
          brand={card.brand}
          last_four={card.last4}
          expires={`${card.exp_month}/${card.exp_year}`}
        />
      )}

      {card && (
        <div style={{ border: '1px solid #d7d7d7', borderRadius: 5, padding: 20, marginTop: 20 }}>
          <PaymentForm
            className="restrict-width"
            data={{
              token: {
                label: 'Update Your Card',
                type: 'creditcard',
                required: true,
              },
            }}
            url="/api/account/card"
            method="PATCH"
            callback={(res) => setCard(res.data.data)}
            buttonText="Save Card"
          />
        </div>
      )}

      {!card && (
        <div className="font-bold text-md mt-4">
          No Card Connected with {whitelabelDetails?.agencyName}
        </div>
      )}
    </div>
  );
}
