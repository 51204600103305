import React from 'react';
import { UilTimes } from '@iconscout/react-unicons'
import './customModal.scss';

/**
 * 
 * @param {{title: string, close: boolean, onClose: callback, style: object, height: number, width: number}} props 
 * @returns 
 */
export default function CustomModal(props) {
  return (
    <div className="csModalBackdrop">
      <div
        className="csModalContent scroller"
        style={{ height: props.height, width: props.width, padding: 10, ...props?.style }}
      >
        {(props.title || props.close) && <div className="csModalTitleContainer" style={{ borderBottom: `${(!props.title && !props.close)?"none":"1px solid #D7D7D7"}` }}>
          <div className="csModalTitle">
            {props.title && (
              <h1>{props.title}</h1>
            )}
          </div>
          {props.close && (
            <button type="button" onClick={() => props.onClose()} className='closeBtn'>
              <UilTimes className="csModalCloseButton" size={20} />
            </button>
          )}
        </div>}
        <div className="csModalBody scroller" style={{ overflowY: 'auto' }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
