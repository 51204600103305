import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import Mapping from './mapping';
import { UilArrowLeft, UilQuestionCircle } from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import { WhitelabelContext } from 'app/whitelabel';
import { AuthContext } from 'components/lib';

const INTEGRATIONS = {
  'zoho-crm': {
    name: 'Zoho CRM',
    img: 'https://zapier-images.imgix.net/storage/services/8b222e5e2d52e0b827a02ba8c3162de1.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
    connectURL: `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.READ,ZohoCRM.org.READ,ZohoCRM.modules.leads.READ,ZohoCRM.modules.accounts.ALL,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.deals.READ,ZohoCRM.modules.activities.ALL,ZohoCRM.bulk.ALL,ZohoCRM.settings.custom_views.READ,ZohoCRM.settings.fields.READ,ZohoCRM.notifications.ALL,ZohoCRM.modules.notes.ALL&client_id=${
      process.env.REACT_APP_ZOHO_CLIENT_ID ||
      '1000.33FXDP3XRS4OATXQ7BBG9RO9GBRP7B'
    }&response_type=code&access_type=offline&redirect_uri=${
      window.location.origin
    }/account/integration/zoho-crm`,
    help: "https://help.salesblink.io/en/articles/9080642-connect-salesblink-with-zoho-crm"
  },
  hubspot: {
    name: 'HubSpot',
    img: 'https://zapier-images.imgix.net/storage/developer/cde9764aa8d19fdd6d591455dbe5a78d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
    connectURL: `https://app.hubspot.com/oauth/authorize?client_id=${
      process.env.REACT_APP_HUBSPOT_CLIENT_ID ||
      'a9ecf31a-50ff-40c3-a93a-52642a74e540'
    }&scope=automation%20actions%20crm.objects.owners.read%20settings.users.teams.write%20settings.users.teams.read%20settings.users.write%20account-info.security.read%20crm.objects.contacts.write%20crm.objects.marketing_events.read%20crm.objects.marketing_events.write%20crm.lists.write%20crm.objects.companies.read%20crm.lists.read%20settings.users.read%20crm.objects.deals.read%20crm.objects.contacts.read&redirect_uri=${
      window.location.origin
    }/account/integration/hubspot&state=connect-hubspot`,
    help: "https://help.salesblink.io/en/articles/9080641-connect-salesblink-with-hubspot"
  },
  pipedrive: {
    name: 'Pipedrive',
    img: 'https://zapier-images.imgix.net/storage/developer/a70589f8db6a514b6820267b506409f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
    connectURL: `https://oauth.pipedrive.com/oauth/authorize?client_id=${
      process.env.REACT_APP_PIPEDRIVE_CLIENT_ID || '1c6fdfc0b0df51b2'
    }&state=connect-pipedrive&scope=base,admin,activities:full,contacts:read,deals:read,users:read,leads:read&redirect_uri=${
      window.location.origin
    }/account/integration/pipedrive?state=connect-pipedrive`,
    help: "https://help.salesblink.io/en/articles/9080640-connect-salesblink-with-pipedrive"
  },
};

export default function NativeIntegration(props) {
  const [connected, setConnected] = useState(false);
  const [properties, setProperties] = useState([]);
  const [existingProperties, setExistingProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [integration, setIntegration] = useState(null);
  const serviceName = props.serviceName;
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const authContext = useContext(AuthContext);

  // show the unsaved changes will be lost dialog if user makes some changes but hasn't saved them yet.
  useEffect(() => {
    if(serviceName !== "hubspot" && serviceName !== "pipedrive" && serviceName !== "zoho-crm") return

    function stopUnload(event) {
      event.preventDefault();
    }

    if(unsavedChanges) {
      window.addEventListener("beforeunload", stopUnload);

      return () => {
        window.removeEventListener("beforeunload", stopUnload);
      }
    }
  }, [unsavedChanges])

  useEffect(() => {
    if(serviceName !== "hubspot" && serviceName !== "pipedrive" && serviceName !== "zoho-crm") return
    getIntegration();
    const code = new URLSearchParams(window.location.search).get('code');
    const account_server = new URLSearchParams(window.location.search).get('accounts-server');
    if (code) connectIntegration(props.serviceName, code, account_server);
  }, []);

  useEffect(() => {
    setIntegration(props.integrationData)
  }, [props.integrationData])

  async function connectIntegration(serviceName, code, account_server) {
    let authData = { type: serviceName, code };
    if(account_server)authData['account_server'] = account_server
    try {
      await Axios({
        url: '/api/integration',
        method: 'post',
        data: authData,
      });
    } catch (err) {
      console.log('ERROR ', err.response);
    } finally {
      window.location.href = `/account/integration/${props.serviceName}`;
    }
  }

  async function getIntegration() {
    try {
      let res = await Axios({
        url: `/api/integration/${props.serviceName}`,
        method: 'get',
      });
      setConnected(res.data.connected);
      setProperties(res.data.data);
      if (res.data.properties) setExistingProperties(res.data.properties);
    } catch (err) {
      console.log('ERROR ', err.response);
    } finally {
      setLoading(false);
    }
  }

  async function deleteIntegration() {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: `<h5>${
        props.serviceName.charAt(0).toUpperCase() + props.serviceName.slice(1)
      } integration will be disconnected?</h5>`,
      showCancelButton: true,
      confirmButtonText: `Disconnect`,
      confirmButtonColor: '#E53A40',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Axios({
            url: `/api/integration/${serviceName}`,
            method: 'delete',
          });
          setConnected(false);
        } catch (err) {
          console.log('ERROR ', err.response);
        }
      }
    });
  }

  return (
    <div className="individual-integration">
      <button className="back-button" onClick={props.onClose}>
        <UilArrowLeft size={35} />
      </button>
      {(serviceName === "hubspot" || serviceName === "pipedrive" || serviceName === "zoho-crm") ?
        <>
          <div>
            <div className="card">
              <img src={INTEGRATIONS[props.serviceName].img} alt={INTEGRATIONS[props.serviceName].name} />
              <h1 className="integration-heading">{INTEGRATIONS[props.serviceName].name}</h1>
            </div>
            {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer' style={{ marginTop: -20, marginBottom: 20 }} href={INTEGRATIONS[props.serviceName].help} target='_blank'>
              Need Help
              <UilQuestionCircle size={18} style={{ marginLeft: 3 }} />
            </a>}
          </div>
          {!connected && (
            <CustomButton
              title="Connect"
              onClick={() => {
                window.location.href = INTEGRATIONS[props.serviceName].connectURL;
              }}
              variant="primary"
              style={{ margin: 'auto', fontSize: 25 }}
              disabled={loading}
            />
          )}
          {connected && (
            <Mapping
              properties={properties}
              title={props.serviceName}
              type={props.serviceName}
              existingProperties={existingProperties}
              onSave={props.onSave}
              deleteIntegration={deleteIntegration}
              onClose={props.onClose}
              setUnsavedChanges={setUnsavedChanges}
            />
          )}
        </>
      :
      <>
        <div style={{ marginTop: 20, textAlign: 'center' }}>
          <img
            className="m-auto"
            style={{ width: 80, height: 80 }}
            src={integration?.logo}
            alt={integration?.name}
            loading="lazy"
          />
          <h1 className="font-bold text-xl mb-1 mt-2">Integrate {integration?.name} with {whitelabelDetails?.agencyName}</h1>
          <p>
            {integration?.appDescription}
            <br />
            We currently do not have a native integration with {integration?.name}, you can integrate {whitelabelDetails?.agencyName} with {integration?.name} using an app connector.
          </p>
        </div>
        <div className="integrations-wrapper" style={{ marginTop: 20 }}>
          {integration?.zapier && <div>
            <div className="integration-item" onClick={() => window.open("https://zapier.com/developer/public-invite/121176/a7010ccab612be53f3f70975e4922fd2", "_blank")}>
              <img src={"https://zapier-images.imgix.net/storage/services/9103b01cf64908114323789f4480455d_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50"} className='image' alt={"Zapier"} />
              <h1 className="integration-heading name">Zapier</h1>
            </div>
            {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer mt-3' href='https://help.salesblink.io/en/articles/9080637-connect-salesblink-with-zapier' target='_blank'>
              <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
              Learn How
            </a>}
          </div>}
          {integration?.make && <div>
            <div className="integration-item" onClick={() => window.open("https://us1.make.com/app/invite/065c356189ba0728d234740b5a86183a", "_blank")}>
              <img src={"https://res.cloudinary.com/dotrlsog9/image/upload/v1712871877/Integration%20Images.-%20Used%20in%20SalesBlink/make_mjnqzi.png"} className='image' alt={"Make"} />
              <h1 className="integration-heading name">Make</h1>
            </div>
            {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer mt-3' href='https://help.salesblink.io/en/articles/9165625-connect-salesblink-with-make' target='_blank'>
              <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
              Learn How
            </a>}
          </div>}
          {integration?.pabblyConnect && <div>
            <div className="integration-item" onClick={() => window.open("https://www.pabbly.com/connect/integrations/salesblink", "_blank")}>
              <img src={"https://res.cloudinary.com/dotrlsog9/image/upload/v1712871878/Integration%20Images.-%20Used%20in%20SalesBlink/pabbly-connect_prmwgq.webp"} className='image' alt={"Pabbly"} />
              <h1 className="integration-heading name">Pabbly Connect</h1>
            </div>
            {authContext?.user?.permission !== 'client' && <a className='csCustomeLink justify-center cursor-pointer mt-3' href='https://help.salesblink.io/en/articles/9163699-connect-salesblink-with-pabbly-connect' target='_blank'>
              <UilQuestionCircle size={18} style={{ marginRight: 3 }} />
              Learn How
            </a>}
          </div>}
        </div>
      </>}

    </div>
  );
}
