import React, { useState } from 'react';
import { UilEllipsisH, UilAngleDown } from '@iconscout/react-unicons';
import './dropdown.scss';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';

export function CustomDropdown(props) {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState({
    clientX: 0,
    clientY: 0,
  });

  return (
    <div
      className="csTableDropdown"
      onBlur={() => {
        setTimeout(() => {
          setOpen(false);
        }, 300);
      }}
    >
      {props.type === 'select' && (
        <button
          className={`dropbtn ${props.disabled && 'disabled'}`}
          id="all-select-dropdown"
        >
          <input
            type="checkbox"
            className="csCheckbox"
            checked={props.checked}
            ref={(el) => el && (el.indeterminate = props.indeterminate)}
            onChange={() => props.selectAction()}
          />{' '}
          {!!props.items.length && (
            <UilAngleDown 
              onClick={(event) => {
                const { clientX, clientY } = event;
                setPos({ clientX, clientY });

                if (!props.disabled) setOpen(!open);
              }}
            />
          )}
        </button>
      )}
      {props.type !== 'select' && (
        <button
          className={`${props.className || 'dropBtnMore'}`}
          style={{ ...props.style }}
          onClick={(event) => {
            const { clientX, clientY } = event;
            setPos({ clientX, clientY });

            if (!props.disabled) setOpen(!open);
          }}
        >
          {props.icon ? props.icon : <UilEllipsisH size={16} />}
        </button>
      )}
      {open &&
        createPortal(
          <div
            className={`dropdown-content ${props.right ? 'right' : ''}`}
            style={{
              position: 'fixed',
              top: pos.clientY + 20,
              left: pos.clientX,
              width: `${props.width || 'auto'}`,
              zIndex: 9999,
            }}
          >
            {props.items.map((item) => (
              <div
                key={item.key}
                onClick={() => {
                  item.action(props.id);
                  setOpen(false);
                }}
                className='flex'
              >
                {item.icon}
                {item.title}
              </div>
            ))}
          </div>,
          document.body
        )}
      {props.tooltip && (
        <Tooltip
          anchorSelect={`#all-select-dropdown`}
          place="right"
          style={{ zIndex: 1 }}
        >
          {props.tooltip}
        </Tooltip>
      )}
    </div>
  );
}
