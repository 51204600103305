import { Dashboard } from 'views/dashboard/dashboard';
import Leads from 'views/leads';
import Engage from 'views/engage/engage';
import { Help } from 'views/dashboard/help';
import Meet from 'views/meet/meet';
import Import from 'views/leads/import/import';
import Tasks from 'views/engage/tasks';
import Conditions from 'views/engage/conditions';
import TaskTemplates from 'views/engage/tasktemplates';
import EditSequence from 'views/engage/editSequence';
import ManageUnsubscribed from 'views/engage/manageunsubscribed';
import EmailTemplate from 'views/engage/emailTemplate';
import GenerateChatGPTSequence from 'views/engage/generateChatGPTSequence';
import EmailDeliverabilityReport from 'views/engage/emailDeliverabilityReport';
import Activities from 'views/leads/activities';
import Inbox from 'views/inbox';
import SequenceStats from 'views/engage/sequenceStats';

const Routes = [
  {
    path: '/',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/inbox',
    view: Inbox,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/leads',
    view: Leads,
    layout: 'app',
    permission: 'user',
  },
  // {
  //   path: '/leads/:tab',
  //   view: Leads,
  //   layout: 'app',
  //   permission: 'user',
  // },
  {
    path: '/contact/:contactID',
    view: Activities,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/outreach',
    view: Engage,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/outreach/:tab',
    view: Engage,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/outreach/:tab/:listID',
    view: Engage,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/outreach/templates/:id',
    view: EmailTemplate,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/manage-unsubscribed',
    view: ManageUnsubscribed,
    layout: 'app',
    permission: 'user',
    title: 'Manage Unsubscribed',
  },
  {
    path: '/task-types',
    view: Tasks,
    layout: 'app',
    permission: 'user',
    title: 'Tasks',
  },
  {
    path: '/task-types/:taskID/conditions',
    view: Conditions,
    layout: 'app',
    permission: 'user',
    title: 'Conditions',
  },
  {
    path: '/task-types/:taskID/templates',
    view: TaskTemplates,
    layout: 'app',
    permission: 'user',
    title: 'Task Templates',
  },
  {
    path: '/task-types/:taskID/templates/:templateID',
    view: TaskTemplates,
    layout: 'app',
    permission: 'user',
    title: 'Task Templates',
  },
  // {
  //   path: '/welcome',
  //   view: OnboardingView,
  //   layout: 'onboarding',
  //   permission: 'user',
  //   title: 'Welcome',
  // },
  {
    path: '/meet',
    view: Meet,
    layout: 'app',
    permission: 'user',
    title: 'Meet',
  },
  {
    path: '/meet/:tab',
    view: Meet,
    layout: 'app',
    permission: 'user',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
  {
    path: '/import-list/:list_id',
    view: Import,
    layout: 'app',
    permission: 'user',
    title: 'Import List | SalesBlink',
  },
  {
    path: '/outreach/sequences/:sequenceID',
    view: EditSequence,
    layout: 'app',
    permission: 'user',
    title: 'Edit Sequence | SalesBlink',
  },
  {
    path: '/outreach/sequences/:id/reports',
    view: EditSequence,
    layout: 'app',
    permission: 'user',
    title: 'Sequence Report | SalesBlink',
  },
  {
    path: '/outreach/sequences/:id/stats',
    view: SequenceStats,
    layout: 'app',
    permission: 'user',
    title: 'Sequence Stats | SalesBlink',
  },
  {
    path: '/outreach/sequences/:id/generate',
    view: GenerateChatGPTSequence,
    layout: 'app',
    permission: 'user',
    title: 'Sequence Generate | SalesBlink',
  },
  {
    path: '/outreach/email-senders/:id/deliverability',
    view: EmailDeliverabilityReport,
    layout: 'app',
    permission: 'user',
    title: 'Email Deliverability | SalesBlink',
  },
];

export default Routes;
