import './index.scss'
import axios from 'axios';
import Swal from 'sweetalert2';
import {
    UilEdit,
    UilTrash,
    UilToggleOn,
    UilToggleOff,
    UilPlusCircle
} from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip';
import { Col, Row } from 'react-grid-system';
import { Animate, AuthContext } from "components/lib";
import { useContext, useState, useEffect } from "react";
import CustomDialog from 'components/custom/customDialog';
import CustomButton from 'components/custom/customButton';
import { setWorkspaceLocalStorage } from 'utils/setWorkspaceLocalStorage';
import { Button, CustomTable, PageTitle, TextInput } from 'components/lib';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

export function Workspace() {
    const [isOpen, setIsOpen] = useState(false);
    const [mainAccount, setMainAccount] = useState('');
    const [workspaceName, setWorkspaceName] = useState('');
    const [isEditingOpen, setIsEditingOpen] = useState(false);
    const [newWorkspaceName, setNewWorkspaceName] = useState('');
    const [createWorkspace, setCreateWorkspace] = useState(false);
    const [selectedAccountID, setSelectedAccountID] = useState('');
    const authContext = useContext(AuthContext);

    const editWorkspaceName = async () => {
        try {
            if(workspaceName.trim().length < 4) Toast.fire({ icon: 'error', title: 'The workspace name must be at least four characters long.' });
            await axios.put('/api/account/workspaces', { newWorkspaceName: workspaceName, accountID: selectedAccountID });
            window.location.reload();
        } catch (error) {
            return Toast.fire({ icon: 'error', title: error?.response?.data?.message });
        } finally {
            setIsEditingOpen(false);
            setWorkspaceName('');
            setSelectedAccountID('');
        }
    }

    const deleteWorkspace = async () => {
        try {
            if(selectedAccountID === mainAccount.id) {
                setIsOpen(false);
                return Toast.fire({ icon: 'error', title: 'Cannot delete the default workspace' });
            }
            const params = { accountID: selectedAccountID };
            await axios.delete('/api/user/delete', { params });
            if(selectedAccountID === authContext.user.account_id) {
                setWorkspaceLocalStorage(mainAccount.name);
                authContext.switchAccount(mainAccount.id);
            }
            else window.location.reload();
        } catch (error) {
            return Toast.fire({ icon: 'error', title: error?.response?.data?.message });
        } finally {
            setIsOpen(false);
            setSelectedAccountID('');
        }
    }

    const handleWorkspace = async () => {
        if(newWorkspaceName.trim().length < 4) return Toast.fire({ icon: 'error', title: `The workspace name must be at least four characters long.` });
        try {
          const response = await axios.post('/api/account/workspaces', {workspaceName: newWorkspaceName});
          if(response.status === 201) {
            setWorkspaceLocalStorage(newWorkspaceName);
            authContext.switchAccount(response.data.data.id);
          }
        } catch (error) {
          Swal.fire({
            icon: 'info',
            title: 'Can not create workspace',
            html: error.response.data.message,
            showCancelButton: true,
            confirmButtonText: `Upgrade`,
            confirmButtonColor: '#0066FF',
            reverseButtons: true
          }).then((result) => {
            if(result.isConfirmed) window.location.href = '/account/billing';
          });
        } finally {
          setNewWorkspaceName('');
          setCreateWorkspace(false);
        }
    }

    useEffect(() => {
        const accounts = JSON.parse(localStorage.getItem('user')).accounts;
        for (let i = 0; i < accounts?.length; i++) {
            if (accounts[i].permission === 'owner') {
                setMainAccount(accounts[i]);
                break;
            }
        }
    }, []);

    return (
        <div>
            <Animate>
                <div class="account-workspace">
                    <div id='workspace-header' style={{ marginBottom: 16, display: 'flex' }}>
                        <PageTitle
                            title='My Workspaces'
                            description="Create & manage your workspaces."
                            headerStyle={{ marginTop: 0 }}
                        />
                        <CustomButton
                            onClick={() => setCreateWorkspace(true)}
                            title="Create Workspace"
                            variant="primary"
                            icon={<UilPlusCircle />}
                            className="layout-reverse"
                            style={{ marginTop: '0 !important' }}
                            id='create-workspace-button'
                        />
                    </div>
                    {isEditingOpen && (
                        <CustomDialog width={200}>
                            <h2 style={{ paddingLeft: 0 }} className="text-2xl text-modal-header">
                                Edit your Workspace Name
                            </h2>
                            <p style={{ paddingLeft: 0 }}>
                                Enter the name for the workspace
                            </p>
                            <TextInput 
                                value={workspaceName} 
                                style={{ width: '100%' }}
                                placeholder="Enter the name for the workspace..."
                                onChange={(name, value) => { setWorkspaceName(value) }}
                            />
                            <Row style={{ marginLeft: '-8px', marginRight: '-13px', justifyContent: 'space-between'}}>
                                <Col md={6}>
                                    <Button
                                        style={{ width: '100%' }}
                                        text="Cancel"
                                        variant="secondary"
                                        action={() => {
                                            setWorkspaceName('');
                                            setIsEditingOpen(false);
                                        }}
                                    />
                                </Col>
                                <Col md={6} className='addTopMargin'>
                                    <Button
                                        style={{ marginLeft: 15, width: '100%' }}
                                        color="blue"
                                        variant="primary blue"
                                        text="Edit Workspace Name"
                                        action={editWorkspaceName}
                                    />
                                </Col>
                            </Row>
                        </CustomDialog>
                    )}
                    {isOpen && (
                        <CustomDialog width={200}>
                            <h2 className="text-2xl text-modal-header">
                                Delete your Workspace
                            </h2>
                            <p style={{ paddingLeft: 15 }}>
                                Are you sure you want to delete your workspace? You will lose all of
                                your data and this can not be undone.
                            </p>

                            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                                <Col md={6}>
                                    <Button
                                        style={{ marginLeft: 15, width: '100%' }}
                                        color="red"
                                        variant="primary red"
                                        text="Delete Workspace"
                                        action={deleteWorkspace}
                                    />
                                </Col>
                                <Col md={6} className='addTopMargin'>
                                    <Button
                                        style={{ width: '100%' }}
                                        text="Cancel"
                                        variant="primary"
                                        action={() => {
                                            setIsOpen(false);
                                            setWorkspaceName('');
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CustomDialog>
                    )}
                    {createWorkspace && 
                        <CustomDialog close={true} onClose={() => setCreateWorkspace(false)}>
                            <div style={{ padding: 10 }}>
                                <h1
                                    className="text-2xl text-modal-header"
                                    style={{ marginTop: '-15px', padding: 0 }}
                                >
                                    New Workspace
                                </h1>
                                <p className="text-modal-description" style={{ padding: 0 }}>
                                    Create a new workspace.
                                </p>
                                <hr className="mt-3 mb-3" />
                                <TextInput 
                                    value={newWorkspaceName}
                                    onChange={(name, value) => setNewWorkspaceName(value)}
                                    placeholder="Enter the name of your workspace"
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '1rem',
                                        gap: '2rem',
                                        justifyContent: 'end',
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        variant="primary grey"
                                        action={() => {
                                        setNewWorkspaceName('');
                                        setCreateWorkspace(false);
                                        }}
                                        text="Cancel"
                                    />
                                    <Button
                                        variant="primary"
                                        action={handleWorkspace}
                                        text={`Create`}
                                    />
                                </div>
                            </div>
                        </CustomDialog>
                    }
                    <CustomTable 
                        columns={[
                            {   Header: 'Name',
                                accessor: 'name',
                                Cell: ({ row }) => {
                                    const { original } = row;

                                    return <p className='capitalize'>{original.name}</p>
                                }
                            },
                            {
                                Header: 'Permission',
                                accessor: 'permission',
                                Cell: ({ row }) => {
                                    const { original } = row;

                                    return <p className='capitalize'>{original.permission}</p>
                                }
                            },
                            {
                                Header: 'Actions',
                                accessor: 'id',
                                disableSortBy: true,
                                Cell: ({ row }) => {
                                    const { original } = row;
                                    
                                    return (
                                        <div className='flex gap-4 items-center'>
                                            <div>
                                                { original.id === JSON.parse(localStorage.getItem('user')).account_id ? 
                                                    <div id='toggleOn'>
                                                        <UilToggleOn style={{ fill: '#10B981' }} size={32} />
                                                        <Tooltip anchorSelect="#toggleOn" place="top">Already on this Workspace</Tooltip>
                                                    </div> : 
                                                    <div id='toggleOff'>
                                                        <UilToggleOff onClick={() => { setWorkspaceLocalStorage(original.name); authContext.switchAccount(original.id) }} style={{ cursor: 'pointer', fill: '#E53A40'}} size={32} />
                                                        <Tooltip anchorSelect="#toggleOff" place="top">Switch to this Workspace</Tooltip>
                                                    </div>
                                                }
                                            </div>
                                            <div
                                                id='editWorkspace'
                                                style={{
                                                    color: original['permission'] === "owner" || original['permission'] === "admin" ? '#F9C00C' : "#a0a0a0",
                                                    border: '2px solid currentColor',
                                                    borderRadius: 5,
                                                    padding: 2,
                                                    cursor: original['permission'] === "owner" || original['permission'] === "admin" ? 'pointer' : 'default',
                                                }}
                                                onClick={() => {
                                                    if(original['permission'] === "owner" || original['permission'] === "admin") {
                                                        setSelectedAccountID(prev => original.id);
                                                        setWorkspaceName(prev => original.name);
                                                        setIsEditingOpen(true);
                                                    }
                                                }}
                                            >
                                                <UilEdit size={19} />
                                                {(original['permission'] === "owner" || original['permission'] === "admin") && <Tooltip anchorSelect="#editWorkspace" place="top">Edit workspace name</Tooltip>}
                                            </div>
                                            <div 
                                                id={`deleteWorkspace-${original.id === mainAccount.id ? 'primary' : 'secondary'}`}
                                                style={{
                                                    color: original['permission'] === "owner" && original['id'] !== mainAccount.id ? '#E53A40' : "#a0a0a0",
                                                    border: '2px solid currentColor',
                                                    borderRadius: 5,
                                                    padding: 2,
                                                    cursor: original['permission'] === "owner" ? 'pointer' : 'default',
                                                }}
                                                onClick={() => {
                                                    if(original['permission'] === "owner" && original['id'] !== mainAccount.id) {
                                                        setSelectedAccountID(prev => original.id);
                                                        setIsOpen(true);
                                                    }
                                                }}
                                            >
                                                <UilTrash size={19} />
                                                {original['permission'] === "owner" && <Tooltip anchorSelect="#deleteWorkspace-secondary" place="top">Delete workspace</Tooltip>}
                                                {original['permission'] === "owner" && <Tooltip anchorSelect="#deleteWorkspace-primary" place="top">You can not delete default workspace.</Tooltip>}
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        ]}
                        data={JSON.parse(localStorage.getItem('user')).accounts}
                        noBottomMargin={true}
                    />
                </div>
            </Animate>
        </div>
    )
}