import React from 'react'
import {
    NovuProvider,
    PopoverNotificationCenter
} from '@novu/notification-center';
import { UilBell } from '@iconscout/react-unicons';

export function NovuNotification(props) {
    function onNotificationClick(message) {
        // your logic to handle the notification click
        if (message?.cta?.data?.url) {
            window.location.href = message.cta.data.url;
        }
    }

    return (
        <div style={{ marginTop: 15 }}>
            <NovuProvider subscriberId={props.userID} applicationIdentifier={'_UF1SJCNBk35'}>
                <PopoverNotificationCenter 
                    onNotificationClick={onNotificationClick} 
                    colorScheme="light"
                >
                    {({ unseenCount }) => <CustomNotificationBellIcon unseenCount={unseenCount} />}
                </PopoverNotificationCenter>
            </NovuProvider>
        </div>
    )
}

const CustomNotificationBellIcon = (props) => {
    return <div style={{ position: 'relative', display: 'inline-block' }}>
        <UilBell size={26} style={{ cursor: 'pointer' }} />
        {props.unseenCount > 0 && <span style={{ position: 'absolute', top: 0, right: 0, width: 8, height: 8, backgroundColor: '#0066FF', borderRadius: '50%' }}></span>}
    </div>
}