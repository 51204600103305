import Axios from 'axios';
import CustomModal from 'components/custom/customModal';
import { TextInput, Button, WhitelabelContext } from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { UilQuestionCircle  } from '@iconscout/react-unicons';

export default function WarmupLinksEditor(props) {
  const [linkName, setLinkName] = useState('');
  const [linkLabel, setLinkLabel] = useState('');
  const [linkValue, setLinkValue] = useState('');
  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    if (!props.selected) return;
    setLinkName(props.selected.name);
    setLinkLabel(props.selected.label);
    setLinkValue(props.selected.value);
  }, [props.selected]);

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  const handleSave = async () => {
    try {
      if (linkName === '' || linkLabel === '' || linkValue === '')
        return Swal.fire({
          icon: 'error',
          title: ``,
          html: `<h5>Error, All Fields are Required!</h5>`,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });

      if (!isValidHttpUrl(linkValue))
        return Swal.fire({
          icon: 'error',
          title: ``,
          html: `<h5>Error, Please enter a valid URL!</h5>`,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: '#0066FF',
        });

      let body = {
        name: linkName,
        label: linkLabel,
        value: linkValue,
      };

      if (props.selected)
        await Axios.put(`/api/warmuplinks/${props.selected.id}`, body);
      else await Axios.post('/api/warmuplinks', body);

      props.onClose();
    } catch (err) {
      props.onClose();
      return Swal.fire({
        icon: 'error',
        title: ``,
        html: `<h5>Error in Creating Signature!</h5>`,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    }
  };

  return (
    <>
      <CustomModal
        title={props.selected ? 'Edit WarmUp Link' : 'Create WarmUp Link'}
        onClose={props.onClose}
        width={500}
      >
        <div style={{ padding: 15 }}>
          <p
            className="text-modal-description"
            style={{ marginTop: '-15px', padding: 0 }}
          >
            Email WarmUp links help prevent your website links from being a spam indicator, often Gmail and others consider adding links as Spam if it is included in a lot of emails and gets almost no clicks. 
            <br /><br />
            {whitelabelDetails?.agencyName} fixes this by adding your website link/url in the WarmUp process where the links are clicked to ensure your future cold emails with these links do not end up in spam.
            <br /><br />
            Add the link here then select by Internal name on warmup section of any email sender.
          </p>
          <TextInput
            onChange={(name, value) => setLinkName(value)}
            value={linkName}
            label={<div className='flex'>
              Internal Name
              <span style={{ color: 'red', marginLeft: 5 }}>
                *
              </span>
              <UilQuestionCircle
                size={18}
                id={"internal-name"}
                style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              />
            </div>}
          />
          <Tooltip anchorSelect={`#internal-name`} place={"right"}>
            Name of your link for internal purposes only.
          </Tooltip>
          <TextInput
            onChange={(name, value) => setLinkLabel(value)}
            value={linkLabel}
            label={<div className='flex'>
              Link Label (Anchor Text)
              <span style={{ color: 'red', marginLeft: 5 }}>
                *
              </span>
              <UilQuestionCircle
                size={18}
                id={"link-name"}
                style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              />
            </div>}
          />
          <Tooltip anchorSelect={`#link-name`} place={"right"}>
            The text on which your url will be linked in the email warmup body.
          </Tooltip>
          <TextInput
            onChange={(name, value) => setLinkValue(value)}
            value={linkValue}
            label={<div className='flex'>
              Link URL
              <span style={{ color: 'red', marginLeft: 5 }}>
                *
              </span>
              <UilQuestionCircle
                size={18}
                id={"link-url"}
                style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
              />
            </div>}
          />
          <Tooltip anchorSelect={`#link-url`} place={"right"}>
            The URL/Domain/Link that you want to warmup for better deliverability.
          </Tooltip>
          <div
            style={{
              width: '100%',
              marginTop: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '2rem',
            }}
          >
            <Button
              variant="primary grey"
              action={() => props.onClose()}
              text="Cancel"
            />
            <Button
              variant="primary"
              action={() => handleSave()}
              text={props.selected ? 'Update' : 'Save'}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
}
