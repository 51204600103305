import React, { useState, useEffect } from 'react';
import './customToggle.scss';

export default function CustomToggle(props) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(props.selected)
  }, [props.selected])

  return (
    <div
      onClick={() => {
        setActive(!active)
        props.onChange(!active)
      }}
      className={`csCustomToggle ${active && 'active'}`}
    >
      <div>{props.icon}</div>
      <div>{props.title}</div>
    </div>
  );
}
