/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './loader.tailwind.js';
import './loader.scss'

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props);

  return (
    <>
      <div className={loaderStyle} style={{zIndex: 100}}>
        {/* <div id="circularG">
          <div id="circularG_1" className="circularG"></div>
          <div id="circularG_2" className="circularG"></div>
          <div id="circularG_3" className="circularG"></div>
          <div id="circularG_4" className="circularG"></div>
          <div id="circularG_5" className="circularG"></div>
          <div id="circularG_6" className="circularG"></div>
          <div id="circularG_7" className="circularG"></div>
          <div id="circularG_8" className="circularG"></div>
        </div> */}
        <div class="loader"></div>
      </div>
      <div
        className="absolute top-1/2 text-center w-full"
        style={{ marginTop: 60 }}
      >
        {props.children}
      </div>
    </>
  );
}
