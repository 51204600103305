/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

let planArr = [
  {
    id: 'price_1MGcZ0GdPjcvdGepIuyyh9wk',
    prodId: 'price_1MZQxbGdPjcvdGepseprG0Sz',
    name: 'Trial',
    allowed_routes: ['all'],
  },
  {
    id: 'price_1MGeN8GdPjcvdGepED9PBzbf',
    prodId: 'price_1MZQG0GdPjcvdGepSQ4I3Wwx',
    name: 'Email WarmUp',
    allowed_routes: ['/outreach/email-senders', '/outreach/settings', '/warmup-pro'],
  },
  {
    id: 'price_1MPKNSGdPjcvdGepnu35Vxbv',
    prodId: 'price_1MZQG0GdPjcvdGeptFFRBskB',
    name: 'Email WarmUp Year',
    allowed_routes: ['/outreach/email-senders', '/outreach/settings', '/warmup-pro'],
  },
  {
    id: 'price_1MGhrgGdPjcvdGephRMfnJcM',
    prodId: 'price_1MZQFgGdPjcvdGep94vcoPvG',
    name: 'Basic',
    allowed_routes: [
      '/dashboard',
      '/outreach',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/leads/lists',
      '/import-list',
      '/account/users',
    ],
  },
  {
    id: 'price_1MGhqYGdPjcvdGepHzlbyJ3D',
    prodId: 'price_1MZQFmGdPjcvdGepXTpRkjj7',
    name: 'Professional',
    allowed_routes: [
      '/dashboard',
      '/outreach',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1MGhowGdPjcvdGepghtmFFcI',
    prodId: 'price_1MZQFtGdPjcvdGepb624umPg',
    name: 'Prospecting Pro',
    allowed_routes: [
      '/outreach/lists',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
    ],
  },
  {
    id: 'price_1MGhrgGdPjcvdGepniCQ7NW8',
    prodId: 'price_1MZQFgGdPjcvdGepjtzHbY6x',
    name: 'Basic Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/leads/lists',
      '/import-list',
      '/account/users',
    ],
  },
  {
    id: 'price_1MGhqYGdPjcvdGepsKsXFinQ',
    prodId: 'price_1MZQFmGdPjcvdGepefhMe1VD',
    name: 'Professional Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1MGhowGdPjcvdGepvW545Bu6',
    prodId: 'price_1MZQFtGdPjcvdGepCDWiQUnj',
    name: 'Prospecting Yearly',
    allowed_routes: [
      '/outreach/lists',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
    ],
  },
  {
    id: 'price_1MP3iDGdPjcvdGep0WyxurZX',
    prodId: 'price_1MZQFHGdPjcvdGepqdCwq6wz',
    name: 'Email Sender',
    allowed_routes: ['/outreach/email-senders'],
  },
  {
    id: 'price_1MPKMvGdPjcvdGepdQxuOJ9C',
    prodId: 'price_1MZQFHGdPjcvdGep2MKqnVbH',
    name: 'Email Sender Yearly',
    allowed_routes: ['/outreach/email-senders'],
  }, // TODO:: remove all above data after September 2024
  {
    id: 'price_1NlO9xGdPjcvdGep9YF3r6AD',
    prodId: 'price_1NmacEGdPjcvdGepmmuHKhFx',
    name: 'Scale',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/account/users',
    ],
  },
  {
    id: 'price_1NlO9xGdPjcvdGepEIlXuvy3',
    prodId: 'price_1NmacEGdPjcvdGep2Es38a71',
    name: 'Scale Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/account/users',
    ],
  },
  {
    id: 'price_1NlOBWGdPjcvdGepZTcoisuX',
    prodId: 'price_1NmacNGdPjcvdGepsKomGWo4',
    name: 'Growth',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1NlOBWGdPjcvdGepiG9E8ygr',
    prodId: 'price_1NmacNGdPjcvdGepXIPMMVgF',
    name: 'Growth Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1NlOCSGdPjcvdGep9ttru8rN',
    prodId: 'price_1NmacTGdPjcvdGep92G9GQKO',
    name: 'Business',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/outbox',
      '/outreach/lists',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1NlOCTGdPjcvdGepbVHNdFQg',
    prodId: 'price_1NmacTGdPjcvdGepI1laNWPb',
    name: 'Business Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjF9GdPjcvdGepx9QOieRr',
    prodId: 'price_1Ph1psGdPjcvdGepRNZruWNA',
    name: 'Agency Scale',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjF9GdPjcvdGepAoEr0Y4B',
    prodId: 'price_1Ph1psGdPjcvdGepnbYjAxPc',
    name: 'Agency Scale Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjFVGdPjcvdGeptaUFevCe',
    prodId: 'price_1Ph1pwGdPjcvdGepxUk00D9W',
    name: 'Agency Growth',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjFwGdPjcvdGepfojH6Ta2',
    prodId: 'price_1Ph1pwGdPjcvdGepYS6hYU2e',
    name: 'Agency Growth Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjHSGdPjcvdGeps4pAOsah',
    prodId: 'price_1Ph1pzGdPjcvdGepT5TslN8s',
    name: 'Agency Business',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
  {
    id: 'price_1PgjHSGdPjcvdGepSuG2AaXK',
    prodId: 'price_1Ph1pzGdPjcvdGep8Cer6g7m',
    name: 'Agency Business Yearly',
    allowed_routes: [
      '/dashboard',
      '/outreach/sequences',
      '/outreach/tasks',
      '/outreach/reports',
      '/outreach/templates',
      '/outreach/lists',
      '/outreach/outbox',
      '/outreach/email-senders',
      '/outreach/settings',
      '/leads',
      '/leads/people',
      '/leads/companies',
      '/leads/lists',
      '/import-list',
      '/blink-gpt-pro',
      '/warmup-pro',
      '/meet',
      '/meet/my-calender',
      '/meet/settings',
      '/account/users',
    ],
  },
];

export function useRouteCheck(route, plans, subscription, expired) {
  const [state, setState] = useState(false);
  let navigator = useNavigate();

  useEffect(() => {
    // console.log("TEST UP ",route, plans, subscription, expired)
    if (subscription === 'ltd') return setState(true);
    if (subscription === 'trialing') return setState(true);
    if (expired) return navigator('/account/billing?tab=invoice');
    if (plans) {
      let planList = plans.split(',');
      for (let i = 0; i < planList.length; i++) {
        let existPlan = planArr.find((p) => {
          if (p.id === planList[i] || p.prodId === planList[i]) return p;
        });
        if (existPlan) {
          if (route === '/') return setState(true);
          else if (existPlan.allowed_routes.find((r) => route.includes(r)))
            return setState(true);
        }
      }
    }
  }, [plans]);

  return state;
}
