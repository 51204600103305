import React from 'react';
import { UilQuestionCircle } from '@iconscout/react-unicons'
import { Tooltip } from 'react-tooltip';

export function PageTitle(props) {
    return (
        <div style={props.style}>
            <h1
                style={{
                    marginTop: '-15px',
                    padding: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    display: 'flex',
                    color: '#3a3a3a',
                    ...props.headerStyle
                }}
            >
                {props.title}
                {props.tooltip && <UilQuestionCircle size={22} style={{ marginTop: 5, marginLeft: 5, cursor: 'pointer' }} id="show-page-info" />}
            </h1>
            <p
                style={{ padding: 0, fontSize: 16, fontWeight: 400, color: '#3a3a3a' }}
            >
                {props.description}
            </p>
            {props.tooltip && <Tooltip anchorSelect="#show-page-info" place={props.tooltipPosition || "right"}>
                {props.tooltip}
            </Tooltip>}
        </div>
    );
}
